import { makeStyles, Box, Paper } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #BF6159",
    borderRadius: 0,
  },
  // centered: {
  //     height: '100%',
  //     position: 'absolute',
  //     zIndex: '5',
  //     top: '45%',
  //     left: '34%',
  //     fontSize: '19pt'

  // },
  // layer: {
  //     top: '0px',
  //     left: '0',
  //     width: '100%',
  //     height: '100%',
  //     position: 'absolute',
  //     backgroundColor: '#00000082',
  // }
  media: {
    width: "100%",
    height: "auto",
  },
}));
function BrandItem({ data }) {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.container} m-1`}
      component={Paper}
      eveluation={1}
    >
      <Link to={`/products?brand=${data?.id}`}>
        <img className={classes.media} src={data.image} alt="..." />
        {/* <div className={classes.layer}></div> */}
        {/* <div className={classes.centered}>{data.brand_name}</div> */}
      </Link>
    </Box>
  );
}

export default BrandItem;
