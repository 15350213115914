import React, {useState} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Logo from "../assets/logo/ADVA-White.png";
import Android from "../assets/images/Android.svg";
import IOS from "../assets/images/ios.svg";
import ApplePay from "../assets/icons/apple-pay.png";
import Visa from "../assets/icons/visa.png";
import MasterCard from "../assets/icons/mastercard.png";
import Mada from "../assets/icons/mada.png";
import ticktokIcon from "../assets/icons/ticktok.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import twitterIcon from "../assets/icons/twitter.svg";

import {
  Button,
  Grid,
  List,
  ListItem,
  TextField,
  Container,
  Typography,
  Divider,
} from "@material-ui/core";
import axios from "axios";
import Config from "../config.json";
import strings from "./../lang/lang";
import {useInventoryItems} from "./../Context/CartContext";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& footer": {
      paddingBottom: "73px",
    },
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    position: "relative",
    left: 0,
    bottom: 0,
    right: 0,
    marginTop: "25px",
    backgroundColor: "#000000",
  },
  newsletterInput: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 286,
    "& input:invalid + fieldset": {
      borderColor: "#BF6159",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159",
      padding: "4px !important",
      borderRadius: "0px",
    },
    "& .MuiInputBase-fullWidth": {
      maxWidth: 286,
    },
  },
  newsletterButton: {
    border: "1px solid #BF6159",
    color: "white",
    margin: "-3px 10px",
    borderRadius: "0px",
    minHeight: "40px",
    padding: "3px 15px",
    "&:hover": {
      backgroundColor: "#BF6159",
      color: "white",
    },
  },
  socialmedia: {
    background: "transparent",
    padding: "5px",
  },
}));

export default function StickyFooter() {
  const classes = useStyles();
  // eslint-disable-next-line
  const [NewsLetter, setNewsLetter] = useState("");
  const [error, seterror] = useState({});
  const [success, setsuccess] = useState(false);

  const {lang} = useInventoryItems();
  strings.setLanguage(lang);
  const submitNewsletter = async () => {
    await axios
      .post(`${Config.SERVER_URL}newsletter/create`, {
        email: NewsLetter,
      })
      .then(() => {
        setsuccess(true);
        setTimeout(() => {
          setsuccess(false);
        }, 3000);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
        setTimeout(() => {
          seterror(false);
        }, 3000);
      });
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <footer className={classes.footer}>
        <Container maxWidth='lg'>
          {/* First Row */}
          <Grid container spacing={2} justify='center'>
            {/* Customer Care */}
            <Grid item lg={2}>
              <h6 className='text-light'>{strings.CustomerCare}</h6>
              <List className={`${classes.flexContainer} footer-links`}>
                <ListItem>
                  <Link to='/faqs'>{strings.Faqs}</Link>
                </ListItem>
                <ListItem>
                  <Link to='/user/customercare'>{strings.CustomerCare}</Link>
                </ListItem>
                <ListItem>
                  <Link to='/about-us'>{strings.AboutUs}</Link>
                </ListItem>
              </List>
            </Grid>
            {/* Shipping */}
            <Grid item lg={2}>
              <h6 className='text-light'>{strings.Shipping}</h6>
              <List className={`${classes.flexContainer} footer-links`}>
                <ListItem>
                  <Link to='/'>{strings.ShippingRates}</Link>
                </ListItem>
                <ListItem>
                  <Link to='/products'>{strings.Products}</Link>
                </ListItem>
                <ListItem>
                  <Link to='/brands'>{strings.Brands}</Link>
                </ListItem>
              </List>
            </Grid>
            {/* Accounts */}
            <Grid item lg={2}>
              <h6 className='text-light'>{strings.Accounts}</h6>
              <List
                className={`${classes.flexContainer} footer-links border-0`}
              >
                <ListItem>
                  <Link to='/user'>{strings.MyAccount}</Link>
                </ListItem>
              </List>
            </Grid>

            {/* NewsLetter */}
            <Grid item lg={5}>
              <h6 className='text-light'>{strings.NewLetter}</h6>
              <Typography component='p' variant='body2' className='text-light'>
                {strings.MakeSureYouDontMiss}
              </Typography>
              {success && (
                <Alert severity='success'>
                  {strings.CongratsYouAreRegistered}
                </Alert>
              )}
              {error &&
                Object.keys(error).map((key, index) => (
                  <Alert key={index} severity='error'>
                    {error[key]}
                  </Alert>
                ))}
              <div className='d-flex mt-3'>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  onChange={(e) => {
                    setNewsLetter(e.target.value);
                  }}
                  className={classes.newsletterInput}
                  size='small'
                  placeholder={strings.TypeEmailHere}
                  fullWidth
                />
                <Button
                  variant='outlined'
                  className={classes.newsletterButton}
                  onClick={submitNewsletter}
                >
                  {strings.Subscribe}
                </Button>
              </div>
            </Grid>
          </Grid>

          {/* Second Row */}
          <Grid container spacing={2} justify='center'>
            <Grid item md={4}>
              <img src={Logo} alt='' />
            </Grid>
            <Grid item md={7} container alignItems='center'>
              {/* Links to Apps */}
              <Box display='flex' alignItems='center'>
                <a
                  href='https://play.google.com/store/apps/details?id=com.adva.adva'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={Android} alt='' className='mx-2' />
                </a>
                <a
                  href='https://apps.apple.com/app/adva-beauty/id1563345318'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={IOS} alt='' className='mx-2' />
                </a>
              </Box>
            </Grid>
            {/* Copyright */}
            <Grid item lg={12} container justify='center'>
              <Typography
                component='p'
                variant='body2'
                className='text-light w-100'
                align='center'
              >
                {strings.CopyRight} &copy; ADVA-2021
              </Typography>
              {/* Privay and terms */}
              <Box display='flex' className='mt-1'>
                <Link
                  to='/privacy-policy'
                  className='text-light text-decoration-none'
                >
                  <Typography component='p' variant='body2'>
                    {strings.PrivacyPolicy}
                  </Typography>
                </Link>
                <Divider orientation='vertical' className='bg-light my-0' />
                <Link
                  to='/terms-conditions'
                  className='text-light text-decoration-none'
                >
                  <Typography component='p' variant='body2'>
                    {strings.TermsConditions}
                  </Typography>
                </Link>
              </Box>
              {/* Social Media */}
              <Box
                display='flex'
                className='mt-2 w-100'
                justifyContent='center'
              >
                <a
                  href='https://twitter.com/Adva31614266'
                  className='text-light text-decoration-none mx-2'
                >
                  <img src={twitterIcon} alt='' />
                </a>
                <a
                  href='https://www.instagram.com/adva.beauty/'
                  className='text-light text-decoration-none mx-2'
                >
                  <img src={instagramIcon} alt='' />
                </a>
                <a
                  href='https://www.tiktok.com/@adva.beauty'
                  className='text-light text-decoration-none mx-2'
                >
                  <img src={ticktokIcon} alt='' />
                </a>
              </Box>
              {/* Payments */}
              <Box
                display='flex'
                className='mt-4 w-100'
                justifyContent='center'
              >
                <img
                  alt='Mada'
                  src={Mada}
                  className='mx-2'
                  style={{opacity: 0.5}}
                />
                <img
                  alt='Visa'
                  src={Visa}
                  className='mx-2'
                  style={{opacity: 0.5}}
                />
                <img
                  alt='MasterCard'
                  src={MasterCard}
                  className='mx-2'
                  style={{opacity: 0.5}}
                />
                <img
                  alt='ApplePay'
                  src={ApplePay}
                  className='mx-2'
                  style={{opacity: 0.5}}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </div>
  );
}
