import React from "react";
import Slider from "react-slick";
import NextIcon from "../../assets/icons/next.svg";
import BackIcon from "../../assets/icons/back.svg";
import { Link } from "react-router-dom";
import { useInventoryItems } from "./../../Context/CartContext";
import strings from "./../../lang/lang";
import LazyLoad from "react-lazyload";
function TopSellers({ data }) {
  const { lang } = useInventoryItems();
  strings.setLanguage(lang);
  const settings = {
    centerMode: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    rtl: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div style={{ backgroundColor: "#F6EDE8" }} className="top-sellers pb-5">
      <p className="text-center top-seller-head f-43">{strings.BestSeller}</p>
      <Slider {...settings}>
        {data.map((d, index) => {
          return (
            <LazyLoad offset={200}>
              <Link to={`/products/details/${d?.id}`} key={index}>
                <img
                  src={d?.productimages[0]?.picture_reference}
                  alt={d?.product_name}
                  className="auto px-lg-1 px-1"
                  style={{ maxWidth: 260, maxHeight: 206 }}
                />
              </Link>
            </LazyLoad>
          );
        })}
      </Slider>
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: `url(${NextIcon}) no-repeat 50% 50%`,
        backgroundColor: "white",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: `url(${BackIcon}) no-repeat 50% 50%`,
        backgroundColor: "white",
      }}
      onClick={onClick}
    />
  );
}

export default TopSellers;
