import Button from "@material-ui/core/Button";
import strings from "./../../../lang/lang";
import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Logo from "../../../assets/logo/ADVA.png";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Chip,
} from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { useInventoryItems } from "./../../../Context/CartContext";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from "axios";
import Config from "../../../config.json";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";
import FullPageLoader from "../../components/FullPageLoader";
import CountriesSelect from "../../components/CountriesSelect";
const useStyles = makeStyles((theme) => ({
  root: {},
  back: {
    textDecoration: "none",
    color: "black",
    fontSize: 16,
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  Button: {
    backgroundColor: "#BF6159",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
}));
function PersonalInformation({ onClickCall, saveData, information }) {
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const { updateIsInternational } = useInventoryItems();
  const [address, setaddress] = useState(null);
  const [customer] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [CustomerInformation, setCustomerInformation] = useState({
    FullName: information.FullName,
    Email: information.Email,
    PhoneNo: information.PhoneNo,
    Address_id: information.Address_id,
  });
  function handleFormChange(e) {
    setCustomerInformation({
      ...CustomerInformation,
      [e.target.name]: e.target.value,
    });
  }
  const handleAddressChange = (address) => {
    updateIsInternational(address.international);
    setCustomerInformation({
      ...CustomerInformation,
      Address_id: address.id,
    });
    console.log(CustomerInformation);
  };
  const onSubmit = async (data) => {
    saveData(CustomerInformation);
    onClickCall(true);
  };
  const [refresh, setRefresh] = useState(false);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      if (customer && CustomerInformation.Address_id === 0) {
        setloading(true);
        await axios
          .get(`${Config.SERVER_URL}address/${customer?.id}`)
          .then((res) => {
            setloading(false);
            if (Object.keys(res.data).length > 0) {
              updateIsInternational(res.data[0].international);
              setCustomerInformation({
                ...CustomerInformation,
                Address_id: res.data[0].id,
              });
              setaddress(res.data);
            }
          })
          .catch((error) => {
            setloading(false);
          });
      }
    };
    init();
  }, [customer, CustomerInformation, updateIsInternational, refresh]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRefresh(!refresh);
  };
  return (
    <Container className="mt-5">
      <FullPageLoader loading={loading} />
      <Link to="/">
        <img src={Logo} alt="Logo" />
      </Link>
      <nav className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">{strings.Cart}</Link>
        </li>
        <li className="breadcrumb-item active">{strings.Checkout}</li>
      </nav>

      <Link to="/" className={classes.back}>
        <ArrowBackIosIcon /> {strings.BackToHome}
      </Link>
      <div className={classes.root}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`w-md-75 mx-auto mx-md-0 h-100`}
        >
          <section className="mb-5">
            <h5 className="font-19 my-3">{strings.PersonalInformation}</h5>

            <FormControl margin="normal" className="d-block">
              <label>{strings.FullName}</label>
              <TextField
                id="FullName"
                type="text"
                name="FullName"
                variant="outlined"
                defaultValue={information?.FullName}
                onChange={(e) => {
                  handleFormChange(e);
                }}
                className={classes.input}
                inputRef={register({
                  required: "required",
                })}
                size="small"
              />
              <span className="error">{errors.FullName?.message}</span>
            </FormControl>
            <FormControl margin="normal" className="d-block">
              <label>{strings.Email}</label>
              <TextField
                id="Email"
                type="Email"
                name="Email"
                variant="outlined"
                defaultValue={information?.Email}
                onChange={(e) => {
                  handleFormChange(e);
                }}
                className={classes.input}
                size="small"
              />
            </FormControl>
            <FormControl margin="normal" className="d-block">
              <label>{strings.PhoneNumber}</label>
              <TextField
                id="PhoneNo"
                type="tel"
                name="PhoneNo"
                defaultValue={information?.PhoneNo}
                variant="outlined"
                onChange={(e) => {
                  handleFormChange(e);
                }}
                className={classes.input}
                inputRef={register({
                  required: "required",
                })}
                size="small"
              />

              <span className="error">{errors.PhoneNo?.message}</span>
            </FormControl>
          </section>
          <section>
            <h5 className="font-19">{strings.ShippingAddress}</h5>
            {/* {address ? <> */}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
              className="mb-2"
            >
              {strings.AddAddress}
            </Button>
            <span className="error">{errors.Address_id?.message}</span>
            {!!address && Object.keys(address).length > 0 && (
              <Grid container spacing={1}>
                {address.map((add, index) => {
                  return (
                    <Grid xs={12} item key={index}>
                      <Box className="border" p={1}>
                        <Typography component="label" variant="subtitle2">
                          {strings.ShippingAddress}
                        </Typography>
                        <Box p={1}>
                          <Typography component="label" variant="caption">
                            {strings.Address} {index + 1}
                          </Typography>

                          <Grid container>
                            <Grid item xs={10}>
                              {add?.address},{add?.city},{add?.country}
                            </Grid>
                            <Grid item xs={2} container alignItems="center">
                              <input
                                type="radio"
                                name="Address_id"
                                value={add?.id}
                                onChange={() => handleAddressChange(add)}
                                checked={
                                  CustomerInformation.Address_id === 0
                                    ? index === 0
                                      ? true
                                      : false
                                    : CustomerInformation.Address_id === add?.id
                                }
                                ref={register({ required: true })}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </section>
          <div className="d-flex justify-content-end my-2">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              className={`w-md-100 ${classes.Button}`}
              disabled={address ? false : true}
            >
              {strings.ProceedToPayments}
            </Button>
          </div>
        </form>
      </div>
      <NewAddress
        isOpen={open}
        handleClose={handleClose}
        setaddress={setaddress}
      />
    </Container>
  );
}
const newAddressStyle = makeStyles(() => ({
  label: {
    backgroundColor: "#FFFFFF",
    border: " 1px solid #D5D5D5",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "12px",
  },
  labelActive: {
    backgroundColor: "#FFFFFF",
    border: " 1px solid #BF6159",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "12px",
  },
}));
function NewAddress({ isOpen, handleClose, setaddress }) {
  const classes = newAddressStyle();
  const [addForm, setaddForm] = useState({
    country: "Saudi Arabia",
    label: "Home",
  });
  const handleChange = (e) => {
    addForm[e.target.name] = e.target.value;
    setaddForm(addForm);
  };
  const [error, seterror] = useState({});
  const [success, setsuccess] = useState(false);
  const [User] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [loading, setloading] = useState(false);
  const SubmitAddress = async () => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/address/create/${User?.id}`, {
        ...addForm,
      })
      .then((res) => {
        setloading(false);
        setsuccess(true);
        setaddress(res.data);
        handleClose();
      })
      .catch((error) => {
        setloading(false);
        if (error.response.status === 400) seterror(error.response.data);
        setTimeout(() => {
          seterror({});
        }, 3000);
      });
  };
  return (
    <>
      <FullPageLoader loading={loading} />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {strings.AddNewAddress}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {!!error &&
            Object.keys(error).map((key) => {
              return (
                <Alert key={key} severity="error">
                  {error[key]}
                </Alert>
              );
            })}
          {success && (
            <Alert severity="success">{strings.AddressAddedSuccessfully}</Alert>
          )}
          <Box p={2}>
            <FormControl fullWidth className="mt-2">
              <label>{strings.Addresses}</label>
              <TextField
                name="address"
                color="primary"
                variant="outlined"
                onChange={(e) => handleChange(e)}
                size="small"
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings.City}</label>
                  <TextField
                    name="city"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings.Country}</label>
                  <CountriesSelect
                    currentCountry={addForm.country}
                    handleChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings.PostalCode}</label>
                  <TextField
                    name="postal_code"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings.AddressType}</label>
                  <div className="d-flex">
                    <Chip
                      label={strings.AddressHome}
                      onClick={() => {
                        setaddForm({ ...addForm, label: "Home" });
                      }}
                      className={
                        addForm?.label === "Home"
                          ? classes.labelActive
                          : classes.label
                      }
                    />
                    <Chip
                      label={strings.Office}
                      onClick={() => {
                        setaddForm({ ...addForm, label: "Office" });
                      }}
                      className={
                        addForm?.label === "Office"
                          ? classes.labelActive
                          : classes.label
                      }
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className="mx-4 px-3">
          <Button
            onClick={handleClose}
            className="mx-2"
            color="primary"
            variant="outlined"
          >
            {strings.Cancel}
          </Button>
          <Button
            onClick={SubmitAddress}
            color="primary"
            variant="contained"
            autoFocus
          >
            {strings.Save}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default PersonalInformation;
