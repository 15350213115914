import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Views/Auth/Login";
import Register from "./pages/Views/Auth/Register";
import Brands from "./pages/Views/Brands/Index";
import CheckOut from "./pages/Views/Checkout/CheckOut";
import Confirm from "./pages/Views/Checkout/Confirm";
import ProductDetails from "./pages/Views/Products/Details";
import Products from "./pages/Views/Products/Index";
import MyAccount from "./pages/Views/User/MyAccount";
import ForgotPassword from "./pages/Views/Auth/ForgotPassword";
import PrivateRoute from "./pages/components/Routes/PrivateRoute";
import Gallery from "./pages/Views/Gallery/Index";
import GalleryDetails from "./pages/Views/Gallery/Details";
import CustomerCare from "./pages/Views/CustomerCare/Index";
import { useInventoryItems } from "./Context/CartContext";
import FAQ from "./pages/Views/Faq/Index";
import CardPayment from "./pages/Views/Checkout/CardPayment";
import ConfirmPayment from "./pages/Views/Checkout/ConfirmPayment";
import VerifyAccount from "./pages/Views/Auth/VerifyAccount";
import Aboutus from "./pages/Views/Misc/Aboutus";
import TermsAndCondition from "./pages/Views/Misc/TermsAndCondition";
import PrivacyPolicy from "./pages/Views/Misc/PrivacyPolicy";

/**
 * Component for redirecting to desgnited route and component.
 *
 * @component
 * @App
 * return (
 *   <App  />
 * )
 */

function App() {
  const { lang } = useInventoryItems();

  document
    .getElementById("root")
    .setAttribute("dir", lang === "ar" ? "rtl" : "ltr");
  document.body.setAttribute("dir", lang === "ar" ? "rtl" : "ltr");

  return (
    <Router basename="/">
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/register" component={Register} exact />
        <Route path="/verify-account/:id?" component={VerifyAccount} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <Route path="/brands" component={Brands} exact />
        <Route path="/products" component={Products} exact />
        <Route path="/gallery" component={Gallery} exact />
        <Route path="/gallery/:detail" component={GalleryDetails} exact />
        <Route path="/products/details/:id" component={ProductDetails} exact />
        <Route path="/faqs" component={FAQ} exact />
        <Route path="/about-us" component={Aboutus} exact />
        <Route path="/terms-conditions" component={TermsAndCondition} exact />
        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
        <PrivateRoute path="/checkout" component={<CheckOut />} exact />
        <PrivateRoute path="/checkout/confirm" component={<Confirm />} exact />
        <PrivateRoute
          path="/checkout/payment/:id"
          component={<CardPayment />}
          exact
        />
        <PrivateRoute
          path="/checkout/payments/confirm"
          component={<ConfirmPayment />}
          exact
        />
        <PrivateRoute path="/user" component={<MyAccount />} exact />
        <PrivateRoute
          path="/user/customercare"
          component={<CustomerCare />}
          exact
        />
      </Switch>
    </Router>
  );
}

export default App;
