import React, { useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Footer from "../../../layout/Footer";
import PersonalInformation from "./PersonalInformation";
import Payments from "./Payments";
import Confirm from "./Confirm";
import { useInventoryItems } from "./../../../Context/CartContext";
import strings from "./../../../lang/lang";
function CheckOut() {
  const [ProceedToPayment, setProceedToPayment] = useState(false);
  function handleProceed(value) {
    setProceedToPayment(value);
  }
  const [customer] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [CustomerInformation, setCustomerInformation] = useState({
    Address_id: 0,
    FullName: customer?.first_name + customer?.last_name,
    Email: customer?.email !== "null" ? customer?.email : "",
    PhoneNo: customer?.phone,
  });
  const [confirmOrder, setconfirmOrder] = useState(false);
  const [UserInfo, setUserInfo] = useState(null);
  const handlePayInfo = (data) => {
    setUserInfo({ CustomerInfo: CustomerInformation, PaymentInfo: data });
    setconfirmOrder(true);
  };
  const handleCustInfo = (data) => {
    setCustomerInformation(data);
  };
  const handlePayment = (value) => {
    setProceedToPayment(value);
  };

  return (
    <>
      <Grid container>
        {confirmOrder ? (
          <Grid item xs={12}>
            <Confirm UserInfo={UserInfo} setconfirmOrder={setconfirmOrder} />
          </Grid>
        ) : (
          <>
            <Grid xs={12} md={7} item className="LeftSide">
              {ProceedToPayment ? (
                <Payments
                  onClickCall={handlePayment}
                  saveData={handlePayInfo}
                />
              ) : (
                <PersonalInformation
                  onClickCall={handleProceed}
                  saveData={handleCustInfo}
                  information={CustomerInformation}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              justify="center"
              alignItems="center"
              container
            >
              <Cart />
            </Grid>
          </>
        )}
      </Grid>

      <Footer />
    </>
  );
}

const cartStyles = makeStyles((theme) => ({
  root: {
    marginTop: "110px",
    width: "75%",
  },
  totalRoot: {
    "& p,& span": {
      fontSize: 25,
    },
  },
}));
function Cart() {
  const classes = cartStyles();
  const { items, returnValue, isCOD } = useInventoryItems();
  const [products] = useState(items);
  return (
    <>
      <section className={classes.root}>
        <h5 className="font-19 my-3">{strings.CartItem}</h5>
        {products &&
          products?.map((p, index) => {
            return <CartItem product={p} key={index} />;
          })}

        {returnValue("isFree") && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.Shipping}</p>
              <p>
                {strings.sar}
                <span className="text-main">{returnValue("shipping")}</span>
              </p>
            </div>
          </div>
        )}
        <div className={classes.totalRoot}>
          <div className="d-flex-between-center">
            <p>{strings.VAT}</p>
            <p>
              {strings.SAR}
              <span className="text-main">{returnValue("vat")}</span>
            </p>
          </div>
        </div>

        {/* Promo if applied */}
        {returnValue("promo") > 0 && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.PromoCode}</p>
              <p>
                {strings.sar}
                <span className="text-main">{returnValue("promo")}</span>
              </p>
            </div>
          </div>
        )}
        {/* Discount if Applied */}
        {returnValue("discount") > 0 && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.DiscountAmount}</p>
              <p>
                {strings.sar}
                <span className="text-main">{returnValue("discount")}</span>
              </p>
            </div>
          </div>
        )}
        {/* Discount if Applied */}
        {isCOD && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.CODCharges}</p>
              <p>
                {strings.SAR}
                <span className="text-main">{returnValue("cod")}</span>
              </p>
            </div>
          </div>
        )}
        {/* Points Discount if Applied */}
        {returnValue("pointsDiscount") > 0 && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.PointsDiscount}</p>
              <p>
                {strings.SAR}
                <span className="text-main">
                  {returnValue("pointsDiscount")}
                </span>
              </p>
            </div>
          </div>
        )}
        <div className={classes.totalRoot}>
          <div className="d-flex-between-center">
            <p>{strings.Total}</p>
            <p>
              {strings.SAR} <span className="text-main">{returnValue()}</span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
function CartItem({ product }) {
  const { lang } = useInventoryItems();
  return (
    <>
      <div className="row my-2 border-bottom-main pb-3">
        <div className="col-md-3">
          <img
            src={product?.productimages[0]?.picture_reference}
            alt="EyePatch"
            width="100"
            height="100"
            className="mx-auto"
            style={{ boxShadow: "0px 3px 6px #00000029" }}
          />
        </div>
        <div className="col-md-9">
          <div className="d-flex-between-center">
            <h6 className="f-25">
              {lang.localeCompare("en") === 0
                ? product?.product_name
                : product?.product_arabic_name}
            </h6>
            <p className="text-right f-25">
              {strings.Price}: {strings.sar}
              {product?.price - product?.discounted_amount}
            </p>
          </div>
          <div className="d-flex-end-center">
            {strings.Quantity}: {product?.newQuantity}
          </div>
        </div>
      </div>
    </>
  );
}
export default CheckOut;
