import React, { useEffect, useState } from "react";
import NoPayment from "../../../assets/icons/NoPayment.svg";
import Grid from "@material-ui/core/Grid";
import { Divider, Typography, Box, Paper } from "@material-ui/core";
import axios from "axios";
import Config from '../../../config.json'
import FullPageLoader from "../../components/FullPageLoader";
function Payments() {
  const [payments, setpayments] = useState(null)
  const [user] = useState(JSON.parse(sessionStorage.getItem('user')));
  const [loading, setloading] = useState(false)
  useEffect(() => {
    window.scrollTo(0,0)
    const init = async () => {
      setloading(true)
      await axios.get(`${Config.SERVER_URL}paymentcard/${user?.id}`).then(res => {
        setpayments(res.data)
        setloading(false)
      }).catch(error => {
        setloading(false)
      })
    }
    init()
  }, [user])
  return (
    <div>
      <FullPageLoader loading={loading}/>
      <Typography align="center" component="h4" variant="h4">
        Payment Cards
      </Typography>
      <Divider />
      {payments ?
        <>
          {payments.map(card => {
            return <Grid container item xs={12} spacing={3} className="my-2 border">
              <Box component={Paper} className="w-100" display="flex" justifyContent="space-between" p={2}>
                <Box>
                  <Typography component="p">
                    Name on Card: {card?.name_on_card}
                  </Typography>
                  <Typography component="p">
                    Card Number: {card?.card_number}
                  </Typography>
                </Box>
                <Box>
                  <Typography component="p">
                    Expiry Date: {card?.expiry_date}
                  </Typography>
                  <Typography component="p">
                    Code: {card?.security_code}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          })}
        </>
        :
        <>
          <Grid container justify="center" className="mb-5">
            <Grid item xs={6}>
              <img src={NoPayment} alt="" className="auto" />
            </Grid>
          </Grid>
          <Typography variant="h5" align="center">
            You don't have any saved payment methods
      </Typography>
          <Typography variant="caption" component="p" align="center">
            Save payment methods at checkout
      </Typography>
        </>
      }
    </div>
  );
}

export default Payments;
