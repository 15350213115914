import React, { useState, useEffect } from "react";
import noContent from "../../../assets/icons/NoContent.svg";
import axios from "axios";
import Config from "../../../config.json";
import { Typography, Grid, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DataTable from "react-data-table-component";
import strings from "./../../../lang/lang";
import FullPageLoader from "../../components/FullPageLoader";
function Return() {
  const [returns, setreturns] = useState([]);
  const [error, seterror] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [search, setsearch] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    setisLoading(true);
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}returned/orders/${user?.id}?search=${search}`)
        .then((res) => {
          setisLoading(false);
          setreturns(res.data);
        })
        .catch((error) => {
          setisLoading(false);
          if (error.response.status === 400) seterror(error.response.data);
        });
    };
    init();
  }, [user, search]);
  const columns = [
    {
      name: strings.OrderID,
      selector: "id",
      sortable: true,
      cell: (row) => {
        return (
          <span className="text-main" style={{ cursor: "pointer" }}>
            ID-{row?.order_id}
          </span>
        );
      },
    },
    {
      name: strings.ReturnStatus,
      selector: "return_status",
      sortable: true,
      hide: "md",
    },
    {
      name: strings.Comment,
      selector: "comment",
      sortable: true,
      hide: "md",
    },
    {
      name: strings.ReturnReason,
      selector: "return_reason",
      sortable: true,
      hide: "md",
      wrap: true,
    },
    {
      name: strings.ProductState,
      selector: "product_state",
      sortable: true,
      hide: "md",
    },
    {
      name: strings.Date,
      selector: "created_at",
      sortable: true,
      wrap: true,
    },
  ];
  return (
    <>
      <FullPageLoader loading={isLoading} />
      {!!error &&
        Object.keys(error).map((key) => {
          return (
            <Alert key={key} severity="error">
              {error[key]}
            </Alert>
          );
        })}
      {Object.keys(returns).length === 0 ? (
        <>
          <Grid container item xs={12} justify="center">
            <img src={noContent} alt="" />
          </Grid>
          <Typography variant="h5" align="center">
            {strings.NoReturnRequestMade}
          </Typography>
          <Typography variant="caption" component="p" align="center">
            {strings.WantToMakeAReturnRequest}
          </Typography>
        </>
      ) : (
        <>
          <Grid container justify="space-between">
            <Grid item xsclassName="d-md-none">
              <Typography component="h5" variant="h5">
                {strings.ReturnRequest}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                color="primary"
                variant="outlined"
                placeholder={strings.Search}
                fullWidth
                size="small"
                onChange={(e) => setsearch(e.target.value)}
              />
            </Grid>
          </Grid>
          <DataTable
            paginationComponentOptions={{
              rowsPerPageText: strings.RowPerPage,
              rangeSeparatorText: strings.Of,
            }}
            direction="ltr"
            noDataComponent={strings.ThereAreNoRecordsToDisplay}
            columns={columns}
            data={returns}
            progressPending={isLoading}
            pagination
          />
        </>
      )}
    </>
  );
}

export default Return;
