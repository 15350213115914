import React from "react";
import successTick from "../../assets/images/successTick.png";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import strings from "./../../lang/lang";
function SuccessModal() {
  const location = useHistory();
  const redirect = () => {
    setTimeout(() => {
      location.push("/");
    }, 1000);
  };
  return (
    <div
      className="modal fade"
      id="SuccessModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered  success-modal"
        role="document"
      >
        <div className="modal-content">
          <h5 className="text-center my-3">{strings.Success}</h5>
          <img src={successTick} alt="successTick" className="mx-auto" />
          <p className="text-center my-3">{strings.YourOrderIsOnTheWay}</p>
          <div
            className="w-100 modal-footer"
            style={{ backgroundColor: "#BF6159", color: "white" }}
          >
            <Button
              data-dismiss="modal"
              onClick={() => redirect()}
              className="text-light w-100"
            >
              {strings.GoToHome}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
