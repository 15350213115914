import { Box, FormControl, Paper, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import axios from "axios";
import Config from "../../../config.json";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import strings from "./../../../lang/lang";
import FullPageLoader from "./../../components/FullPageLoader";
import CountriesSelect from "../../components/CountriesSelect";
function Address() {
  // const [isLoading, setisLoading] = useState(false);
  const [step, setstep] = useState(1);

  return <>{getComponent(step, setstep)}</>;
}

function getComponent(step, setstep) {
  switch (step) {
    case 1:
      return <AllAddress setstep={setstep} />;
    default:
      break;
  }
}
function AllAddress({ setstep }) {
  const [addresses, setaddress] = useState([]);
  const [error, seterror] = useState({});
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}address/${user?.id}`)
        .then((res) => {
          setisLoading(false);
          setaddress(res.data);
        })
        .catch((error) => {
          setisLoading(false);
          if (error.response?.status === 400) seterror(error.response?.data);
        });
    };
    init();
  }, [user]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <FullPageLoader loading={isLoading} />
      <NewAddress
        isOpen={open}
        handleClose={handleClose}
        setaddress={setaddress}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleClickOpen} color="primary" variant="contained">
          {strings.AddAddress}
        </Button>
      </Box>
      {isLoading && <Typography align="center">{strings.Loading}</Typography>}
      {!!error &&
        Object.keys(error).map((key) => {
          return (
            <Alert key={key} severity="error">
              {error[key]}
            </Alert>
          );
        })}
      {!!addresses &&
        addresses?.map((add) => {
          return (
            <Paper className="p-4 my-3">
              <Grid container spacing={2}>
                <Grid item xs={6} container alignItems="center">
                  <span
                    className={`badge ${
                      add?.default ? "badge-primary" : "badge-light"
                    } font-1`}
                  >
                    {strings.Default}
                  </span>
                  <span className={`badge font-1`}>{add.label}</span>
                  {/* <Typography className="mx-1">Home</Typography> */}
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                  <EditButton address={add} />
                  <DeleteAddress
                    address={add}
                    setaddress={setaddress}
                    seterror={seterror}
                  />
                </Grid>
              </Grid>
              <Typography component="p" variant="overline">
                {strings.Addresses}
              </Typography>
              <Typography component="p" variant="body1">
                {add?.address}
              </Typography>
            </Paper>
          );
        })}
    </>
  );
}
function EditButton({ address }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <EditAddress address={address} isOpen={open} handleClose={handleClose} />
      <Button onClick={handleClickOpen} className="text-main">
        <EditIcon /> {strings.Edit}
      </Button>
    </>
  );
}
function DeleteAddress({ address, setaddress, seterror }) {
  const user = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : null;
  const DeleteAdd = async () => {
    await axios
      .post(`${Config.SERVER_URL}customer/address/delete/${address?.id}`, {
        customer_id: user?.id,
      })
      .then((response) => {
        setaddress(response.data);
      })
      .catch((error) => {
        if (error.response?.status === 400) seterror(error.response?.data);
      });
  };
  return (
    <>
      <Button onClick={DeleteAdd} className="text-main">
        <DeleteForeverIcon /> {strings.Delete}
      </Button>
    </>
  );
}

const newAddressStyle = makeStyles(() => ({
  label: {
    backgroundColor: "#FFFFFF",
    border: " 1px solid #D5D5D5",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "12px",
  },
  labelActive: {
    backgroundColor: "#FFFFFF",
    border: " 1px solid #BF6159",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "12px",
  },
}));

function NewAddress({ isOpen, handleClose, setaddress }) {
  const classes = newAddressStyle();
  const [addForm, setaddForm] = useState({
    country: "Saudi Arabia",
    label: "Home",
  });
  const handleChange = (e) => {
    setaddForm({ ...addForm, [e.target.name]: e.target.value });
    console.log(addForm);
  };
  const [error, seterror] = useState({});
  const [success, setsuccess] = useState(false);
  const [User] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [loading, setloading] = useState(false);
  const SubmitAddress = async () => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/address/create/${User?.id}`, {
        ...addForm,
      })
      .then((res) => {
        setsuccess(true);
        setaddress(res.data);
        handleClose();
        setloading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) seterror(error.response.data);
        setloading(false);
      });
  };
  return (
    <>
      <FullPageLoader loading={loading} />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{strings.AddAddress}</DialogTitle>
        <Divider />
        <DialogContent>
          {!!error &&
            Object.keys(error).map((key) => {
              return (
                <Alert key={key} severity="error">
                  {error[key]}
                </Alert>
              );
            })}
          {success && (
            <Alert severity="success">{strings.AddressAddedSuccessfully}</Alert>
          )}
          <Box p={2}>
            <FormControl fullWidth className="mt-2">
              <label>{strings?.Addresses}</label>
              <TextField
                name="address"
                color="primary"
                variant="outlined"
                onChange={(e) => handleChange(e)}
                size="small"
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings?.Country}</label>
                  <CountriesSelect
                    handleChange={handleChange}
                    currentCountry={addForm.country}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings?.City}</label>
                  <TextField
                    name="city"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings?.PostalCode}</label>
                  <TextField
                    name="postal_code"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings.AddressType}</label>
                  <div className="d-flex">
                    <Chip
                      label={strings.AddressHome}
                      onClick={() => {
                        setaddForm({ ...addForm, label: "Home" });
                      }}
                      className={
                        addForm?.label === "Home"
                          ? classes.labelActive
                          : classes.label
                      }
                    />
                    <Chip
                      label={strings.Office}
                      onClick={() => {
                        setaddForm({ ...addForm, label: "Office" });
                      }}
                      className={
                        addForm?.label === "Office"
                          ? classes.labelActive
                          : classes.label
                      }
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Box className="mx-4 p-3" display="flex" justifyContent="flex-end">
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            className="mx-1"
          >
            {strings.Cancel}
          </Button>
          <Button
            onClick={SubmitAddress}
            color="primary"
            variant="contained"
            autoFocus
          >
            {strings.Save}
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

function EditAddress({ address, isOpen, handleClose }) {
  const [addForm, setaddForm] = useState({
    address: address?.address,
    country: address?.country,
    city: address?.city,
    postal_code: address?.postal_code,
    label: address?.label,
  });
  const [loading, setloading] = useState(false);
  const handleChange = (e) => {
    addForm[e.target.name] = e.target.value;
    setaddForm(addForm);
  };
  const [error, seterror] = useState({});
  const [success, setsuccess] = useState(false);
  const [User] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const SubmitAddress = async () => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/address/update/${address?.id}`, {
        ...addForm,
        customer_id: User?.id,
      })
      .then((res) => {
        setloading(false);
        setsuccess(true);
        setTimeout(() => {
          handleClose();
        }, 1000);
      })
      .catch((errors) => {
        setloading(false);
        if (errors?.response?.status === 400) seterror(errors.response.data);
      });
  };
  const classes = newAddressStyle();
  return (
    <>
      <FullPageLoader loading={loading} />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          {strings.UpdateAddress}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {!!error &&
            Object.keys(error).map((key) => {
              return (
                <Alert key={key} severity="error">
                  {error[key]}
                </Alert>
              );
            })}
          {success && (
            <Alert severity="success">
              {strings.AddressUpdatedSuccessfully}
            </Alert>
          )}
          <Box p={2}>
            <FormControl fullWidth className="mt-2">
              <label>{strings?.Address}</label>
              <TextField
                name="address"
                color="primary"
                variant="outlined"
                onChange={(e) => handleChange(e)}
                size="small"
                defaultValue={address?.address}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings?.Country}</label>
                  <CountriesSelect
                    currentCountry={address?.country}
                    handleChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings?.City}</label>
                  <TextField
                    name="city"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    defaultValue={address?.city}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings?.PostalCode}</label>
                  <TextField
                    name="postal_code"
                    color="primary"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    defaultValue={address?.postal_code}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="mt-2">
                  <label>{strings.AddressType}</label>
                  <div className="d-flex">
                    <Chip
                      label={strings.AddressHome}
                      onClick={() => {
                        setaddForm({ ...addForm, label: "Home" });
                      }}
                      className={
                        addForm?.label === "Home"
                          ? classes.labelActive
                          : classes.label
                      }
                    />
                    <Chip
                      label={strings.Office}
                      onClick={() => {
                        setaddForm({ ...addForm, label: "Office" });
                      }}
                      className={
                        addForm?.label === "Office"
                          ? classes.labelActive
                          : classes.label
                      }
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Box className="mx-4 p-3" display="flex" justifyContent="flex-end">
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            className="mx-1"
          >
            {strings.Cancel}
          </Button>
          <Button
            onClick={SubmitAddress}
            color="primary"
            variant="contained"
            autoFocus
          >
            {strings.Save}
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
export default Address;
