import React from 'react'
import ErrorTick from '../../assets/images/error-tick.svg'
import Button from "@material-ui/core/Button"
import { useHistory } from 'react-router-dom'
import strings from './../../lang/lang';
function ErrorModal({message}) {
    const location = useHistory()
    const redirect = ()=>{
        setTimeout(() => {
            location.push('/')
        }, 1000);
    }
    return (
        <div className="modal fade" id="ErrorModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered  success-modal" role="document">
                <div className="modal-content">
                    <h5 className="text-center my-3">Error</h5>
                    <img src={ErrorTick} alt="ErrorTick" className="mx-auto" />
                    <p className="text-center my-3">{message}</p>
                    <div className="w-100 modal-footer">
                        <Button data-dismiss="modal" onClick={()=>redirect()} className="text-light w-100">{strings.GoToHome}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorModal
