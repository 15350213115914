import strings from "./../../../lang/lang";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Button,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import Footer from "../../../layout/Footer";
import { FormControl, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Config from "../../../config.json";
import ResetPassword from "./ResetPassword";
import FullPageLoader from "../../components/FullPageLoader";
import Header from "./../../../layout/Header";
const useStyles = makeStyles(() => ({
  Button: {
    backgroundColor: "#BF6159",
    width: "100%",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  link: {
    display: "block",
    textAlign: "center",
    margin: "15px",
    textDecoration: "none",
    color: "#BF6159",
    "&:hover": {
      color: "#BF6159",
    },
  },
}));
function VerifyCode({ email }) {
  const classes = useStyles();
  const [UserForm, setUserForm] = useState({ code: "" });
  const { register, handleSubmit, errors } = useForm();
  const [ToReset, setToReset] = useState(false);
  const [userId, setuserId] = useState("");
  const [error, seterror] = useState({});
  const [loading, setloading] = useState(false);
  const onSubmit = async (data) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/verifycode`, {
        code: UserForm.code,
        email: email,
      })
      .then((res) => {
        setloading(false);
        setuserId(res?.data?.id);
        setToReset(true);
      })
      .catch((error) => {
        error && error.response.status === 400 && seterror(error.response.data);
        setloading(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleFormChange = (e) => {
    let tempUserForm = UserForm;
    tempUserForm[e.target.name] = e.target.value;
    setUserForm(tempUserForm);
  };
  return (
    <>
      {ToReset ? (
        <ResetPassword id={userId} />
      ) : (
        <div>
          <FullPageLoader loading={loading} />
          <Header />
          <div className="d-block d-lg-flex brands-header mx-140">
            <ol className="breadcrumb my-auto">
              <li className="breadcrumb-item">
                <Link to="/">{strings.Home}</Link>
              </li>
              <li className="breadcrumb-item active">{strings.Login}</li>
            </ol>
          </div>
          <p className="f-43 text-center">{strings.VerifyCode}</p>
          <Divider />
          <Grid
            item
            xs={11}
            sm={11}
            md={6}
            lg={3}
            className="mx-auto my-5"
            justify="center"
            alignItems="center"
          >
            {error &&
              Object.keys(error).map((key) => {
                return (
                  <Alert severity="error" key={key}>
                    {error[key]}
                  </Alert>
                );
              })}
            <Typography component="h4" variant="h5" align="center">
              Verification code
            </Typography>
            <Typography
              component="p"
              variant="caption"
              align="center"
              className="f-16"
            >
              Please enter the 4 digit verification code we have just sent you
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl margin="normal" className="d-block">
                <label>{strings.VerificationCodeLabel}</label>
                <TextField
                  id="Code"
                  type="text"
                  name="code"
                  variant="outlined"
                  onChange={(e) => {
                    handleFormChange(e);
                  }}
                  className={classes.input}
                  inputRef={register({
                    required: "required",
                  })}
                  size="small"
                />
                <span className="error">{errors.email?.message}</span>
              </FormControl>
              <div className="d-flex-center-center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.Button}
                >
                  {strings.Reset}
                </Button>
              </div>
            </form>
            <Link to="/login" className={classes.link}>
              {strings.BackToLogin}
            </Link>
          </Grid>
          <Footer />
        </div>
      )}
    </>
  );
}

export default VerifyCode;
