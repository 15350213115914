import strings from "./../../../lang/lang";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Header from "./../../../layout/Header";
import Footer from "../../../layout/Footer";
import {
  makeStyles,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormControl } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import Config from "../../../config.json";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import PhoneInput from "react-phone-input-2";
import FullPageLoader from "../../components/FullPageLoader";
const useStyles = makeStyles((theme) => ({
  Button: {
    backgroundColor: "#BF6159",
    width: "100%",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  link: {
    display: "block",
    textAlign: "center",
    margin: "5px",
    textDecoration: "none",
    color: "#BF6159",
    "&:hover": {
      color: "#BF6159",
    },
  },
  loginlink: {
    textAlign: "center",
    margin: "5px",
    textDecoration: "none",
    color: "#BF6159",
    "&:hover": {
      color: "#BF6159",
    },
  },
  PhoneInputStyle: {
    direction: "ltr !important",
  },
}));
function Register() {
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  let user = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : null;
  const [loading, setloading] = useState(false);
  // eslint-disable-next-line
  const [isLogined, setisLogined] = useState(user);
  const [UserForm, setUserForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const location = useHistory();
  const handleFormChange = (e) => {
    let tempUserForm = UserForm;
    tempUserForm[e.target.name] = e.target.value;
    setUserForm(tempUserForm);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [haveErrors, sethaveErrors] = useState([]);
  const onSubmit = async (data) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/create`, UserForm)
      .then((response) => {
        location.push(`/verify-account/${response.data?.customer_id}`);
        setloading(false);
      })
      .catch((error) => {
        sethaveErrors(error.response.data);
        setloading(false);
      });
  };
  return (
    <div>
      {isLogined ? <Redirect to="/" /> : ""}
      <FullPageLoader loading={loading} />
      <Header />
      <div className="d-block d-lg-flex brands-header mx-140">
        <ol className="breadcrumb my-auto">
          <li className="breadcrumb-item">
            <Link to="/">{strings.Home}</Link>
          </li>
          <li className="breadcrumb-item active">{strings.CreateAccount}</li>
        </ol>
      </div>
      <p className="f-43 text-center">{strings.CreateAccount}</p>
      <Divider />
      <Grid
        item
        xs={11}
        sm={11}
        md={6}
        lg={4}
        className="mx-auto"
        justify="center"
        alignItems="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" className="d-block">
                <label>{strings.FirstName}</label>
                <TextField
                  id="first_name"
                  type="text"
                  name="first_name"
                  variant="outlined"
                  onChange={(e) => {
                    handleFormChange(e);
                  }}
                  className={classes.input}
                  inputRef={register({
                    required: "required",
                  })}
                  size="small"
                />
                <span className="error">{errors.first_name?.message}</span>
                <span className="error">
                  {haveErrors?.first_name && (
                    <Alert severity="error">{haveErrors?.first_name}</Alert>
                  )}
                </span>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" className="d-block">
                <label>{strings.LastName}</label>
                <TextField
                  id="last_name"
                  type="text"
                  name="last_name"
                  variant="outlined"
                  onChange={(e) => {
                    handleFormChange(e);
                  }}
                  className={classes.input}
                  inputRef={register({
                    required: "required",
                  })}
                  size="small"
                />
                <span className="error">{errors.last_name?.message}</span>
                <span className="error">
                  {haveErrors?.last_name && (
                    <Alert severity="error">{haveErrors?.last_name}</Alert>
                  )}
                </span>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" className="d-block">
                <label>{strings.PhoneNumber}</label>
                <PhoneInput
                  country={"sa"}
                  name="phone"
                  onChange={(e) => {
                    setUserForm({ ...UserForm, phone: e });
                  }}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  inputClass="w-100"
                  containerClass={classes.PhoneInputStyle}
                />
                <span className="error">{errors.PhoneNo?.message}</span>
                <span className="error">
                  {haveErrors?.phone && (
                    <Alert severity="error">{haveErrors?.phone}</Alert>
                  )}
                </span>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" className="d-block">
                <label>{strings.Email}</label>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  variant="outlined"
                  onChange={(e) => {
                    handleFormChange(e);
                  }}
                  className={classes.input}
                  size="small"
                />
                <span className="error">
                  {haveErrors?.email && (
                    <Alert severity="error">{haveErrors?.email}</Alert>
                  )}
                </span>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" className="d-block">
                <label>{strings.Password}</label>
                <TextField
                  id="password"
                  type="password"
                  name="password"
                  variant="outlined"
                  onChange={(e) => {
                    handleFormChange(e);
                  }}
                  className={classes.input}
                  inputRef={register({
                    required: "required",
                  })}
                  size="small"
                />
                {errors.password?.message}
                <span className="error">
                  {haveErrors?.password && (
                    <Alert severity="error">{haveErrors?.password}</Alert>
                  )}
                </span>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" className="d-block">
                <label>{strings.ConfirmPassword}</label>
                <TextField
                  id="confirm_password"
                  type="password"
                  name="confirm_password"
                  variant="outlined"
                  onChange={(e) => {
                    handleFormChange(e);
                  }}
                  className={classes.input}
                  inputRef={register({
                    required: "required",
                  })}
                  size="small"
                />
                {errors.confirm_password?.message}
                <span className="error">
                  {haveErrors?.confirm_password && (
                    <Alert severity="error">
                      {haveErrors?.confirm_password}
                    </Alert>
                  )}
                </span>
              </FormControl>
            </Grid>
          </Grid>
          <div className="d-flex-center-center my-4">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.Button}
            >
              {strings.CreateAccount}
            </Button>
          </div>
          <Link to="/" className={classes.link}>
            {strings.ReturnToStore}
          </Link>
          <Typography component="p" className="w-100 mt-5" align="center">
            {strings.AlreadyHaveAnAccount}
            <Link to="/login" className={classes.loginlink}>
              {strings.Login}
            </Link>
          </Typography>
        </form>
      </Grid>
      <Footer />
    </div>
  );
}

export default Register;
