import React, { createContext, useState, useContext, useEffect } from "react";
import strings from "./../lang/lang";
import axios from "axios";
import Config from "../config.json";
const InventoryItemContext = createContext();
export const useInventoryItems = () => useContext(InventoryItemContext);

/**
 * @component
 * @alias  InventoryItem
 * @param {*} props - Component to rendered in context
 * @returns <InventoryItemProvider>{children}</InventoryItemProvider>
 */

export default function InventoryItemProvider(props) {
  // USER
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // End User

  const [lang, setlanguage] = useState("ar");

  let initialItems = sessionStorage.getItem("products")
    ? JSON.parse(sessionStorage.getItem("products"))
    : [];
  const [vat, setvat] = useState(
    sessionStorage.getItem("vat")
      ? parseFloat(JSON.parse(sessionStorage.getItem("vat")))
      : 0
  );
  const [total, settotal] = useState(
    sessionStorage.getItem("total")
      ? parseFloat(JSON.parse(sessionStorage.getItem("total")))
      : 0
  );
  const [dummy, setdummy] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [selectedDiscount, setselectedDiscount] = useState(0);
  let tempvat = 0;
  const updatecart = (e) => {
    setvat(0);
    let totaltemp = 0;
    for (let i = 0; i < e.length; i++) {
      totaltemp += calculate(e[i]);
    }
    settotal(parseFloat(totaltemp));
    setItems(e);
    setvat(parseFloat(tempvat));
    sessionStorage.setItem("products", JSON.stringify(e));
    updateDiscountValue(selectedDiscount);
    if (e.length > 0) {
      sessionStorage.setItem("total", JSON.stringify(parseFloat(totaltemp)));
      sessionStorage.setItem("vat", JSON.stringify(parseFloat(vat)));
    } else {
      sessionStorage.setItem("total", JSON.stringify(parseFloat(0)));
      sessionStorage.setItem("vat", JSON.stringify(parseFloat(0)));
      setpointsDiscountvalue(0);
      setselectedDiscount(0);
    }
  };
  /**
   * @function
   *  Calculate price of product in cart with quantity after apply discount
   * @name InventoryItem#calcuate
   * @param {object} prod - Object of product
   * @returns {float} price - calculated price of product and quantity
   */
  const calculate = (prod) => {
    let price = 0;
    if (prod.discounted_amount > 0) {
      price =
        (Number(prod.price) - Number(prod.discounted_amount)) *
        prod.newQuantity;
    } else {
      price = Number(prod.price) * prod.newQuantity;
    }
    if (prod.vat === 1 || prod?.vat === "1") {
      tempvat += price * (tax / 100);
      price = price * (tax / 100) + price;
    }
    return price;
  };
  /**
   * @function
   * @name InventoryItem#setlanguages
   * @description Set system lanuage
   * @param {string} e - Language i.e English/Arabic
   */
  const setlanguages = (e) => {
    strings.setLanguage(e);
    setlanguage(e);
    setdummy(!dummy);
  };
  /**
   * @function
   * @name InventoryItem#emptycart
   * @description  Delete all items from cart either on logout or successfull order
   */
  const emptycart = async () => {
    setItems([]);
    settotal(0);
    setvat(0);
  };
  /**
   * @function
   * @name InventoryItem#emptytotal
   * @description Set Total of cart to zero either on logout or successfull order
   *
   */
  const emptytotal = () => {
    settotal(0);
  };
  const [Apromo, setpromo] = useState({ promo_code: "" });
  const [Adiscount, setdiscount] = useState({ discount_code: "" });
  const [isCOD, setisCOD] = useState(false);
  const [discountValue, setdiscountValue] = useState(0);
  const [promoValue, setpromoValue] = useState(0);
  const [pointsDiscountvalue, setpointsDiscountvalue] = useState(0);
  const [tax, settax] = useState(null);

  /**
   *  @function
   * @name InventoryItem#updatepromo
   * @description Apply or Remove promo code on cart.
   * @param {object|null} p - Object of promo code or null
   *
   */
  const updatepromo = (p) => {
    setpromo(p);
    if (p) {
      let discount = Number(p?.points_for_customer);
      let value = Number(total) * (discount / 100);
      setpromoValue(value);
    } else setpromoValue(0);
  };

  /**
   * @function
   * @name InventoryItem#updateddiscount
   * @description  Apply or remove discount code on cart
   * @param {object|null} d - Object of discount code or null
   */
  const updatediscount = (d) => {
    setdiscount(d);
    if (d) {
      let discount = Number(d?.discount_percent);
      let value = Number(total) * (discount / 100);
      setdiscountValue(value);
    } else setdiscountValue(0);
  };

  /**
   * @function
   * @name InventoryItem#updateisCOD
   * @description  Update check for order that is it cash on delivery.
   * @param {string} value - COD/Card
   */
  const updateisCOD = (value) => {
    if (value === "CashonDelivery") setisCOD(true);
    else setisCOD(false);
  };

  /**
   * @function
   * @name InventoryItem#updateDiscountValue
   * @description  calculate disount value on cart.
   * @param {number} e - Discount percentage value
   */
  const updateDiscountValue = (e) => {
    setselectedDiscount(e);
    let pointsdiscount = total * ((e * 10) / 100);
    setpointsDiscountvalue(pointsdiscount);
  };

  /**
   * @function
   * @name InventoryItem#updateIsInternational
   * @description Check if order is to internation country or suadia arabia.
   * @param {number} value - 1/0 for internation/not international customer
   */
  const updateIsInternational = (value) => {
    setisInternational(value !== 0 ? true : false);
  };

  const [shipping, setshipping] = useState(0);
  const [internationalshipping, setInternationalshipping] = useState({
    international_shipping: 0,
    free_shipping: 0,
  });
  const [freeShipping, setfreeShipping] = useState(0);
  const [isInternational, setisInternational] = useState(false);
  /**
   * @typedef {Number} cod
   */

  /**
   * @callback setcod
   * @param {cod} state
   * @returns {void}
   */

  /**
   *
   * @namespace {Object}
   * @property {cod} 0
   * @property {setcod} 1
   */
  const [cod, setcod] = useState(0);

  useEffect(() => {
    /**
     * @function
     * @name InventoryItem#init
     * @description  Init method is called on component load to get standard shipping rate, cod charges and vat(tax)
     */
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}setting/shippingrate/get`)
        .then((res) => {
          setshipping(res.data?.shipping_rate);
          setInternationalshipping({
            international_shipping: res.data?.international_charges,
            free_shipping: res.data?.free_international_shipping,
          });
          setfreeShipping(res.data?.free_shipping);
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}setting/cod/get`)
        .then((res) => {
          setcod(res.data?.charges);
        })
        .catch((error) => {});
      await axios
        .get(`${Config.SERVER_URL}setting/tax/get`)
        .then((res) => {
          settax(res.data?.tax_value);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  /**
   * @function
   * @name InventoryItem#returnValue
   * @description  Return Value method is called to get calcaluate value on base of value.
   * @param {string} value - condition for value to be returned
   */
  const returnValue = (value) => {
    switch (value) {
      case "total":
        return parseFloat(total).toFixed(2);
      case "vat":
        return parseFloat(vat).toFixed(2);
      case "discount":
        return parseFloat(discountValue).toFixed(2);
      case "promo":
        return parseFloat(promoValue).toFixed(2);
      case "shipping":
        const amount = isInternational
          ? parseFloat(internationalshipping.international_shipping).toFixed(2)
          : parseFloat(shipping).toFixed(2);
        return returnValue("isFree") ? amount : 0;
      case "free_shipping":
        return parseFloat(freeShipping).toFixed(0);
      case "cod":
        return parseFloat(cod).toFixed(0);
      case "isFree":
        return isInternational
          ? parseFloat(total) <= internationalshipping.free_shipping
          : parseFloat(total) <= freeShipping;
      case "selectedDiscount":
        return selectedDiscount;
      case "pointsDiscount":
        return parseFloat(pointsDiscountvalue).toFixed(2);
      default:
        if (Object.keys(items).length > 0) {
          let cal = isInternational
            ? internationalshipping.free_shipping <= total
              ? 0
              : parseFloat(internationalshipping.international_shipping)
            : freeShipping <= total
            ? 0
            : parseFloat(shipping);
          cal +=
            parseFloat(total) -
            parseFloat(discountValue) -
            parseFloat(pointsDiscountvalue) -
            parseFloat(promoValue) +
            (isCOD ? parseFloat(cod) : 0);
          return parseFloat(cal).toFixed(2);
        } else {
          return 0.0;
        }
    }
  };
  return (
    <InventoryItemContext.Provider
      value={{
        items,
        updatecart,
        total,
        setlanguages,
        lang,
        emptycart,
        emptytotal,
        vat,
        updatediscount,
        updateisCOD,
        updatepromo,
        Adiscount,
        Apromo,
        isCOD,
        promoValue,
        discountValue,
        returnValue,
        open,
        handleClose,
        handleOpen,
        updateDiscountValue,
        updateIsInternational,
      }}
    >
      {props.children}
    </InventoryItemContext.Provider>
  );
}
