import strings from "./../../../lang/lang";
import React, { useState } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import {
  makeStyles,
  Button,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import Footer from "../../../layout/Footer";
import { FormControl } from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from "axios";
import Config from "../../../config.json";
import Alert from "@material-ui/lab/Alert";
import Header from "./../../../layout/Header";
import FullPageLoader from "../../components/FullPageLoader";
const useStyles = makeStyles(() => ({
  Button: {
    backgroundColor: "#BF6159",
    width: "100%",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  link: {
    display: "block",
    textAlign: "center",
    margin: "15px",
    textDecoration: "none",
    color: "#BF6159",
    "&:hover": {
      color: "#BF6159",
    },
  },
}));
export default function VerifyAccount() {
  const classes = useStyles();
  const [UserForm, setUserForm] = useState({ code: "", email: null });
  const { register, handleSubmit, errors } = useForm();
  const location = useHistory();
  const { id } = useParams();
  const [error, seterror] = useState({});
  const [loading, setloading] = useState(false);
  const onSubmit = async (data) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/verify`, {
        code: UserForm.code,
        id: id ? id : null,
        email: UserForm?.email ? UserForm?.email : null,
      })
      .then((res) => {
        setloading(false);
        location.push("/login");
      })
      .catch((error) => {
        error && error.response.status === 400 && seterror(error.response.data);
        setloading(false);
      });
  };
  const handleFormChange = (e) => {
    let tempUserForm = UserForm;
    tempUserForm[e.target.name] = e.target.value;
    setUserForm(tempUserForm);
  };
  return <Redirect to="/login" />;
  // eslint-disable-next-line
  return (
    <>
      <FullPageLoader loading={loading} />
      <div>
        <Header />
        <div className="d-block d-lg-flex brands-header mx-140">
          <ol className="breadcrumb my-auto">
            <li className="breadcrumb-item">
              <Link to="/">{strings.Home}</Link>
            </li>
            <li className="breadcrumb-item active">{strings.Login}</li>
          </ol>
        </div>
        <p className="f-43 text-center">{strings.VerifyCode}</p>
        <Divider />
        <Grid
          item
          xs={11}
          sm={11}
          md={6}
          lg={3}
          className="mx-auto my-5"
          justify="center"
          alignItems="center"
        >
          {error &&
            Object.keys(error).map((key) => {
              return (
                <Alert severity="error" key={key}>
                  {error[key]}
                </Alert>
              );
            })}
          <form onSubmit={handleSubmit(onSubmit)}>
            {id ? (
              ""
            ) : (
              <FormControl margin="normal" className="d-block">
                <label>{strings.EmailMobile}</label>
                <TextField
                  id="Email"
                  type="text"
                  name="email"
                  variant="outlined"
                  onChange={(e) => {
                    handleFormChange(e);
                  }}
                  className={classes.input}
                  inputRef={register({
                    required: "required",
                  })}
                  size="small"
                />
                <span className="error">{errors.email?.message}</span>
              </FormControl>
            )}
            <FormControl margin="normal" className="d-block">
              <label>{strings.VerificationCodeLabel}</label>
              <TextField
                id="Code"
                type="text"
                name="code"
                variant="outlined"
                onChange={(e) => {
                  handleFormChange(e);
                }}
                className={classes.input}
                inputRef={register({
                  required: "required",
                })}
                size="small"
              />
              <span className="error">{errors.email?.message}</span>
            </FormControl>
            <div className="d-flex-center-center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.Button}
              >
                {strings.Verify}
              </Button>
            </div>
          </form>
        </Grid>
        <Footer />
      </div>
    </>
  );
}
