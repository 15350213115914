import React from "react";
import { withStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import strings from "./../../lang/lang";

const BootstrapInput = withStyles((theme) => ({
  input: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #bb534a",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "caudex",
  },
}))(InputBase);

export default function CountriesSelect({ currentCountry, handleChange }) {
  const [country, setCountry] = React.useState(currentCountry);
  const updateSelectCountry = (e) => {
    handleChange(e);
    setCountry(e.target.value);
  };
  return (
    <NativeSelect
      id="Countries-select"
      value={country}
      onChange={updateSelectCountry}
      input={<BootstrapInput />}
      name="country"
    >
      <option value="Saudi Arabia">{strings.SaudiArabia}</option>
      <option value="Kuwait">{strings.Kuwait}</option>
      <option value="Emirates">{strings.Emirates}</option>
      <option value="Qatar">{strings.Qatar}</option>
      <option value="Bahrain">{strings.Bahrain}</option>
      <option value="Oman">{strings.Oman}</option>
      <option value="Egypt">{strings.Egypt}</option>
      <option value="Jordan">{strings.Jordan}</option>
      <option value="Yamen">{strings.Yamen}</option>
      <option value="Iraq">{strings.Iraq}</option>
    </NativeSelect>
  );
}
