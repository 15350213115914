import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import Footer from "../../../layout/Footer";
import { useForm } from "react-hook-form";
import { FormControl, Typography } from "@material-ui/core";
import strings from "./../../../lang/lang";
import axios from "axios";
import Config from "../../../config.json";
import Alert from "@material-ui/lab/Alert";
import { useHistory, Link } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader";
import Header from "./../../../layout/Header";
const useStyles = makeStyles(() => ({
  Button: {
    backgroundColor: "#BF6159",
    width: "100%",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  link: {
    display: "block",
    textAlign: "center",
    margin: "15px",
    textDecoration: "none",
    color: "#BF6159",
    "&:hover": {
      color: "#BF6159",
    },
  },
}));
export default function ResetPassword({ id }) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setloading] = useState(false);

  const [UserForm, setUserForm] = useState({
    password: "",
    confirm_password: "",
  });
  const handleFormChange = (e) => {
    let tempUserForm = UserForm;
    tempUserForm[e.target.name] = e.target.value;
    setUserForm(tempUserForm);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useHistory();
  const [error, seterror] = useState({});
  const onSubmit = async (data) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/resetpassword`, {
        ...UserForm,
        customer_id: id,
      })
      .then((res) => {
        setloading(false);
        location.push("/login");
      })
      .catch((error) => {
        error && error.response.status === 400 && seterror(error.response.data);
        setloading(false);
      });
  };
  return (
    <div>
      <FullPageLoader loading={loading} />
      <Header />
      <div className="d-block d-lg-flex brands-header mx-140">
        <ol className="breadcrumb my-auto">
          <li className="breadcrumb-item">
            <Link to="/">{strings.Home}</Link>
          </li>
          <li className="breadcrumb-item active">{strings.Login}</li>
        </ol>
      </div>
      <p className="f-43 text-center">{strings.ResetPassword}</p>
      <Divider />
      <Grid
        item
        xs={11}
        sm={11}
        md={6}
        lg={3}
        className="mx-auto my-5"
        justify="center"
        alignItems="center"
      >
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert severity="error" key={key}>
                {error[key]}
              </Alert>
            );
          })}
        <Typography component="h4" variant="h5" align="center">
          Make new password
        </Typography>
        <Typography
          component="p"
          variant="caption"
          align="center"
          className="f-16"
        >
          Enter your password for next time login
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl margin="normal" className="d-block">
            <label>{strings.NewPassword}</label>
            <TextField
              id="password"
              type="Password"
              name="password"
              variant="outlined"
              onChange={(e) => {
                handleFormChange(e);
              }}
              className={classes.input}
              inputRef={register({
                required: "required",
              })}
              size="small"
            />
            {errors.password?.message}
          </FormControl>
          <FormControl margin="normal" className="d-block">
            <label>{strings.ConfirmPassword}</label>
            <TextField
              id="confirm_password"
              type="Password"
              name="confirm_password"
              variant="outlined"
              onChange={(e) => {
                handleFormChange(e);
              }}
              className={classes.input}
              inputRef={register({
                required: "required",
              })}
              size="small"
            />
            {errors.confirm_password?.message}
          </FormControl>
          <div className="d-flex-center-center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.Button}
            >
              {strings.Continue}
            </Button>
          </div>
        </form>
      </Grid>
      <Footer />
    </div>
  );
}
