import React from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
const useStyles = makeStyles(() => ({
  root: {
    padding: "5px",
    marginTop: "25px",
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  card: {
    width: 260,
    height: 240,
  },
}));
export default function OffersItem({ offer }) {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      justify="center"
      className={classes.root}
      xs={6}
      sm={4}
      lg={3}
    >
      <LazyLoad offset={250} height={260}>
        <Link to={`/products?offer=${offer?.id}`}>
          <img src={offer?.image} alt={offer?.name} className={classes.image} />
        </Link>
      </LazyLoad>
    </Grid>
  );
}
