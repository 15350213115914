import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../../config.json";
import { Typography, Divider, Box } from "@material-ui/core";
import Header from "./../../../layout/Header";
import Footer from "./../../../layout/Footer";
import { Container } from "@material-ui/core";
import strings from "./../../../lang/lang";
import FullPageLoader from "./../../components/FullPageLoader";
function PrivacyPolicy() {
  const [loading, setloading] = useState(false);
  const [policy, setpolicy] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    setloading(true);
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}policies/get`)
        .then((res) => {
          setpolicy(res.data?.body);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, []);
  function createMarkup() {
    return { __html: policy };
  }

  return (
    <>
      <FullPageLoader loading={loading} />
      <Header />
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h1"
          className="text-center f-43"
          style={{ marginTop: 119 }}
        >
          {strings.PrivacyPolicy}
        </Typography>
        <Divider className="my-2" />
        <Box dangerouslySetInnerHTML={createMarkup()}></Box>
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
