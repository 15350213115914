import React, { useState, useEffect } from "react";
import { Divider, Typography, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Config from "../../../../config.json";
import Alert from "@material-ui/lab/Alert";
import ReturnOrder from "./Return";
import strings from "./../../../../lang/lang";
import FullPageLoader from "../../../components/FullPageLoader";

function OrderHistory({ id }) {
  const [order, setorder] = useState(null);
  const [error, seterror] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [returnOrder, setreturnOrder] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}order/details/${user?.id}/${id}`)
        .then((res) => {
          setisLoading(false);
          setorder(res.data);
        })
        .catch((errors) => {
          setisLoading(false);
          if (errors?.response?.status === 400) seterror(errors?.response.data);
        });
    };
    init();
  }, [id, user, returnOrder]);
  return (
    <>
      {returnOrder ? (
        <ReturnOrder id={id} setreturnOrder={setreturnOrder} />
      ) : (
        <>
          <FullPageLoader loading={isLoading} />
          {!!error &&
            Object.keys(error).map((key) => {
              return (
                <Alert severity="error" key={key}>
                  {error[key]}
                </Alert>
              );
            })}
          {isLoading ? (
            <Typography component="h6" variant="h6">
              Loading Order Please wait
            </Typography>
          ) : (
            <>
              <Typography component="h4" variant="h5" align="center">
                Orders
              </Typography>
              <Divider />
              <Grid container spacing={3}>
                {/* Level 1*/}
                <Grid
                  container
                  justify="space-between"
                  justifyContent="space-between"
                  item
                  xs={12}
                  md={7}
                >
                  {/* Level 2*/}

                  {/* Personal Information Column */}
                  <Grid item xs={12} md={6} className="px-1 mt-4">
                    <Typography component="h4" variant="h5" className="mt-3">
                      ID-{id}
                    </Typography>
                    <Typography component="h4" variant="h6" className="d-block">
                      {strings.PersonalInformation}
                    </Typography>
                    <Box className="mt-2">
                      <Typography component="label" variant="body1">
                        {strings.FullName}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {order?.customer?.first_name}
                        {order?.customer?.last_name}
                      </Typography>
                    </Box>
                    <Box className="mt-2">
                      <Typography component="label" variant="body1">
                        {strings.Email}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {order?.customer?.email}
                      </Typography>
                    </Box>
                    <Box className="mt-2">
                      <Typography component="label" variant="body1">
                        {strings.PhoneNumber}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {order?.customer?.phone}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Shipping Address Column */}
                  <Grid item xs={12} md={6} className="mt-lg-70">
                    {order?.status === "returned" ? (
                      <span></span>
                    ) : (
                      <span
                        onClick={() => setreturnOrder(true)}
                        className="text-main"
                        style={{ cursor: "pointer" }}
                      >
                        {strings.ReturnOrder}
                      </span>
                    )}
                    <Typography component="h4" variant="h6" className="d-block">
                      {strings.ShippingAddress}
                    </Typography>
                    <Box p={1} className="mt-2">
                      <Typography component="label" variant="body1">
                        {strings.Addresses}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {order?.address}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box p={1} className="mt-2">
                        <Typography component="label" variant="body1">
                          {strings.Country}
                        </Typography>
                        <Typography component="p" variant="body2">
                          {order?.country ? order?.country : "N/A"}
                        </Typography>
                      </Box>
                      <Box p={1} className="mt-2">
                        <Typography component="label" variant="body1">
                          {strings.City}
                        </Typography>
                        <Typography component="p" variant="body2">
                          {order?.city ? order?.city : "N/A"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box p={1} className="mt-2">
                      <Typography component="label" variant="body1">
                        {strings.PostalCode}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {order?.postal_code ? order?.postal_code : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Payment Information */}
                  <Grid item xs={12} md={6} className="mt-4">
                    <Typography component="h4" variant="h6" className="d-block">
                      {strings.PaymentMethod}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      className="d-block"
                    >
                      {strings.BankCardInfo}
                    </Typography>
                    <Box p={1} className="mt-2">
                      <Typography component="label" variant="body1">
                        {strings.NameOnCard}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {order?.payment?.name_on_card
                          ? order?.payment?.name_on_card
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box p={1} className="mt-2">
                      <Typography component="label" variant="body1">
                        {strings.CardNumber}
                      </Typography>
                      <Typography component="p" variant="body2">
                        {order?.payment?.number_on_card
                          ? order?.payment?.number_on_card
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {/* Right Side */}
                <Grid
                  container
                  justify="space-between"
                  justifyContent="space-between"
                  item
                  xs={12}
                  md={5}
                  className="border-left mt-lg-70"
                >
                  {!!order?.cart && <Cart cartItem={order?.cart} />}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
function Cart({ cartItem }) {
  return (
    <>
      <Box>
        <Typography variant="h4" component="h3" className="my-2">
          {strings.Orderitems}
        </Typography>

        {!!cartItem &&
          cartItem?.map((item) => {
            return (
              <Grid container spacing={1}>
                <Grid md={3} item>
                  <img
                    src={item?.product?.productimages[0]?.picture_reference}
                    style={{ width: "100%", height: "auto" }}
                    alt={item?.product?.product_name}
                  />
                </Grid>
                <Grid item md={9} container justify="space-between">
                  <Box p={2}>
                    <Typography>{item?.product?.product_name}</Typography>
                    <Typography>{item?.product?.model}</Typography>
                  </Box>
                  <Box>
                    <Typography>{item?.price}</Typography>
                    <Typography>{item?.quantity}</Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </>
  );
}
export default OrderHistory;
