import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import axios from "axios";
import Config from "../../../../config.json";
import { Divider } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Alert from "@material-ui/lab/Alert";
import strings from "./../../../../lang/lang";
import FullPageLoader from "../../../components/FullPageLoader";
function ReturnOrder({ id, setreturnOrder }) {
  const [ReturnProducts, setReturnProducts] = useState([]);
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [order, setorder] = useState(null);
  const addToReturnProducts = (item) => {
    setReturnProducts([...ReturnProducts, item]);
  };
  const removeFromReturnProducts = (item) => {
    let rProducts = ReturnProducts.filter((prod) => {
      return prod.id !== item.id;
    });
    setReturnProducts(rProducts);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}order/details/${user?.id}/${id}`)
        .then((res) => {
          setisLoading(false);
          setorder(res.data);
        })
        .catch((error) => {
          setisLoading(false);
          if (error?.response?.status === 400) seterror(error?.response.data);
        });
    };
    init();
  }, [id, user]);
  const [ReturnReason, setReturnReason] = useState("");
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setisLoading] = useState(false);
  const onSubmit = async () => {
    setisLoading(true);
    await axios
      .post(`${Config.SERVER_URL}orders/return/${user?.id}`, {
        order_id: id,
        cartitems: JSON.stringify(ReturnProducts),
        return_reason: ReturnReason,
      })
      .then(() => {
        setsuccess(true);
        setTimeout(() => {
          setreturnOrder(false);
        }, 3000);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  return (
    <>
      <FullPageLoader loading={isLoading} />
      <span
        className="back"
        onClick={() => setreturnOrder(false)}
        style={{ cursor: "pointer" }}
      >
        <ArrowBackIosIcon /> Back
      </span>
      <form onSubmit={handleSubmit(onSubmit)}>
        {success && <Alert severity="success">{strings.OrderReturned}</Alert>}
        {error &&
          Object.keys(error).map((key) => {
            return <Alert severity="error">{error[key]}</Alert>;
          })}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="border-right">
            <Typography variant="h5" className="my-3">
              {strings.ReturnOrder}
            </Typography>
            <Typography variant="h5" component="h4">
              ID-{id}
            </Typography>
            <Order
              cart={order?.cart}
              total={order?.total}
              addToReturnProducts={addToReturnProducts}
              removeFromReturnProducts={removeFromReturnProducts}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">{strings.ReturnReason}</Typography>
            <Box p={2}>
              <TextField
                color="primary"
                variant="outlined"
                size="small"
                name="return_reason"
                onChange={(e) => setReturnReason(e.target.value)}
                fullWidth
                inputRef={register({
                  required: "required",
                  minLength: {
                    value: 10,
                    message: "Return Reason should be atleast 10 characters.",
                  },
                })}
              />
              <span className="error"> {errors.return_reason?.message}</span>
            </Box>
          </Grid>
          <Grid container item xs={12} justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="w-md-100"
              disabled={isLoading}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
const orderStyles = makeStyles(() => ({
  totalRoot: {
    "& p": {
      fontWeight: "bold",
    },
  },
}));

function Order({ cart, total, removeFromReturnProducts, addToReturnProducts }) {
  const classes = orderStyles();
  return (
    <>
      {cart &&
        cart?.map((cartitem) => {
          return (
            <OrderItem
              item={cartitem}
              addToReturnProducts={addToReturnProducts}
              removeFromReturnProducts={removeFromReturnProducts}
            />
          );
        })}
      <div className={classes.totalRoot}>
        <div className="d-flex-between-center">
          <p>{strings.Total}</p>
          <p>
            {strings.SAR} {total}
          </p>
        </div>
      </div>
    </>
  );
}

function OrderItem({ item, addToReturnProducts, removeFromReturnProducts }) {
  const [checked, setchecked] = useState(false);
  function handleChange() {
    if (checked) {
      removeFromReturnProducts(item);
    } else {
      addToReturnProducts(item);
    }
    setchecked(!checked);
  }
  return (
    <>
      <Grid container style={{ opacity: checked ? "1" : "0.7" }}>
        <Grid item xs={4} container alignItems="center">
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <img
            src={item?.product?.productimages[0]?.picture_reference}
            alt=""
            width="69"
            height="59"
            className="mx-auto"
          />
        </Grid>
        <Grid item xs={8}>
          <div className="d-flex-between-center">
            <h6>{item?.product?.product_name}</h6>
            <p className="text-right px-4">
              {strings.Price}: {strings.SAR} {item?.price}
            </p>
          </div>
          <div className="d-flex-end-center">
            <p className="text-right px-4">
              {strings.Quantity}: {item?.quantity}
            </p>
          </div>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
export default ReturnOrder;
