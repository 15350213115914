import strings from "./../../../lang/lang";
import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  makeStyles,
  Button,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import Footer from "../../../layout/Footer";
import { useForm } from "react-hook-form";
import { FormControl } from "@material-ui/core";
import axios from "axios";
import Config from "../../../config.json";
import Alert from "@material-ui/lab/Alert";
import { useInventoryItems } from "./../../../Context/CartContext";
import FullPageLoader from "../../components/FullPageLoader";
import Header from "./../../../layout/Header";
const useStyles = makeStyles(() => ({
  Button: {
    backgroundColor: "#BF6159",
    width: "100%",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  link: {
    display: "block",
    textAlign: "right",
    margin: "5px",
    paddingBottom: "15px",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#BF6159",
    },
  },
  createlink: {
    display: "block",
    textAlign: "center",
    margin: "25px 5px",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#BF6159",
    },
  },
}));
function Login() {
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  let user = sessionStorage.getItem("user")
    ? sessionStorage.getItem("user")
    : null;
  const location = useHistory();
  // eslint-disable-next-line
  const [isLogined, setisLogined] = useState(user);
  const [UserForm, setUserForm] = useState({ email: "", password: "" });
  const [UsersFormError, setUsersFormError] = useState(false);
  const { handleOpen } = useInventoryItems();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = async (data) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customers/login`, UserForm)
      .then((response) => {
        let user = response.data;
        if (user.email === "null") {
          user.email = "";
        }
        sessionStorage.setItem("user", JSON.stringify(user));
        setloading(false);
        handleOpen();
        location.push("/");
      })
      .catch((error) => {
        setUsersFormError(true);
        setloading(false);
      });
  };
  const handleFormChange = (e) => {
    let tempUserForm = UserForm;
    tempUserForm[e.target.name] = e.target.value;
    setUserForm(tempUserForm);
  };
  return (
    <div>
      {isLogined ? <Redirect to="/" /> : ""}
      <FullPageLoader loading={loading} />
      <Header />
      <div className="d-block d-lg-flex brands-header mx-140 ">
        <ol className="breadcrumb my-auto">
          <li className="breadcrumb-item">
            <Link to="/">{strings.Home}</Link>
          </li>
          <li className="breadcrumb-item active">{strings.Login}</li>
        </ol>
      </div>
      <p className="f-43 text-center">{strings.Login}</p>
      <Divider />
      <Grid
        item
        xs={11}
        sm={11}
        md={6}
        lg={3}
        container
        className="mx-auto py-4"
        justify="center"
        alignItems="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {UsersFormError && (
            <Alert severity="error">{strings.InvalidCredentials}</Alert>
          )}
          <FormControl margin="normal" className="d-block">
            <label>{strings.EmailMobile}</label>
            <TextField
              id="Email"
              type="text"
              name="email"
              variant="outlined"
              onChange={(e) => {
                handleFormChange(e);
              }}
              className={classes.input}
              placeholder="966xxxxxxxxxx"
              inputRef={register({
                required: "required",
              })}
              size="small"
            />
            <span className="error">{errors.email?.message}</span>
          </FormControl>
          <FormControl margin="normal" className="d-block">
            <label>{strings.Password}</label>
            <TextField
              id="Password"
              type="Password"
              name="password"
              variant="outlined"
              onChange={(e) => {
                handleFormChange(e);
              }}
              className={classes.input}
              inputRef={register({
                required: "required",
              })}
              size="small"
            />
            <span className="error">{errors.password?.message}</span>
          </FormControl>
          <Link to="/forgot-password" className={classes.link}>
            {strings.ForgotPassword}
          </Link>
          <div className="d-flex-center-center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.Button}
            >
              {strings.Signin}
            </Button>
          </div>
          <Link to="/register" className={classes.createlink}>
            {strings.CreateAccount}
          </Link>
          <Link to="/verify-account" className={classes.createlink}>
            Verify Account
          </Link>
        </form>
      </Grid>
      <Footer />
    </div>
  );
}

export default Login;
