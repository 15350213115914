import React, { useState, useEffect } from "react";
import Header from "./../../../layout/Header";
import Footer from "./../../../layout/Footer";
import { Box, Breadcrumbs, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import Lightbox from "react-awesome-lightbox";
import axios from "axios";
import Config from "../../../config.json";
const useStyles = makeStyles(() => ({
  media: {
    maxWidth: "100%",
    height: "auto",
  },
}));

const slides = [];
export default function GalleryDetails() {
  let { detail } = useParams();
  const classes = useStyles();
  const [open, setopen] = useState(false);
  const [showIndex, setshowIndex] = useState(0);
  const [models, setmodels] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}media/album/${detail}`)
        .then((response) => {
          if (response.data) setmodels(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, [detail]);

  return (
    <>
      <Header />
      <div className="d-flex brands-header mx-140 border-bottom-main mb-5">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/gallery">
            Gallery
          </Link>
          <Link color="inherit" to="#" disabled>
            Details
          </Link>
        </Breadcrumbs>
      </div>
      <p className="font-2 text-center">{models.name} </p>
      <Box className="mx-140">
        <Grid container justify="center" spacing={3}>
          {models?.mediapictures?.map((d, index) => {
            return (
              <Grid
                item
                xs={4}
                key={index}
                className="mt-4 d-flex-center-center"
              >
                <Card
                  onClick={() => {
                    setopen(true);
                    setshowIndex(index);
                  }}
                >
                  <img
                    src={d.image}
                    alt={models.name}
                    className={classes.media}
                  />
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {open && (
        <Lightbox
          images={slides}
          onClose={() => setopen(false)}
          startIndex={showIndex}
        />
      )}
      <Footer />
    </>
  );
}
