import React from "react";
import strings from "./../../lang/lang";
import { Dialog, makeStyles } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(() => ({
  bottom: {
    paddingBottom: 53,
    "& button": {
      minWidth: 156,
    },
  },
  body: {},
}));
function LoginModal({ open, handleClose }) {
  const location = useHistory();
  const loginModalHandle = () => {
    handleClose();
    setTimeout(() => {
      location.push("/");
    }, 500);
  };
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      className="loginModal"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        {strings.WelcomeToAdva}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="text-light text-center mt-3"
        >
          {strings.Youhavesuccessfullyloggedin} <br />
          {strings.Getupto50discountonourlatestproducts}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={`d-flex justify-content-center ${classes.bottom}`}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => loginModalHandle()}
        >
          {strings.ShopNow}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoginModal;
