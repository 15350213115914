import React, { useState, useEffect } from "react";
import Header from "./../../../layout/Header";
import Footer from "./../../../layout/Footer";
import {
  AppBar,
  Button,
  Grid,
  Box,
  TextField,
  Paper,
  Avatar,
  OutlinedInput,
  Hidden,
  InputAdornment,
  FormControl,
  TextareaAutosize,
  IconButton,
  Menu,
  List,
  ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import Config from "../../../config.json";
import Logo from "../../../assets/logo/ADVA.png";
import SendIcon from "@material-ui/icons/Send";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import strings from "../../../lang/lang";
import { useInventoryItems } from "./../../../Context/CartContext";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ImageIcon from "@material-ui/icons/Image";
import { DeleteForever } from "@material-ui/icons";
import ShareIcon from "@material-ui/icons/Share";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, WhatsappIcon } from "react-share";
import FullPageLoader from "../../components/FullPageLoader";

const useStyles = makeStyles(() => ({
  media: {
    maxWidth: "100%",
    height: "auto",
  },
  comments: {
    maxHeight: "200px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  comment: {
    backgroundColor: "#F8F8F88A",
  },
  postbutton: {
    maxWidth: 266,
    maxHeight: 81,
    border: "1px solid #e1e1e1",
    "& button": {
      maxWidth: 266,
      maxHeight: 81,
    },
  },
}));
export default function Gallery() {
  const classes = useStyles();
  const [search, setsearch] = useState("");
  const [posts, setposts] = useState(null);
  const [filter, setfilter] = useState("");
  const { lang } = useInventoryItems();
  strings.setLanguage(lang);
  const [customer] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [loading, setloading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      setloading(true);
      await axios
        .get(`${Config.SERVER_URL}posts?search=${search}&filter=${filter}`)
        .then((res) => {
          setposts(res.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, [search, filter]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const location = useHistory();
  const likepost = async (id) => {
    if (!customer) {
      location.push("/login");
      return;
    }
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/like/${id}`, {
        customer_id: customer?.id,
      })
      .then((res) => {
        setposts(res.data);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  const deletepost = async (id) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}post/delete/${customer?.id}/${id}`)
      .then((res) => {
        setposts(res.data);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  return (
    <>
      <FullPageLoader loading={loading} />
      <AddPost
        isopen={open}
        handleClose={() => handleClose()}
        setposts={setposts}
      />
      <Header />
      <AppBar
        position="static"
        color="transparent"
        className="py-2"
        style={{ boxShadow: "0px 3px 6px #9E9E9E29" }}
      >
        <Grid container justify="center">
          <Grid item xs={11} md={4}>
            <TextField
              variant="outlined"
              color="primary"
              fullWidth
              size="small"
              placeholder={strings.SearchForPost}
              onChange={(e) => setsearch(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </AppBar>
      <Container maxWidth="lg" className="mt-5">
        <Hidden mdUp>
          <Grid item xs={12} container className="mb-5">
            <Paper
              className="w-100"
              style={{
                overflow: "hidden",
                border: "1px solid rgba(112, 112, 112, 0.3)",
              }}
              evelation={0}
            >
              <Button
                className="p-3 w-100 d-block"
                onClick={handleClickOpen}
                style={{
                  overflow: "hidden",
                  boxShadow: "0px 3px 6px #9E9E9E29",
                }}
              >
                <Typography
                  className="text-main font-weight-bold f-31"
                  align="center"
                >
                  <ImageIcon className="text-main mx-2" /> {strings.AddAPost}
                </Typography>
                <p className="f-12 mb-0">{strings.UploadYourImage}</p>
              </Button>
            </Paper>
            <Box
              className="my-3 w-100"
              display="flex"
              justifyContent="space-evenly"
            >
              <Typography component="p" variant="button" align="center">
                <span
                  onClick={() => setfilter("top")}
                  style={{
                    borderBottom: filter === "top" ? "1px solid #BF6159" : "",
                    cursor: "pointer",
                  }}
                >
                  {strings.TopPost}
                </span>
              </Typography>
              <Typography component="p" variant="button" align="center">
                <span
                  onClick={() => setfilter("recent")}
                  style={{
                    borderBottom:
                      filter === "recent" ? "1px solid #BF6159" : "",
                    cursor: "pointer",
                  }}
                >
                  {strings.RecentPost}
                </span>
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item xs={3}>
              <Paper
                className={classes.postbutton}
                elevation={0}
                style={{ overflow: "hidden" }}
              >
                <Button
                  className="p-3 w-100 d-block"
                  onClick={handleClickOpen}
                  style={{
                    overflow: "hidden",
                    boxShadow: "0px 3px 6px #9E9E9E29",
                  }}
                  disableFocusRipple
                  disableRipple
                >
                  <Typography className="text-main" align="center">
                    <ImageIcon className="text-main mx-2" />
                    {strings.AddAPost}
                  </Typography>
                  <p className="font-0">{strings.UploadYourImage}</p>
                </Button>
              </Paper>
              <Typography
                variant="h6"
                component="h6"
                align="center"
                className="mt-2"
              >
                {strings.ShowPosts}
              </Typography>
              <Box className="mt-3">
                <Typography component="p" variant="button" align="center">
                  <span
                    onClick={() => setfilter("top")}
                    style={{
                      borderBottom: filter === "top" ? "1px solid #BF6159" : "",
                      fontWeight: filter === "top" ? "bold" : "",
                      marginLeft:
                        lang.localeCompare("en") === 0 ? "-12px" : "0px",
                      marginRight:
                        lang.localeCompare("en") === 0 ? "0px" : "-12px",
                      cursor: "pointer",
                    }}
                  >
                    {strings.TopPost}
                  </span>
                </Typography>
                <Typography component="p" variant="button" align="center">
                  <span
                    onClick={() => setfilter("recent")}
                    style={{
                      borderBottom:
                        filter === "recent" ? "1px solid #BF6159" : "",
                      fontWeight: filter === "recent" ? "bold" : "",
                      marginLeft:
                        lang.localeCompare("en") === 0 ? "25px" : "0px",
                      marginRight:
                        lang.localeCompare("en") === 0 ? "0px" : "25px",
                      cursor: "pointer",
                    }}
                  >
                    {strings.RecentPost}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} lg={6} md={8}>
            {posts &&
              posts?.map((post, index) => {
                return (
                  <Paper
                    evelation={2}
                    className="mb-5 post-style"
                    key={index}
                    style={{ width: "98%", margin: "auto" }}
                    elevation={0}
                  >
                    <Box
                      style={{
                        borderBottom: "1px solid rgba(112, 112, 112, 0.12)",
                      }}
                    >
                      {post?.customer_id === 0 ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          className="px-4 pt-1"
                        >
                          <img alt="Adva" src={Logo} width="40" />
                          <Box className="px-2">
                            <label htmlFor="">ADVA</label>
                            <p>{post?.created_at}</p>
                          </Box>
                          <Divider />
                        </Box>
                      ) : (
                        <>
                          <Box display="flex" justifyContent="space-between">
                            <Box
                              display="flex"
                              alignItems="center"
                              className="px-4 pt-1"
                            >
                              <Avatar
                                alt="Adva"
                                src={post?.customer?.profile_image}
                                width="40"
                              />
                              <Box className="px-2">
                                <label htmlFor="">
                                  {post?.customer?.first_name}
                                </label>
                                <p>{post?.created_at}</p>
                              </Box>
                            </Box>
                            {post?.customer_id === customer?.id ? (
                              <Box
                                display="flex"
                                alignItems="center"
                                className="px-4"
                              >
                                <DeleteForever
                                  style={{ color: "red" }}
                                  onClick={() => deletepost(post?.id)}
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </>
                      )}
                      <Box
                        style={{
                          borderTop: "1px solid rgba(112, 112, 112, 0.12)",
                        }}
                      >
                        <Typography component="p" className="px-4">
                          {post?.description}
                        </Typography>
                        <img
                          src={post?.image}
                          alt="post"
                          style={{ width: "100%", height: "auto" }}
                        />
                      </Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Like post={post} likepost={likepost} />
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            className="text-main"
                          >
                            <ChatBubbleIcon />
                            <span className="text-dark mx-2 f-18">
                              {strings.Comment}: {post.comments_count}
                            </span>
                          </IconButton>
                          <ActionMenu />
                        </Grid>
                      </Grid>
                    </Box>
                    {post?.comments && (
                      <Box className={classes.comments}>
                        {post?.comments?.map((com, index) => {
                          return (
                            <Grid container spacing={2} key={index}>
                              <Grid
                                item
                                xs={3}
                                lg={1}
                                container
                                justify="center"
                                alignItems="center"
                                className="mx-3"
                              >
                                <Avatar
                                  variant="circular"
                                  src={com?.customer?.profile_image}
                                />
                              </Grid>
                              <Grid item xs>
                                <Box
                                  className={`py-2 my-1 px-5 ${classes.comment}`}
                                >
                                  <Typography>
                                    {com?.customer?.first_name}
                                  </Typography>
                                  <p>{com?.comment}</p>
                                </Box>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Box>
                    )}
                    <Newcomment post={post} setposts={setposts} />
                  </Paper>
                );
              })}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
function Like({ post, likepost }) {
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [isLiked, setisLiked] = useState(false);
  useEffect(() => {
    const init = async () => {
      if (user) {
        await axios
          .post(
            `${Config.SERVER_URL}customer/like/post/${post?.id}/${user?.id}`
          )
          .then((res) => {
            setisLiked(res.data);
          })
          .catch((error) => {});
      }
    };
    init();
  }, [user, post]);
  return (
    <IconButton
      disableFocusRipple
      disableRipple
      onClick={() => likepost(post?.id)}
      className="text-main"
    >
      {isLiked ? <ThumbUpIcon /> : <ThumbUpAltOutlinedIcon />}
      <span className="text-dark mx-2 f-18">
        {strings.Like}: {post.likes_count}
      </span>
    </IconButton>
  );
}

function Newcomment({ post, setposts }) {
  const [comment, setcomment] = useState("");
  const [User] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const location = useHistory();
  const addComment = async () => {
    if (!User) {
      location.push("/login");
      return;
    }
    await axios
      .post(`${Config.SERVER_URL}comment`, {
        post_id: post?.id,
        customer_id: User?.id,
        comment: comment,
      })
      .then((response) => {
        setposts(response.data);
        setcomment("");
      })
      .catch((error) => {});
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addComment();
    }
  };
  return (
    <Grid item xs={12} container>
      <OutlinedInput
        color="primary"
        onChange={(e) => setcomment(e.target.value)}
        endAdornment={
          <InputAdornment position="end" onClick={addComment}>
            <SendIcon className="text-main" />
          </InputAdornment>
        }
        fullWidth
        value={comment}
        placeholder={strings.TypeYourCommentHere}
        onKeyDown={handleKeyDown}
      />
    </Grid>
  );
}

function AddPost({ isopen, handleClose, setposts }) {
  const [Images, setImages] = useState(null);
  const [ImagesURL, setImagesURL] = useState(null);
  const [User] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  function handleImageChanges(e) {
    let imagesTemp = e.target.files[0];
    let imagesTempURL = URL.createObjectURL(e.target.files[0]);
    setImagesURL(imagesTempURL);
    setImages(imagesTemp);
  }
  const location = useHistory();
  const [description, setdescription] = useState("");
  const [error, seterror] = useState({});
  const handleSubmit = async () => {
    if (!User?.id) {
      location.push("/login");
      return;
    }
    const formData = new FormData();

    formData.append("image", Images);
    formData.append("description", description);
    formData.append("customer_id", User?.id);
    await axios
      .post(`${Config.SERVER_URL}media/create/post`, formData)
      .then((response) => {
        handleClose();
        setposts(response.data);
        setImages(null);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
      });
  };
  const closeModal = () => {
    handleClose();
    setisAgree(false);
  };
  const [isAgree, setisAgree] = useState(false);
  return (
    <Dialog
      open={isopen}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      {isAgree ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle id="alert-dialog-title">
              {strings.CreateAPost}
            </DialogTitle>
            <span
              className="font-25 mx-3"
              style={{ cursor: "pointer" }}
              onClick={closeModal}
            >
              &times;
            </span>
          </Box>
          <DialogContent>
            {error &&
              Object.keys(error).map((key) => {
                return <Alert severity="error">{error[key]}</Alert>;
              })}
            <FormControl fullWidth>
              <label>
                {strings.Description}
                <TextareaAutosize
                  rowsMin="5"
                  className="w-100"
                  onChange={(e) => setdescription(e.target.value)}
                />
              </label>
            </FormControl>
            <FormControl fullWidth>
              <label className="imageUpload">
                {strings.UploadImage}
                <input
                  type="file"
                  name="banner"
                  id="banner"
                  onChange={handleImageChanges}
                />
              </label>
            </FormControl>
            <Box display="flex" justifyContent="center">
              {Images && (
                <img src={ImagesURL} alt="" width="250" height="250" />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              className="w-100 d-block"
            >
              Add
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <DialogContentText>{strings.GalleryAgreement}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeModal}
              color="primary"
              variant="outlined"
              className="d-block mx-2"
            >
              {strings.Disagree}
            </Button>
            <Button
              onClick={() => setisAgree(true)}
              color="primary"
              variant="contained"
              className="d-block"
            >
              {strings.Agree}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

function ActionMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    background: "transparent",
  };
  return (
    <>
      <Button
        variant="text"
        className={classes.transparentButton}
        startIcon={<ShareIcon className="text-main font-25" />}
        onClick={handleClick}
        disableFocusRipple
        disableRipple
      >
        <span className=""> {strings.Share}</span>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ minWidth: 250, background: "transparent" }}
        elevation={0}
      >
        <List style={flexContainer}>
          <ListItem className="p-1">
            <FacebookShareButton
              url={window.location.href}
              quote="Do check out this amazing post, visit https://www.advabeauty.com for more"
            >
              <FacebookIcon size={35} round={true} className="p-0" />
            </FacebookShareButton>
          </ListItem>
          <ListItem className="p-1">
            <WhatsappShareButton
              url={window.location.href}
              title="Do check out this amazing post, visit https://www.advabeauty.com for more"
            >
              <WhatsappIcon size={35} round={true} className="p-0" />
            </WhatsappShareButton>
          </ListItem>
        </List>
      </Menu>
    </>
  );
}
