import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-awesome-lightbox/build/style.css";
import "react-phone-input-2/lib/style.css";
import InventoryItemsProvider from "./Context/CartContext";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import UserProvider from "./Context/UserContext";
import RTL from "./layout/rtl";
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Caudex", "cursive"].join(","),
  },
});
ReactDOM.render(
  <RTL>
    <ThemeProvider theme={theme}>
      <InventoryItemsProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </InventoryItemsProvider>
    </ThemeProvider>
  </RTL>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
