import React, { useState, useEffect } from "react";
import {
  Badge,
  Typography,
  Divider,
  Select,
  Paper,
  Box,
  MenuItem,
  Grid,
  makeStyles,
  Button,
  DialogContentText,
  DialogContent,
  TextField,
  Avatar,
} from "@material-ui/core";
import EditProfile from "../../../assets/icons/editprofile.png";
import strings from "./../../../lang/lang";
import { FormControl } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Config from "../../../config.json";
import Alert from "@material-ui/lab/Alert";
import FullPageLoader from "../../components/FullPageLoader";
import { message } from "antd";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  back: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  ProfileRoot: {
    // "& .MuiBadge-root": {
    //   margin: "10px 18%",
    // },
    "& .MuiBadge-badge": {
      height: 0,
    },
    "& .MuiAvatar-root": {
      width: "auto",
      height: "auto",
    },
    "& .MuiBadge-badge label": {
      cursor: "pointer",
    },
  },
  Button: {
    backgroundColor: "#BF6159",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  profile: {
    maxWidth: 200,
  },
}));
function Profile() {
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const [User, setUser] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [updateProfile, setupdateProfile] = useState({
    first_name: User?.first_name,
    last_name: User?.last_name,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [success, setsuccess] = useState(false);
  const [successmsg, setsuccessmsg] = useState("");
  const [error, seterror] = useState({});
  const [loading, setloading] = useState(false);
  const handleProfilePicture = async (e) => {
    setloading(true);
    setupdateProfile(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("profile_image", e.target.files[0]);
    await axios
      .post(`${Config.SERVER_URL}customer/profile/update/${User?.id}`, formData)
      .then((response) => {
        setloading(false);
        if (response.status) {
          setsuccess(true);
          setsuccessmsg("Profile Updated successfully");
          let user = JSON.parse(sessionStorage.getItem("user"));
          user.profile_image = response.data.profile_image;
          sessionStorage.setItem("user", JSON.stringify(user));
          setUser(user);
        }
      })
      .catch((error) => {
        setloading(false);
        if (error.response?.status === 400) seterror(error.response.data);
        setTimeout(() => {
          seterror({});
        }, 3000);
      });
  };
  const onSubmit = async (data) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}customer/update/${User?.id}`, {
        ...updateProfile,
      })
      .then((response) => {
        setloading(false);
        setsuccess(true);
        sessionStorage.setItem("user", JSON.stringify(response.data));
        setsuccessmsg("Profile Updated successfully");
      })
      .catch((errors) => {
        if (errors.response?.status === 400) seterror(errors.response?.data);
        setloading(false);
      });
  };
  function handleFormChange(e) {
    updateProfile[e.target.name] = e.target.value;
    setupdateProfile(updateProfile);
  }
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <FullPageLoader loading={loading} />
      <Typography component="h6" variant="h5" align="center">
        {strings.Profile}
      </Typography>
      <Divider />
      <Box
        component={Paper}
        p={3}
        marginTop={5}
        style={{
          boxShadow: "0px 3px 6px #0000001A",
          border: "1px solid #D2D2D2",
        }}
      >
        <Typography component="p" variant="h6">
          {strings.PersonalInformation}
        </Typography>
        <Divider />
        {success && <Alert severity="success">{successmsg}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={2}
            className={`${classes.root}`}
          >
            <Grid item md={3} className={classes.ProfileRoot}>
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <label>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleProfilePicture(e);
                      }}
                    />
                    <img src={EditProfile} alt="EditProfile" />
                  </label>
                }
              >
                {User?.profile_image?.length > 0 ? (
                  <img
                    src={User?.profile_image}
                    alt=""
                    className={classes.profile}
                  />
                ) : (
                  <Avatar
                    className={classes.profile}
                    style={{ width: 150, height: 150 }}
                  >
                    {User?.first_name.substring(0, 1)}
                  </Avatar>
                )}
              </Badge>
            </Grid>
            <Grid item md={9} className={classes.ProfileRoot}>
              <Grid container spacing={2}>
                <Grid item xs={12} md>
                  <FormControl margin="normal" className="d-block">
                    <label>{strings.FirstName}</label>
                    <TextField
                      id="FirstName"
                      type="text"
                      name="first_name"
                      variant="outlined"
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                      className={classes.input}
                      inputRef={register({
                        required: "required",
                      })}
                      defaultValue={User?.first_name}
                      size="small"
                    />
                    <span className="error">{error.first_name}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md>
                  <FormControl margin="normal" className="d-block">
                    <label>{strings.LastName}</label>
                    <TextField
                      id="LastName"
                      type="text"
                      name="last_name"
                      variant="outlined"
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                      className={classes.input}
                      inputRef={register({
                        required: "required",
                      })}
                      defaultValue={User?.last_name}
                      size="small"
                    />
                    <span className="error">{error.last_name}</span>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label>{strings.Communication}</label>
                  <FormControl fullWidth>
                    <Select
                      name="communication"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      value="1"
                      className={classes.select}
                    >
                      <MenuItem value={1}>English</MenuItem>
                      <MenuItem value={2}>العربية</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="font-md-10 "
                    onClick={() => handleClickOpen()}
                  >
                    {strings.ChangePassword}
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="d-flex-end-center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={`${classes.Button} w-md-100`}
                  >
                    {strings.Save}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
      <ChangePassword
        isopen={open}
        handleClose={() => handleClose()}
        setloading={setloading}
      />
    </>
  );
}

function ChangePassword({ isopen, handleClose, setloading }) {
  const [UserForm, setUserForm] = useState({});
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const handleChange = (e) => {
    UserForm[e.target.name] = e.target.value;
    setUserForm(UserForm);
  };
  const location = useHistory();
  const handleSubmit = async () => {
    setloading(true);
    const User = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null;
    if (!User) {
      location.push("/login");
      return;
    }

    await axios
      .post(`${Config.SERVER_URL}customer/password/update/${User?.id}`, {
        ...UserForm,
        id: User?.id,
      })
      .then((res) => {
        seterror({});
        setsuccess(true);
        handleClose();
        setloading(false);
        message.success("Password Updated successfully");
      })
      .catch((error) => {
        if (error.response.status === 400) seterror(error.response.data);
        setloading(false);
      });
  };
  return (
    <Dialog open={isopen} onClose={handleClose} maxWidth="lg">
      <DialogTitle id="alert-dialog-title">
        {strings.ChangePassword}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="p-3">
          {!!error &&
            Object.keys(error).map((key) => {
              return (
                <Alert severity="error" key={key}>
                  {error[key]}
                </Alert>
              );
            })}
          {success && (
            <Alert severity="success">
              {strings.PasswordUpdatedSuccessfully}
            </Alert>
          )}
          <FormControl fullWidth className="mt-3">
            <label>{strings.CurrentPassword}</label>
            <TextField
              name="current_password"
              color="primary"
              variant="outlined"
              size="small"
              type="password"
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <FormControl fullWidth className="mt-3">
            <label>{strings.NewPassword}</label>
            <TextField
              name="password"
              color="primary"
              variant="outlined"
              size="small"
              type="password"
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <FormControl fullWidth className="mt-3">
            <label>{strings.ConfirmPassword}</label>
            <TextField
              name="confirm_password"
              color="primary"
              variant="outlined"
              size="small"
              type="password"
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mx-4">
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          className="w-25"
        >
          {strings.Cancel}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          className="w-25"
        >
          {strings.Save}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default Profile;
