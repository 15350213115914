import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Hidden,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import Config from "../../../config.json";
import DataTable from "react-data-table-component";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import PrintIcon from "@material-ui/icons/Print";
import Logo from "../../../assets/logo/ADVA.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Alert from "@material-ui/lab/Alert";
import strings from "../../../lang/lang";
import FullPageLoader from "../../components/FullPageLoader";
const useStyles = makeStyles(() => ({
  barcode: {
    "& svg": {
      transform: "rotate(90deg) !important",
      marginTop: "25%",
    },
  },
  back: {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  returnItem: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#BF6159",
    },
  },
  Button: {
    backgroundColor: "#BF6159",
    display: "block",
    margin: "20px auto",
    minWidth: "100px",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  PointsBody: {
    "& .MuiTableCell-root.MuiTableCell-body": {
      border: "none",
    },
  },
  VoucherSection: {
    "& .MuiSvgIcon-root": {
      color: "#BF6159",
      margin: "2px 5px",
    },
  },
  VoucherRoot: {
    marginTop: "25px",
    "& .MuiPaper-root": {
      minHeight: "230px",
    },
    direction: "ltr",
  },
}));

function Reward() {
  const classes = useStyles();
  const [, setCopySuccess] = useState(false);
  const [PromoCode, setPromoCode] = useState(null);
  const [points, setpoints] = useState([]);
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [vouchers, setvouchers] = useState(null);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      setloading(true);
      axios
        .get(`${Config.SERVER_URL}customer/points/${user?.id}`)
        .then((res) => {
          setpoints(res.data.points);
          settotal(res.data.total);
          setconversionrate(res.data.conversion);
          setPromoCode(res.data.promo);
        })
        .catch((error) => {});
      axios
        .get(`${Config.SERVER_URL}voucher/get/${user?.id}`)
        .then((res) => {
          setvouchers(res.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, [user]);
  const columns = [
    {
      name: strings.Date,
      selector: "created_at",
      sortable: true,
      wrap: true,
    },
    {
      name: strings.Description,
      selector: "origin",
      sortable: true,
      wrap: true,
    },
    {
      name: strings.Points,
      selector: "points",
      sortable: true,
      wrap: true,
    },
  ];
  const [total, settotal] = useState(0);
  const [conversionrate, setconversionrate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <FullPageLoader loading={loading} />
      <RedeemPoints
        handleClose={handleClose}
        open={open}
        user={user}
        setloading={setloading}
      />
      <div className="advapoints">
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <p className="font-1">{strings.RewardPoints}</p>
            <p>
              <strong>
                {strings.Total} &nbsp;
                {strings.PTS}. {total} = {strings.SAR} &nbsp;
                {total
                  ? Number(total) *
                    (Number(conversionrate?.amount) /
                      Number(conversionrate?.points))
                  : 0}
              </strong>
              <span
                onClick={() => handleClickOpen()}
                style={{ cursor: "pointer" }}
                className="text-main mx-2"
              >
                {strings.RedeemPoints}
              </span>
            </p>
            <p>
              {strings.PointsCurrencyExchage}: {strings.PTS}.
              {conversionrate?.points} = {strings.SAR} {conversionrate?.amount}
            </p>
            <Box component={Paper} p={2}>
              <DataTable
                paginationComponentOptions={{
                  rowsPerPageText: strings.RowPerPage,
                  rangeSeparatorText: strings.Of,
                }}
                direction="ltr"
                noDataComponent={strings.ThereAreNoRecordsToDisplay}
                columns={columns}
                data={points}
                pagination
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={6} className={classes.VoucherSection}>
            {PromoCode?.promo_code && (
              <>
                <Hidden smDown>
                  <Box display="flex" alignItems="center">
                    <Box display="block" className="mx-2">
                      <Typography component="p" variant="caption">
                        Promo Code
                      </Typography>
                      <Typography component="p" variant="h5">
                        {PromoCode?.promo_code}
                      </Typography>
                    </Box>
                    <CopyToClipboard
                      text={PromoCode?.promo_code}
                      onCopy={() => {
                        setCopySuccess(true);
                        setTimeout(() => {
                          setCopySuccess(false);
                        }, 2000);
                      }}
                    >
                      <Button color="primary" variant="outlined">
                        Copy Code
                      </Button>
                    </CopyToClipboard>
                  </Box>
                </Hidden>
                <Hidden mdUp>
                  <Box display="flex" alignItems="center">
                    <Box display="block" className="mx-2">
                      <Typography component="p" variant="caption">
                        Promo Code
                      </Typography>
                      <Typography component="p" variant="h5">
                        {PromoCode?.promo_code}
                      </Typography>
                    </Box>

                    <CopyToClipboard
                      text={PromoCode?.promo_code}
                      onCopy={() => {
                        setCopySuccess(true);
                        setTimeout(() => {
                          setCopySuccess(false);
                        }, 2000);
                      }}
                    >
                      <Button color="primary" variant="outlined">
                        Copy Code
                      </Button>
                    </CopyToClipboard>
                  </Box>
                </Hidden>
              </>
            )}
            <p className="font-19">{strings.Voucher}</p>
            {vouchers &&
              vouchers?.map((voc, index) => {
                return <Voucher voc={voc} key={index} />;
              })}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
function Voucher({ voc }) {
  const classes = useStyles();
  const componentRef = useRef();
  return (
    <div className={classes.VoucherRoot}>
      <p className="d-flex-end-center">
        {/* <img src={Download} width="20" height="20" alt="print" /> */}
        <ReactToPrint
          trigger={() => <PrintIcon style={{ cursor: "pointer" }} />}
          content={() => componentRef.current}
        />
      </p>
      <Paper elevation={2} ref={componentRef}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={5}>
            <div className={classes.barcode}>
              <Barcode
                data-height="25"
                value={voc?.voucher_code}
                style={{ transform: "rotate(90deg) !important" }}
              />
            </div>
          </Grid>
          <Grid item md={8} xs={7}>
            <div className="d-flex">
              <p style={{ width: "75%" }} className="font-19 text-center">
                Voucher
              </p>
              <img src={Logo} alt="Logo" width="68" height="68" />
            </div>
            <div className="d-flex-between-center px-2">
              <div>
                <p>{strings.Name}</p>
                <p>
                  <strong>{voc?.voucher_name}</strong>
                </p>
              </div>
              <div style={{ minWidth: "100px" }}>
                <p>{strings.DiscountAmount}</p>
                <p>
                  {strings.SAR} <strong>{voc?.amount}</strong>
                </p>
              </div>
            </div>
            <div className="d-flex-between-center px-2 my-20">
              <div>
                <p>{strings.Brand}</p>
                <p>
                  <strong>{voc?.brand}</strong>
                </p>
              </div>
              <div style={{ minWidth: "100px" }}>
                <p>{strings.ValidTill}</p>
                <p>
                  <strong>{voc?.expiry_date}</strong>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

function RedeemPoints({ open, handleClose, user, setloading }) {
  const [vouchers, setvouchers] = useState(null);
  useEffect(() => {
    const init = async () => {
      if (open) {
        setloading(true);
        await axios
          .get(`${Config.SERVER_URL}voucher`)
          .then((res) => {
            setvouchers(res.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    };
    init();
  }, [open, setloading]);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState({});
  const redeemvocuher = async (id) => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}voucher/redeem/${id}/${user?.id}`)
      .then((res) => {
        setsuccess(true);
        setTimeout(() => {
          setsuccess(false);
          handleClose();
        }, 1500);
        console.log("created");
        setloading(false);
      })
      .catch((error) => {
        console.log("false");
        error.response &&
          error.response.status === 400 &&
          seterror(error.response.data);
        setloading(false);
      });
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Redeem Points"}</DialogTitle>
      <DialogContent>
        {success && <Alert>{strings.RedeemSuccessfully}</Alert>}
        {error &&
          Object.keys(error).map((key) => {
            return (
              <Alert severity="error" key={key}>
                {error[key]}
              </Alert>
            );
          })}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{strings.VoucherName}</TableCell>
                <TableCell>{strings.PartnerName}</TableCell>
                <TableCell align="right">{strings.PointsRequired}</TableCell>
                <TableCell align="right">{strings.DiscountAmount}</TableCell>
                <TableCell align="right">{strings.From}</TableCell>
                <TableCell align="right">{strings.To}</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vouchers &&
                vouchers?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.brand}
                    </TableCell>
                    <TableCell align="right">{row?.points_target}</TableCell>
                    <TableCell align="right">{row?.amount}</TableCell>
                    <TableCell align="right">{row?.start_date}</TableCell>
                    <TableCell align="right">{row?.expiry_ate}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => redeemvocuher(row?.id)}
                        variant="text"
                        color="primary"
                        className="text-main"
                        disableFocusRipple
                        disableRipple
                      >
                        Redeem
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default Reward;
