import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import question from "../../../assets/icons/question.svg";
import answer from "../../../assets/icons/answer.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import minusIcon from "../../../assets/icons/minus.svg";
import PlaceHolderImage from "../../../assets/images/placeholder.png";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShareIcon from "@material-ui/icons/Share";
import {
  Button,
  FormControl,
  Avatar,
  makeStyles,
  Grid,
  Box,
  Typography,
  Hidden,
  Divider,
  Container,
  Menu,
  List,
  ListItem,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import ImageIcon from "@material-ui/icons/Image";
import { useForm } from "react-hook-form";
import { TextField, Card, IconButton } from "@material-ui/core";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import { useInventoryItems } from "./../../../Context/CartContext";
import strings from "./../../../lang/lang";
import CartSnackbar from "./../../components/CartSnackbar";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, WhatsappIcon } from "react-share";
import FullPageLoader from "../../components/FullPageLoader";
import { Statistic } from "antd";
const useStyles = makeStyles(() => ({
  Button: {
    width: "345px",
    height: "45px",
    backgroundColor: "#BF6159",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  IncrementButton: {
    backgroundColor: "#BF6159",
    minWidth: "34px",
    minHeight: "34px",
    color: "white",
    textAlign: "center",
    margin: "0px 5px",
    borderRadius: 0,
    "& MuiButton-label": {
      fontSize: "25px !important",
    },
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  quantity: {
    textAlign: "center",
    margin: "auto 5px",
    width: "25px",
    fontSize: "18px",
  },
  rootDiv: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
    alignItems: "center",
  },
  link: {
    // fontSize: "25px",
    cursor: "pointer",
    padding: "10px",
    "&:hover": {
      color: "#BF6159",
    },
  },
  transparentButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& MuiButton-label": {
      fontWeight: "600",
    },
    "&:focus": {
      border: "none",
    },
    "& .MuiButton-startIcon .MuiSvgIcon-root": {
      color: "#F1BD8E",
      fontSize: "40px",
    },
  },
  transparentImageButton: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none !important",
    fontWeight: "bold",
    color: "#BF6159",
    paddingRight: "70px",
    paddingTop: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      border: "none",
    },
    "& .MuiButton-startIcon .MuiSvgIcon-root": {
      color: "#BF6159",
      fontSize: "40px",
    },
    "& .text-center": {
      fontWeight: "lighter",
    },
  },
  starIcon: {
    color: "#FFD500",
  },
  mayLike: {
    maxWidth: "100%",
    height: "auto",
    boxShadow: "0px 0px 10px #00000029",
  },
  media: {
    width: "100%",
    height: "auto",
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
}));

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);
function ProductDetails() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [productitem, setproduct] = useState(null);

  const slides = [];
  for (let i = 0; i < productitem?.productimages?.length; i += 1) {
    slides.push(
      <SwiperSlide key={`slide-${i}`} tag="li" style={{ listStyle: "none" }}>
        <img
          src={productitem?.productimages[i]?.picture_reference}
          alt={productitem?.product_name}
          className="auto"
        />
      </SwiperSlide>
    );
  }

  const thumbs = [];
  for (let i = 0; i < productitem?.productimages?.length; i += 1) {
    thumbs.push(
      <SwiperSlide
        key={`thumb-${i}`}
        tag="li"
        style={{ listStyle: "none", margin: "auto 6px" }}
      >
        <img
          src={productitem?.productimages[i]?.picture_reference}
          alt={`Thumbnail ${i}`}
          className="auto"
        ></img>
      </SwiperSlide>
    );
  }

  let { id } = useParams();
  const [Quantity, setQuantity] = useState(1);
  const [ReviewImages, setReviewImages] = useState([]);
  const [ReviewImagesURL, setReviewImagesURL] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [Question, setQuestion] = useState("");
  const classes = useStyles();
  const [QuestAns, setQuestAns] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [User] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const onSubmit = () => {};

  const [Review, setReview] = useState(false);
  const [allrewiews, setallrewiews] = useState([]);
  function handleReviewChange() {
    setReview(!Review);
  }

  function handleImageChanges(e) {
    let imagesTemp = [];
    let imagesTempURL = [];
    Array.from(e.target.files).forEach((image) => {
      imagesTempURL.push(URL.createObjectURL(image));
      imagesTemp.push(image);
    });
    setReviewImages(imagesTemp);
    setReviewImagesURL(imagesTempURL);
  }

  const [dummystate, setdummystate] = useState(false);
  const { items, updatecart } = useInventoryItems();
  const [currentcolor, setcurrentcolor] = useState(null);
  const [currentsize, setcurrentsize] = useState(null);
  const [wishlist, setwishlist] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const addToCart = (d) => {
    let products = items;
    let rProduct = products.filter((p) => {
      return p.id === d.id;
    });
    if (rProduct.length) {
      rProduct = [];
      products.map((p) => {
        if (p.id === d.id) {
          p.newQuantity += 1;
        }
        rProduct.push(p);
        return "";
      });
      products = rProduct;
    } else {
      var dummy = d;
      dummy["newQuantity"] = Quantity;
      dummy["newColor"] = currentcolor;
      dummy["newSize"] = currentsize;
      products.push(dummy);
    }
    updatecart(products);
    setdummystate(!dummystate);
    handleClick();
  };
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const init = async () => {
      setloading(true);
      await axios
        .get(`${Config.SERVER_URL}product/${id}`)
        .then((res) => {
          setproduct(res.data);
          setallrewiews(res.data?.reviews);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
      window.scrollTo(0, 0);
    };
    init();
  }, [id]);
  const SubmitQA = async () => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}qa/create`, {
        product_id: id,
        customer_id: User ? User?.id : 1,
        question: Question,
      })
      .then((response) => {
        setQuestAns(response.data);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };
  const [reviewerror, setreviewerror] = useState(false);
  const location = useHistory();
  const submitreview = async () => {
    setloading(true);
    if (!User) {
      location.push("/login");
      return;
    }
    const formData = new FormData();
    formData.append("product_id", id);
    formData.append("customer_id", User ? User?.id : 1);
    formData.append("stars", ReviewForm.star);
    formData.append("review_message", ReviewForm.message);
    for (let i = 0; i < ReviewImages.length; i++) {
      formData.append("images[" + i + "]", ReviewImages[i]);
    }
    await axios
      .post(`${Config.SERVER_URL}review/create`, formData)
      .then((response) => {
        setallrewiews(response.data);
        setReviewImagesURL([]);
        setReviewForm({ star: 0, message: "" });
        setReview(false);
        setloading(false);
      })
      .catch((err) => {
        err.response &&
          err.response.status === 400 &&
          setreviewerror(err.response.data);
        setloading(false);
      });
  };

  let deadline = new Date(productitem?.timer_to);
  deadline = deadline * (1000 * 60 * 60 * 24);
  const [ReviewForm, setReviewForm] = useState({ star: 0, message: "" });
  const HandleReviewForm = (e) => {
    ReviewForm[e.target.name] = e.target.value;
    setReviewForm(ReviewForm);
  };
  const [wishlistsuccess, setwishlistsuccess] = useState(false);
  const [wishlisterror, setwishlisterror] = useState(false);
  const addToWishlist = async () => {
    if (!User) {
      location.push("/login");
      return;
    }
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}wishlist/create`, {
        customer_id: User?.id,
        product_id: productitem?.id,
      })
      .then((res) => {
        setwishlistsuccess(true);
        setwishlist(!wishlist);
        setTimeout(() => {
          setwishlistsuccess(false);
        }, 5000);
        setloading(false);
      })
      .catch((error) => {
        error.response &&
          error.response.status === 400 &&
          setwishlisterror(error.response.data);
        setloading(false);
        setwishlist(!wishlist);
        setTimeout(() => {
          setwishlisterror({});
        }, 3000);
      });
  };
  const { lang } = useInventoryItems();
  return (
    <>
      <FullPageLoader loading={loading} />
      {/* Header */}
      <Header />
      <CartSnackbar open={open} />
      {/* breadCumb and Page Title */}
      <Container maxWidth="lg">
        <div className="d-flex brands-header mx-140">
          <ol className="breadcrumb my-auto">
            <li className="breadcrumb-item">
              <Link to="/">{strings.Home}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">{strings.Products}</Link>
            </li>
            <li className="breadcrumb-item active">{id}</li>
          </ol>
        </div>
        <p className="f-43 text-center">
          {lang.localeCompare("en") === 0
            ? productitem?.category?.category_name
            : productitem?.category?.category_arabic_name}
        </p>
        <Divider />
        {/* productitem Details */}
        <Grid container spacing={5} className="ProductDetails pt-5">
          <Grid item xs={12} md={6} className="SliderSection">
            <Swiper
              id="main-carousel"
              thumbs={{ swiper: thumbsSwiper }}
              tag="section"
              wrapperTag="ol"
              navigation
              pagination={{ clickable: true }}
              spaceBetween={1}
              slidesPerView={1}
              style={{ direction: "ltr !important" }}
            >
              {slides}
            </Swiper>
            <Hidden mdDown>
              <Swiper
                id="thumbs"
                spaceBetween={2}
                slidesPerView={4}
                onSwiper={setThumbsSwiper}
                className="px-3"
              >
                {thumbs}
              </Swiper>
            </Hidden>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="p-sm-4 p-md-1 mx-sm-2 mx-md-0 mt-2"
          >
            <section>
              <p className="f-20">
                {lang.localeCompare("en") === 0
                  ? productitem?.product_name
                  : productitem?.product_arabic_name}
              </p>
              <hr />
              <div className="d-flex my-2">
                <span className="font-weight-bold mx-1">
                  {strings.Price}: {strings.SAR}
                </span>
                {productitem?.discounted_amount > 0 ? (
                  <span className="discount mx-1">{productitem?.price}</span>
                ) : (
                  ""
                )}
                <span className="font-weight-bold mx-1">
                  {productitem?.discounted_amount > 0
                    ? Number(productitem?.price) -
                      Number(productitem?.discounted_amount)
                    : Number(productitem?.price)}
                  &nbsp;
                </span>
                <span className="mx-3">
                  {strings.PTS}:{productitem?.reward_points}
                </span>
              </div>
              <Box p={1}>
                <span id="ProductID text-md-justify">ID-{id}</span>

                <p className="my-4 text-md-justify">
                  {lang.localeCompare("en") === 0
                    ? productitem?.product_description
                    : productitem?.product_arabic_description}
                </p>
                <Grid container spacing={2} className="my-2">
                  <Grid xs={6} item>
                    <span>{strings.Availability}:</span>
                    <span>
                      <b>
                        {productitem?.quantity > 0
                          ? strings.InStock
                          : strings.OutOfStock}
                      </b>
                    </span>
                  </Grid>
                  <Grid xs={6} item>
                    <span>{strings.ItemRemaining}:</span>
                    <span className="font-weight-bold mx-1">
                      {productitem?.quantity}
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <span>
                      {strings.Model}: {productitem?.model}
                    </span>
                  </Grid>
                  {productitem?.colors &&
                    Object.keys(productitem?.colors).length > 0 && (
                      <Colors
                        colors={productitem?.colors}
                        setcurrentcolor={setcurrentcolor}
                      />
                    )}

                  {productitem?.sizes &&
                    Object.keys(productitem?.sizes).length > 0 && (
                      <Sizes
                        sizes={productitem?.sizes}
                        setcurrentsize={setcurrentsize}
                      />
                    )}
                </Grid>
              </Box>
              <div className={`${classes.rootDiv} justify-md-content-center`}>
                <span className="font-weight-bold text-md-none">
                  {strings.Quantity}
                </span>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  onClick={() => {
                    Quantity > 1
                      ? setQuantity(Quantity - 1)
                      : setQuantity(Quantity);
                  }}
                >
                  <img src={minusIcon} alt="" />
                </IconButton>
                <span className={classes.quantity}>{Quantity}</span>
                {/* <Button className={classes.IncrementButton}>+</Button> */}
                <IconButton
                  disableRipple
                  disableFocusRipple
                  onClick={() => {
                    setQuantity(Quantity + 1);
                  }}
                >
                  <img src={plusIcon} alt="" />
                </IconButton>
              </div>

              <div className={classes.rootDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.Button} w-md-100 f-26`}
                  onClick={() => addToCart(productitem)}
                  disabled={productitem?.quantity < 1 ? true : false}
                >
                  {strings.AddToCart}
                </Button>
              </div>

              {wishlistsuccess && (
                <Alert severity="success">{strings.WishlistSuccess}</Alert>
              )}
              {wishlisterror &&
                Object.keys(wishlisterror).map((key, index) => {
                  return (
                    <Alert key={index} severity="info">
                      {strings.ProductRemovedFromWishlist}
                    </Alert>
                  );
                })}
              <div className={classes.rootDiv}>
                <Wishlist
                  User={User}
                  id={productitem?.id}
                  addToWishlist={addToWishlist}
                  setwishlist={setwishlist}
                  wishlist={wishlist}
                />
                <ActionMenu />
              </div>
              {/*{productitem?.timer > 0 && (*/}
              {/*  <div>*/}
              {/*    <Statistic.Countdown value={deadline} />*/}
              {/*  </div>*/}
              {/*)}*/}
            </section>
          </Grid>
        </Grid>
        {/* Reviews */}
        <section className="mx-140 my-20">
          <p className="f-43 text-center mb-1 border-bottom-main">
            {strings.CustomerReviews}
          </p>
          <div className="d-flex justify-content-end mx-3">
            {Review ? (
              ""
            ) : (
              <Typography
                onClick={handleReviewChange}
                className={`text-main ${classes.link}`}
              >
                {strings.WriteReview}
              </Typography>
            )}
          </div>
          <div className="border-bottom-main mb-3">
            {allrewiews &&
              allrewiews?.map((re, index) => {
                return (
                  <>
                    <div className={`${classes.rootDiv} `} key={index}>
                      <Avatar
                        src={re?.customer?.profile_image}
                        alt="UserIcon"
                        width="50"
                      />
                      <p style={{ fontSize: "21px", margin: "auto 5px" }}>
                        {re.customer?.first_name}
                      </p>
                      <div className="d-flex">
                        <Stars stars={re?.stars} />
                      </div>
                    </div>
                    <p className="w-75">{re?.review_message}</p>
                    <Grid container spacing={3} className="my-1">
                      {re?.pictures &&
                        re?.pictures.map((image, index) => {
                          return (
                            <Grid item xs={12} md={3} key={index}>
                              <img
                                src={image?.picture}
                                alt=""
                                className="auto"
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                );
              })}
          </div>
          {Review ? (
            <>
              {reviewerror &&
                Object.keys(reviewerror).map((key) => {
                  return (
                    <Alert severity="error" key={key}>
                      {reviewerror[key]}
                    </Alert>
                  );
                })}
              <div className="d-flex justify-content-between">
                <h5>{strings.WriteReview}</h5>
                <span
                  onClick={() => {
                    setReview(false);
                  }}
                  className="cross"
                >
                  &times;
                </span>
              </div>
              <form action="" className="border-bottom-main my-2">
                <FormControl>
                  <label htmlFor="star" style={{ fontSize: "16px" }}>
                    {strings.Rating}
                  </label>
                  <fieldset className="rating">
                    <input
                      type="radio"
                      id="star5"
                      name="star"
                      value="5"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="full" htmlFor="star5"></label>
                    <input
                      type="radio"
                      id="star4half"
                      name="star"
                      value="4.5"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="half" htmlFor="star4half"></label>
                    <input
                      type="radio"
                      id="star4"
                      name="star"
                      value="4"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="full" htmlFor="star4"></label>
                    <input
                      type="radio"
                      id="star3half"
                      name="star"
                      value="3.5"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="half" htmlFor="star3half"></label>
                    <input type="radio" id="star3" name="star" value="3" />
                    <label className="full" htmlFor="star3"></label>
                    <input
                      type="radio"
                      id="star2half"
                      name="star"
                      value="2.5"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="half" htmlFor="star2half"></label>
                    <input
                      type="radio"
                      id="star2"
                      name="star"
                      value="2"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="full" htmlFor="star2"></label>
                    <input
                      type="radio"
                      id="star1half"
                      name="star"
                      value="1.5"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="half" htmlFor="star1half"></label>
                    <input
                      type="radio"
                      id="star1"
                      name="star"
                      value="1"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="full" htmlFor="star1"></label>
                    <input
                      type="radio"
                      id="starhalf"
                      name="star"
                      value="0.5"
                      onChange={(e) => HandleReviewForm(e)}
                    />
                    <label className="half" htmlFor="starhalf"></label>
                  </fieldset>
                </FormControl>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <FormControl>
                      <textarea
                        label={strings.Review}
                        name="message"
                        rows="10"
                        cols="100"
                        className="w-100"
                        onChange={(e) => HandleReviewForm(e)}
                        style={{ border: "1px solid rgb(199, 203, 206)" }}
                      ></textarea>
                    </FormControl>
                  </div>
                  <div className="col-12 col-md-6">
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<ImageIcon />}
                      className={classes.transparentImageButton}
                    >
                      Upload File
                      <input
                        type="file"
                        accept="images/*"
                        hidden
                        multiple
                        onChange={handleImageChanges}
                      />
                      <span
                        style={{ fontSize: "10px" }}
                        className="mx-1 text-muted"
                      >
                        ({strings.Optional})
                      </span>
                    </Button>

                    {ReviewImagesURL.length ? (
                      <Grid container spacing={2}>
                        {ReviewImagesURL.map((image, index) => {
                          return (
                            <Grid item xs={6} md key={index}>
                              <img
                                src={image}
                                className="mx-15 border auto border-dark"
                                alt="ReviewImage"
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={6} md>
                          <img
                            src={PlaceHolderImage}
                            className="border mx-15 auto"
                            alt="ReviewImage"
                          />
                        </Grid>
                        <Grid item xs={6} md>
                          <img
                            src={PlaceHolderImage}
                            className="border mx-15 auto"
                            alt="ReviewImage"
                          />
                        </Grid>
                        <Grid item xs={6} md>
                          <img
                            src={PlaceHolderImage}
                            className="border mx-15 auto"
                            alt="ReviewImage"
                          />
                        </Grid>
                        <Grid item xs={6} md>
                          <img
                            src={PlaceHolderImage}
                            className="border mx-15 auto"
                            alt="ReviewImage"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  <div className="col-12 d-flex justify-content-center my-57">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.Button}
                      size="small"
                      onClick={submitreview}
                    >
                      {strings.SubmitReview}
                    </Button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            ""
          )}
        </section>
        <section className="my-20 mx-140">
          <p className="text-center f-43 ">{strings.QuestionAboutProduct}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems="center" justify="center" spacing={3}>
              <Grid item xs={12} lg={8}>
                <FormControl fullWidth>
                  <TextField
                    id="Question"
                    type="text"
                    name="Question"
                    placeholder={strings.EnterYourQuestionHere}
                    variant="outlined"
                    onChange={(e) => {
                      setQuestion(e.target.value);
                    }}
                    className={classes.input}
                    inputRef={register({
                      required: "required",
                    })}
                    size="small"
                  />
                  <span className="error">{errors.Question?.message}</span>
                </FormControl>
              </Grid>
              <Grid item xs lg={3} xl={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={SubmitQA}
                  fullWidth
                >
                  {strings.AskQuestion}
                </Button>
              </Grid>
            </Grid>
          </form>

          <Box>
            {productitem?.qas &&
              productitem?.qas?.map((qa, index) => {
                return <QuestionAnswer QA={qa} key={index} />;
              })}
          </Box>
          <Box>
            {QuestAns &&
              QuestAns?.map((qa, index) => {
                return <QuestionAnswer QA={qa} key={index} />;
              })}
          </Box>
        </section>
        <section className="my-20 mx-140" style={{ marginTop: 45 }}>
          <p className="text-center f-43 ">{strings.YouMayAlsoLike}</p>
          <Grid container spacing={3}>
            {productitem &&
              productitem?.related_products?.map((prod, index) => {
                return (
                  <Grid item xs={3} key={index}>
                    <Card evaluation={1}>
                      <Link to={`/products/details/${prod?.id}`}>
                        <img
                          src={prod?.productimages[0]?.picture_reference}
                          alt=""
                          className={classes.mayLike}
                        />
                      </Link>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </section>
      </Container>
      <Footer />
    </>
  );
}

function Wishlist({ addToWishlist, User, id, wishlist, setwishlist }) {
  const classes = useStyles();
  const location = useHistory();
  const [isInWishlist, setisInWishlist] = useState(wishlist);
  useEffect(() => {
    const init = async () => {
      if (User) {
        await axios
          .get(`${Config.SERVER_URL}wishlist/${id}/${User?.id}`)
          .then((res) => {
            setisInWishlist(res.data);
            setwishlist(res.data);
          })
          .catch((error) => {});
      }
    };
    init();
  }, [id, User, location, setwishlist, wishlist]);
  return (
    <Button
      variant="text"
      className={classes.transparentButton}
      startIcon={isInWishlist ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      onClick={addToWishlist}
    >
      <span className="text-md-none f-16">
        {isInWishlist ? strings.RemoveFromWishlist : strings.AddToWishlist}
      </span>
    </Button>
  );
}
function QuestionAnswer({ QA }) {
  return (
    <>
      <Box className="border-bottom-main mb-3">
        <Grid container className="mt-5">
          <Grid item xs={2} sm={1} className="d-flex-center-center">
            <img alt="Q" src={question} />
          </Grid>
          <Grid item xs={7}>
            <p>{QA?.question}</p>
          </Grid>
        </Grid>
        {QA?.answer && (
          <Grid container className="mt-5 mb-2">
            <Grid item xs={2} sm={1} className="d-flex-center-center">
              <img alt="A" src={answer} />
            </Grid>
            <Grid item xs={7}>
              <p>{QA?.answer}</p>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

function Stars({ stars }) {
  let element = [];
  for (let i = 0; i < stars; i++) {
    element.push(<StarIcon style={{ color: "#ffd700" }} />);
  }
  return element;
}

function ActionMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const flexContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    background: "transparent",
  };
  return (
    <div>
      <Button
        variant="contained"
        className={classes.transparentButton}
        startIcon={<ShareIcon />}
        onClick={handleClick}
        disableFocusRipple
        disableRipple
      >
        <span className="text-md-none f-16"> {strings.Share}</span>
      </Button>
      <Menu
        id="ShareMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ minWidth: 250, background: "transparent", marginTop: 50 }}
        elevation={0}
      >
        <List style={flexContainer}>
          <ListItem className="p-1">
            <FacebookShareButton
              url={window.location.href}
              quote={strings.DoCheckOut}
            >
              <FacebookIcon size={35} round={true} className="p-0" />
            </FacebookShareButton>
          </ListItem>
          <ListItem className="p-1">
            <WhatsappShareButton
              url={window.location.href}
              title={strings.DoCheckOut}
            >
              <WhatsappIcon size={35} round={true} className="p-0" />
            </WhatsappShareButton>
          </ListItem>
        </List>
      </Menu>
    </div>
  );
}

function Sizes({ sizes, setcurrentsize }) {
  const [selected, setselected] = useState(sizes ? sizes[0] : null);
  const selectsize = (size) => {
    setselected(size);
    setcurrentsize(size);
  };
  return (
    <Grid item xs={12} container alignItems="center" className="mt-3">
      <span>{"Size: "}</span>
      <div className="d-flex">
        {sizes &&
          sizes?.map((c, index) => {
            return (
              <span
                className="mx-2"
                key={index}
                style={{
                  cursor: "pointer",
                  width: "max-content",
                  border: "1px solid #E2E2E2",
                  borderColor: selected?.id === c?.id ? "#bf6159" : "",
                  background: selected?.id === c?.id ? "#FFFFFF" : "",
                  color: selected?.id === c?.id ? "#bf6159" : "",
                  padding: "3px",
                }}
                onClick={() => selectsize(c)}
              >
                {c.size}
                <input
                  type="radio"
                  name="color"
                  value={c?.id}
                  className="d-none"
                />
              </span>
            );
          })}
      </div>
    </Grid>
  );
}
function Colors({ colors, setcurrentcolor }) {
  const [selected, setselected] = useState(colors ? colors[0] : null);
  const selectcolor = (color) => {
    setselected(color);
    setcurrentcolor(color);
  };
  const { lang } = useInventoryItems();
  return (
    <Grid item xs={12} container alignItems="center">
      <span>{strings.Colors}</span>
      <div className="d-flex">
        {colors &&
          colors?.map((c, index) => {
            return (
              <span
                key={index}
                className="color-box"
                style={{
                  cursor: "pointer",
                  width: "max-content",
                  border: "1px solid #E2E2E2",
                  borderColor: selected?.id === c?.id ? "#bf6159" : "",
                  color: selected?.id === c?.id ? "#bf6159" : "",
                  padding: "3px",
                }}
                onClick={() => selectcolor(c)}
              >
                {lang.localeCompare("en") === 0
                  ? c?.color_english
                  : c?.color_arabic}
                <input
                  type="radio"
                  name="color"
                  value={c?.id}
                  className="d-none"
                />
              </span>
            );
          })}
      </div>
    </Grid>
  );
}

export default ProductDetails;
