import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../../config.json";
import { Typography, Divider, Box } from "@material-ui/core";
import Header from "./../../../layout/Header";
import Footer from "./../../../layout/Footer";
import { Container } from "@material-ui/core";
import strings from "./../../../lang/lang";
import FullPageLoader from "./../../components/FullPageLoader";
function TermsAndCondition() {
  const [loading, setloading] = useState(false);
  const [terms, setterms] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    setloading(true);
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}terms/get`)
        .then((res) => {
          setterms(res.data?.body);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, []);

  function createMarkup() {
    return { __html: terms };
  }
  return (
    <>
      <FullPageLoader loading={loading} />
      <Header />

      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h1"
          className="text-center f-43"
          style={{ marginTop: 119 }}
        >
          {strings.TermsConditions}
        </Typography>
        <Divider className="my-2" />
        <Box dangerouslySetInnerHTML={createMarkup()}></Box>
      </Container>
      <Footer />
    </>
  );
}

export default TermsAndCondition;
