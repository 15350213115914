import React, { useRef } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../../config.json";
import SuccessModal from "./../../components/SuccessModal";
import ErrorModal from "./../../components/ErrorModal";
import Loader from "../../../assets/loader/loader.gif";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import strings from "./../../../lang/lang";
import Logo from "../../../assets/logo/ADVA.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Footer from "../../../layout/Footer";
import { useInventoryItems } from "./../../../Context/CartContext";
import FullPageLoader from "../../components/FullPageLoader";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ConfirmPayment() {
  const [data, setdata] = useState(null);
  //eslint-disable-next-line
  const [success, setsuccess] = useState(false);
  //eslint-disable-next-line
  const [error, seterror] = useState(false);
  const query = useQuery();
  const resourcePath = query.get("resourcePath");
  const successButton = useRef();
  const errorButton = useRef();

  const [products] = useState(
    sessionStorage.getItem("products")
      ? JSON.parse(sessionStorage.getItem("products"))
      : null
  );
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const { emptycart, updatepromo, updateisCOD, updatediscount } =
    useInventoryItems();
  const { returnValue } = useInventoryItems();
  const [isFirst, setisFirst] = useState(true);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const init = async () => {
      setloading(true);
      const points = query.get("points_discount");
      const points_discount = points.substring(0, points.indexOf("?"));
      await axios
        .post(`${Config.SERVER_URL}customer/payment/confirm`, {
          id: resourcePath,
          customer_id: user?.id ? user.id : 0,
          total: returnValue(),
          products: products,
          email: query.get("email"),
          phone: query.get("phone"),
          address_id: query.get("address_id"),
          promo_code: query.get("promo_code"),
          discount_code: query.get("discount_code"),
          points_discount: points_discount,
          brand: query.get("brand"),
        })
        .then((res) => {
          const successPattern = /^(000\.000\.|000\.100\.1|000\.[36])/;
          // const manuallPattern = /^(000\.400\.0[^3]|000\.400\.100)/;
          const match1 = successPattern.test(res.data.result.code);
          // const match2 = manuallPattern.test(res.data.result.code);
          if (match1) {
            sessionStorage.setItem("products", JSON.stringify([]));
            sessionStorage.setItem("vat", JSON.stringify(0));
            sessionStorage.setItem("total", JSON.stringify(0));
            emptycart([]);
            updatepromo({ promo_code: "" });
            updatediscount({ discount_code: "" });
            updateisCOD("CreditCard");
            user.points = res.data.points;
            sessionStorage.setItem("user", JSON.stringify(user));
            setsuccess(true);
            successButton.current.click();
          } else {
            seterror(true);
            errorButton.current.click();
          }
          setdata(res.data);
          setloading(false);
        })
        .catch((error) => {
          seterror(true);
          setloading(false);
        });
    };
    if (isFirst) {
      init();
      setisFirst(false);
    }
  }, [
    isFirst,
    resourcePath,
    query,
    user,
    emptycart,
    products,
    returnValue,
    updatediscount,
    updateisCOD,
    updatepromo,
  ]);
  const location = useHistory();
  //eslint-disable-next-line
  const redirect = () => {
    setTimeout(() => {
      location.push("/");
    }, 1000);
  };
  return (
    <>
      <FullPageLoader loading={loading} />
      <button
        className="d-none"
        ref={errorButton}
        data-toggle="modal"
        data-target="#ErrorModal"
      ></button>
      <button
        className="d-none"
        ref={successButton}
        data-toggle="modal"
        data-target="#SuccessModal"
      ></button>
      <SuccessModal />
      <ErrorModal message={data?.result?.description} />
      <div>
        <Grid container>
          <Grid item xs={12} container justify="center">
            <img src={Logo} alt="logo" srcset="" />
          </Grid>
          <Grid item xs={12} container justify="center">
            <nav className="breadcrumb mx-5">
              <li className="breadcrumb-item">
                <Link to="/">{strings.Cart}</Link>
              </li>
              <li className="breadcrumb-item ">
                <Link to="/checkout">{strings.Checkout}</Link>
              </li>
              <li className="breadcrumb-item active">{strings.Confirm}</li>
            </nav>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Link to="/checkout" className="text-dark text-decoration-none">
              <Button variant="text">
                <ArrowBackIosIcon /> {strings.BackToCart}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} container justify="center">
          <img
            src={Loader}
            alt="loader"
            srcset=""
            style={{ maxWidth: 200, maxHeight: 200 }}
          />
        </Grid>
      </div>
      <Footer />
    </>
  );
}

export default ConfirmPayment;
