import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  List,
  Typography,
  InputAdornment,
  ListItem,
  Paper,
  Avatar,
  Card,
  OutlinedInput,
} from "@material-ui/core";
import Header from "./../../../layout/Header";
import Footer from "./../../../layout/Footer";
import ScrollToBottom from "react-scroll-to-bottom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Config from "../../../config.json";
import { Redirect } from "react-router-dom";
import SendIcon from "@material-ui/icons/Send";
import { Alert } from "@material-ui/lab";
import useInterval from "../../../Hooks/Interval";
import strings from './../../../lang/lang';
const useStyles = makeStyles(() => ({
  chat: {
    height: 660,
    backgroundColor: "#F6F6F6",
    border: "1px solid #D1D1D1",
    marginTop: 35
  },
  message: {
    maxWidth: "300px",
    wordBreak: "break-all",
    border: "1px solid #7070704D",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    margin: "auto 5px",
    padding: 5,
  },
}));

function CustomerCare() {
  const [chat, setchat] = useState({});
  const [message, setmessage] = useState("");
  const [allcon, setallcon] = useState([]);
  const [error, seterror] = useState(false);
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );

  useEffect(() => {
    window.scrollTo(0, 0)
    const init = async () => {
      await axios
        .get(`${Config.SERVER_URL}chat/${user?.id}`)
        .then((res) => {
          setchat(res.data);
          setallcon(res.data.messages);
        })
        .catch((error)=>{
        });
    };
    if (user) {
      init();
    }
  }, [user]);
  useInterval(async () => {
    if (user) {
      await axios
        .get(`${Config.SERVER_URL}chat/${user?.id}`)
        .then((res) => {
          setchat(res.data);
          setallcon(res.data.messages);
        })
        .catch((error) => {
        });
    }
  }, 5000);
  const handleSubmit = async () => {
    const rmessage = message;
    setmessage("");
    await axios
      .post(`${Config.SERVER_URL}chat/send/message`, {
        user_id: user?.id,
        message: rmessage,
        chat_id: chat?.id,
      })
      .then((res) => {
        setchat(res.data);
        setallcon(res.data.messages);
      })
      .catch((err) => {
        seterror(true);
      });
  };
  const classes = useStyles();
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }
  if (!user) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <Header />
      <Container maxWidth="lg" className="mt-5">
        <Typography component="h4" variant="h4" align="center">
          {strings.CustomerCare}
        </Typography>
        <Divider />
        <Box>
          {error && <Alert severity="error">{strings.MessageFailed}</Alert>}
          <Box className={classes.chat} component={ScrollToBottom}>
            <List>
              {allcon &&
                allcon?.map((m) => {
                  return m?.user_id === 0 ? (
                    <>
                      <ListItem className="d-flex justify-content-start chat-left">
                        <Avatar>A</Avatar>
                        <Box
                          component={Card}
                          elevation={0}
                          className={classes.message}
                        >
                          {m?.message}
                        </Box>
                      </ListItem>
                    </>
                  ) : (
                      <>
                        <ListItem className="d-flex justify-content-end chat-right">
                          <Box
                            component={Card}
                            elevation={0}
                            className={classes.message}
                          >
                            {m?.message}
                          </Box>
                          <Avatar>C</Avatar>
                        </ListItem>
                      </>
                    );
                })}
            </List>
          </Box>
        </Box>
        <Box component={Paper} p={2}>
          <OutlinedInput
            fullWidth
            id="standard-adornment-password"
            onChange={(e) => setmessage(e.target.value)}
            placeholder={strings.TypeMessageHere}
            value={message}
            endAdornment={
              <InputAdornment position="end" onClick={handleSubmit}>
                <SendIcon className="text-main" your c />
              </InputAdornment>
            }
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default CustomerCare;
