import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ToggleExpand from "../../../assets/icons/ToggleExpand.svg";
import EmptyCart from "../../../assets/icons/EmptyCart.svg";
import minusIcon from "../../../assets/icons/minus.svg";
import plusIcon from "../../../assets/icons/plus.svg";
import { Button, Box, Typography, Grid, IconButton } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { useInventoryItems } from "./../../../Context/CartContext";
import strings from "./../../../lang/lang";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles(() => ({
  root: {
    zIndex: "99999999999",
    padding: "0px",
    paddingRight: "0px !important",
  },

  Button: {
    width: "276px",
    height: "60px",
    backgroundColor: "#BF6159",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  IncrementButton: {
    backgroundColor: "#BF6159",
    minWidth: "33px",
    minHeight: "33px",
    borderRadius: 0,
    color: "white",
    textAlign: "center",
    margin: "0px 5px",
    "& MuiButton-label": {
      fontSize: "18px",
    },
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  quantity: {
    textAlign: "center",
    margin: "auto 5px",
    width: "25px",
    fontSize: "18px",
  },
  totalRoot: {
    margin: "10px",
    padding: "10px",
    border: "1px solid #BF6159",
  },
  totalRow: {
    display: "flex",
    maxWidth: "400px",
    margin: "auto",
  },
}));
function CheckOut() {
  const { items, updatecart, total, returnValue } = useInventoryItems();
  const classes = useStyles();
  const [FullWidth, setFullWidth] = useState(false);
  const location = useHistory();
  function handleCheckout() {
    location.push("/checkout");
  }

  const [CartItems, setCartItems] = useState(items);
  const [dummy, setdummy] = useState(false);
  const incrementQuantity = (prod, index) => {
    CartItems[index]["newQuantity"] += 1;
    setCartItems(CartItems);
    updatecart(CartItems);
    setdummy(!dummy);
  };
  const decrementQuantity = (prod, index) => {
    if (prod.newQuantity <= 1) {
      return;
    }
    CartItems[index]["newQuantity"] -= 1;
    setCartItems(CartItems);
    updatecart(CartItems);
    setdummy(!dummy);
  };
  const removeItem = (prod) => {
    let rProduct = items.filter((v) => Number(v.id) !== Number(prod.id));
    setCartItems(rProduct);
    updatecart(rProduct);
    setdummy(!dummy);
  };
  const changecolor = (color, index) => {
    CartItems[index]["newColor"] = color;
    setCartItems(CartItems);
    updatecart(CartItems);
    setdummy(!dummy);
  };
  const changesize = (size, index) => {
    CartItems[index]["newSize"] = size;
    setCartItems(CartItems);
    updatecart(CartItems);
    setdummy(!dummy);
  };

  return (
    <div
      className={`modal fade right ${classes.root}`}
      id="CheckOutModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
        style={{ width: FullWidth ? "100%" : "475px" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="close"
                onClick={() => {
                  setFullWidth(!FullWidth);
                }}
              >
                <img
                  src={ToggleExpand}
                  alt="ToggleExpand"
                  width="25"
                  height="25"
                  className=" d-none d-md-block"
                />
              </button>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="text-main f-43  fw-100">
                  &times;
                </span>
              </button>
            </div>
            <h4 className="font-2 text-center f-c">{strings.YourCart}</h4>
            <hr />
            {items.length > 0 ? (
              <>
                <Box>
                  {FullWidth ? (
                    <div className="row">
                      {items &&
                        items.map((p, index) => {
                          return (
                            <div className="col-4" key={index}>
                              <CartItem
                                product={p}
                                index={index}
                                increment={incrementQuantity}
                                removeItem={removeItem}
                                decrement={decrementQuantity}
                                changesize={changesize}
                                changecolor={changecolor}
                                FullWidth={FullWidth}
                              />
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <Box p={2}>
                      {CartItems &&
                        CartItems.map((p, index) => {
                          return (
                            <CartItem
                              product={p}
                              index={index}
                              key={index}
                              increment={incrementQuantity}
                              decrement={decrementQuantity}
                              removeItem={removeItem}
                              changesize={changesize}
                              changecolor={changecolor}
                              FullWidth={FullWidth}
                            />
                          );
                        })}
                    </Box>
                  )}
                </Box>
                <div className={classes.totalRoot}>
                  {returnValue("isFree") && (
                    <div
                      className={`${classes.totalRow} justify-content-between`}
                    >
                      <p className="font-20">{strings.Shipping}</p>
                      <p className="font-20">
                        {strings.SAR}
                        <span className="text-danger font-20">
                          {returnValue("shipping")}
                        </span>
                      </p>
                    </div>
                  )}
                  <div
                    className={`${classes.totalRow} justify-content-between`}
                  >
                    <p className="font-20">{strings.VAT}</p>
                    <p className="font-20">
                      {strings.SAR}
                      <span className="text-danger font-20">
                        {returnValue("vat")}
                      </span>
                    </p>
                  </div>
                  <div
                    className={`${classes.totalRow} justify-content-between`}
                  >
                    <p className="font-25">{strings.Total}</p>
                    <p className="font-25">
                      {strings.SAR}

                      <span className="text-danger font-25">
                        {Number(total) > 0
                          ? Number(total) + Number(returnValue("shipping"))
                          : 0.0}
                      </span>
                    </p>
                  </div>
                  <div
                    className={`${classes.totalRow} justify-content-between`}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={`${classes.Button} mx-auto`}
                      onClick={() => handleCheckout()}
                      data-dismiss="modal"
                      disabled={items.length === 0 ? true : false}
                    >
                      {strings.Checkout}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Box
                  style={{ minHeight: 500 }}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <img
                    src={EmptyCart}
                    alt=""
                    style={{
                      marginTop: FullWidth ? "19%" : "40%",
                      maxWidth: 147,
                      maxHeight: 141,
                    }}
                  />
                  <Typography component="p" className="text-main f-25">
                    {strings.YourCartisEmpty}
                  </Typography>
                  <Typography
                    component="p"
                    className="text-muted"
                    style={{ fontSize: 15 }}
                  >
                    {strings.LooksLikeYouHaventChooseAnything}
                  </Typography>
                </Box>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
function CartItem({
  product,
  index,
  increment,
  decrement,
  removeItem,
  changecolor,
  changesize,
  FullWidth,
}) {
  const classes = useStyles();
  const [Quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(product?.newQuantity);
  }, [product]);
  const { lang } = useInventoryItems();
  return (
    <>
      <Grid container className="my-2 p-2 border-main">
        <Grid sm={12} container justify="flex-end" item className="px-1">
          <Button
            style={{ padding: 0, margin: 0, minWidth: 0 }}
            startIcon={<DeleteIcon style={{ color: "red" }} />}
            onClick={() => removeItem(product)}
          ></Button>
        </Grid>
        <Grid sm={12} item className="px-1" container>
          <img
            src={product?.productimages[0]?.picture_reference}
            alt={product?.product_image}
            className="cart-image"
          />
          <Box className="mobile-size">
            <div className="d-flex justify-content-between align-items-center">
              <Link
                to={`/products/details/${product?.id}`}
                className="text-dark f-15 f-c"
              >
                <h6 className="font-weight-bold text-right">
                  {lang.localeCompare("en") === 0
                    ? product?.product_name
                    : product?.product_arabic_name}
                </h6>
              </Link>
            </div>
            <p style={{ marginTop: 12 }} className="mb-0 f-14">
              {strings.Price}: {strings.SAR}.
              {product?.price - product?.discounted_amount}
            </p>
            <div>
              <span className="f-14">{strings.Quantity}</span>
              <IconButton
                disableRipple
                disableFocusRipple
                onClick={() => {
                  decrement(product, index);
                  if (Quantity > 1) setQuantity(Quantity - 1);
                }}
              >
                <img src={minusIcon} alt="" />
              </IconButton>
              <span className={classes.quantity}>{product?.newQuantity}</span>
              <IconButton
                disableRipple
                disableFocusRipple
                onClick={() => {
                  increment(product, index);
                  setQuantity(Quantity + 1);
                }}
              >
                <img src={plusIcon} alt="" />
              </IconButton>
            </div>
          </Box>
        </Grid>
      </Grid>
      {FullWidth ? "" : <hr />}
    </>
  );
}

// function Colors({ colors, setcurrentcolor,index }) {
//   const [selected, setselected] = useState(colors ? colors[0] : null);
//   const selectcolor = (color) => {
//     setselected(color)
//     setcurrentcolor(color,index)
//   }
//   return (
//     <Grid item xs={6} container alignItems="center">
//       <span>{"Colors"}</span>
//       <div className="d-flex">
//         {colors &&
//           colors?.map((c) => {
//             return (
//               <span
//                 className="color-box"
//                 style={{
//                   backgroundColor: c?.color,
//                   cursor: "pointer",
//                   border: selected?.id === c?.id ? '2px solid #bf6159' : "",
//                 }}
//                 onClick={() => selectcolor(c)}
//               >
//                 <input
//                   type="radio"
//                   name="color"
//                   value={c?.id}
//                   className="d-none"
//                 />
//               </span>
//             );
//           })}
//       </div>
//     </Grid>
//   )
// }
export default CheckOut;
