import React, { useEffect, useState } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Container } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { List } from "@material-ui/core";
import {
  ListItem,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import axios from "axios";
import Config from "../../../config.json";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import DeliveryIcon from "../../../assets/icons/faq/Delivery-Black.png";
import DeliveryClickedIcon from "../../../assets/icons/faq/Delivery-white.png";
import ProductIcon from "../../../assets/icons/faq/Product-black.png";
import ProductClickedIcon from "../../../assets/icons/faq/Product-white.png";
import PaymentIcon from "../../../assets/icons/faq/Payment-Black.png";
import PaymentClickedIcon from "../../../assets/icons/faq/Payment-White.png";
import ReturnIcon from "../../../assets/icons/faq/Return-black.png";
import ReturnClickedIcon from "../../../assets/icons/faq/Return-white.png";
import ShoppingIcon from "../../../assets/icons/faq/Shopping-black.png";
import ShoppingClickedIcon from "../../../assets/icons/faq/Shopping-white.png";
import strings from './../../../lang/lang';
import FullPageLoader from "../../components/FullPageLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    borderLeft: "3px solid #707070",
    paddingLeft: "4pt",
  },
  faqCard: {
    minWidth: 190,
    width: "max-content",
    minHeight: 100,
    boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.5)",
    margin: "25px 25px",
    display: "block",
    "&.active": {
      backgroundColor: '#bf6159',
      color: 'white'
    }
  },
}));
function FAQ() {
  const [loading, setloading] = useState(false)
  const classes = useStyles();
  const [filter, setfilter] = useState("");
  const [faqs, setfaqs] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0)
    const init = async () => {
      setloading(true)
      await axios
      .get(`${Config.SERVER_URL}faqs?filter=${filter}`)
      .then((res) => {
        setfaqs(res.data);
        setloading(false)
      })
      .catch((error) => {
          setloading(false)
        });
    };
    init();
  }, [filter]);
  return (
    <>
    <FullPageLoader loading={loading}/>
      <Header />
      <Container maxWidth="lg">
        <Box
          style={{ width: "100%", overflowX: "auto", display: "flex" }}
          className="mb-3"
        >
          <List className="d-flex">
            <ListItem className={`${classes.faqCard} ${filter === '' ? 'active' : ""}`} onClick={() => setfilter("")}>
              <Box p={1} display="block">
                <Typography variant="h5" className="text-center">
                  {strings.All}
                </Typography>
              </Box>
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={filter === '' ? PaymentClickedIcon : PaymentIcon} alt="" />
              </Box>
            </ListItem>
            <ListItem
              className={`${classes.faqCard} ${filter === 'order' ? 'active' : ""}`}
              onClick={() => setfilter("order")}
            >
              <Box p={1} display="block">
                <Typography variant="h5" className="text-center">
                  {strings.OrderIssue}
                </Typography>
              </Box>
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={filter === 'order' ? ShoppingClickedIcon : ShoppingIcon} alt="" />
              </Box>
            </ListItem>
            <ListItem
              className={`${classes.faqCard} ${filter === 'product' ? 'active' : ""}`}
              onClick={() => setfilter("product")}
            >
              <Box p={1} display="block">
                <Typography variant="h5" className="text-center">
                  {strings.ProductAndStock}
                </Typography>
              </Box>
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={filter === 'product' ? ProductClickedIcon : ProductIcon} alt="" />
              </Box>
            </ListItem>
            <ListItem
              className={`${classes.faqCard} ${filter === 'delivery' ? 'active' : ""}`}
              onClick={() => setfilter("delivery")}
            >
              <Box p={1} display="block">
                <Typography variant="h5" className="text-center">
                  {strings.Delivery}
                </Typography>
              </Box>
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={filter === 'delivery' ? DeliveryClickedIcon : DeliveryIcon} alt="" />
              </Box>
            </ListItem>
            <ListItem
              className={`${classes.faqCard} ${filter === 'return' ? 'active' : ""}`}
              onClick={() => setfilter("return")}
            >
              <Box p={1} display="block">
                <Typography variant="h5" className="text-center">
                  {strings.ReturnAndRefund}
                </Typography>
              </Box>
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={filter === 'return' ? ReturnClickedIcon : ReturnIcon} alt="" />
              </Box>
            </ListItem>
            <ListItem
              className={`${classes.faqCard} ${filter === 'payment' ? 'active' : ""}`}
              onClick={() => setfilter("payment")}
            >
              <Box p={1} display="block">
                <Typography variant="h5" className="text-center">
                  {strings.Payment}
                </Typography>
              </Box>
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={filter === 'payment' ? PaymentClickedIcon : PaymentIcon} alt="" />
              </Box>
            </ListItem>
            <ListItem
              className={`${classes.faqCard} ${filter === 'account' ? 'active' : ""}`}
              onClick={() => setfilter("account")}
            >
              <Box p={1} display="block">
                <Typography variant="h5" className="text-center">
                  {strings.Accounts}
                </Typography>
              </Box>
              <Box
                p={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={filter === 'account' ? PaymentClickedIcon : PaymentIcon} alt="" />
              </Box>
            </ListItem>
          </List>
        </Box>

        {faqs &&
          Object.keys(faqs).length > 0 &&
          faqs.map((faq,index) => {
            return (
              <Box m={1} p={1} key={index}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {faq?.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq?.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
      </Container>
      <Footer />
    </>
  );
}

export default FAQ;
