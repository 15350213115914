import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  List,
  ListItem,
  Typography,
  Avatar,
  Hidden,
  Button,
} from "@material-ui/core";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Container } from "@material-ui/core";
import { Box } from "@material-ui/core";
import AdvapointsIcon from "../../../assets/icons/accounts/Advapoints.svg";
import AdvapointsClickedIcon from "../../../assets/icons/accounts/Advapoints-Clicked.svg";
import ClaimsIcon from "../../../assets/icons/accounts/Claims.svg";
import ClaimsClickedIcon from "../../../assets/icons/accounts/Claims-Clicked.svg";
import LocationIcon from "../../../assets/icons/accounts/Location.svg";
import LocationClickedIcon from "../../../assets/icons/accounts/Location-Clicked.svg";
import OrdersIcon from "../../../assets/icons/accounts/Orders.svg";
import OrdersClickedIcon from "../../../assets/icons/accounts/Orders-Clicked.svg";
// import PaymentsIcon from "../../../assets/icons/accounts/Payments.svg";
// import PaymentsClickedIcon from "../../../assets/icons/accounts/Payments-Clicked.svg";
// import PreferencesIcon from "../../../assets/icons/accounts/Preferences.svg";
// import PreferencesClickedIcon from "../../../assets/icons/accounts/Preferences-Clicked.svg";
import ProfileIcon from "../../../assets/icons/accounts/Profile.svg";
import ProfileClickedIcon from "../../../assets/icons/accounts/Profile-Clicked.svg";
import ReturnsIcon from "../../../assets/icons/accounts/Returns.svg";
import ReturnsClickedIcon from "../../../assets/icons/accounts/Returns-Clicked.svg";
import WishlistIcon from "../../../assets/icons/accounts/Wishlist.svg";
import WishlistClickedIcon from "../../../assets/icons/accounts/Wishlist-Clicked.svg";
import Profile from "./Profile";
import Address from "./Address";
import WishList from "./WishList";
import Order from "./Order/Index";
import Return from "./Return";
import Claims from "./Claims";
import Payments from "./Payments";
import Preferences from "./Preferences";
import Reward from "./Reward";
import strings from "../../../lang/lang";
import { useInventoryItems } from "./../../../Context/CartContext";
import PowerSettingsNewSharpIcon from "@material-ui/icons/PowerSettingsNewSharp";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(() => ({
  root: {
    marginTop: 71,
  },
  active: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 6px #0000001C",
  },
}));
function MyAccount() {
  const [step, setstep] = useState("profile");
  const classes = useStyles();
  const { lang } = useInventoryItems();
  const [customer] = useState(JSON.parse(sessionStorage.getItem("user")));
  const location = useHistory();
  strings.setLanguage(lang);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Hidden mdUp>
        <Box p={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography component="p" variant="body1">
                {strings.Hello}, {customer?.name}
              </Typography>
              <Typography component="p" variant="overline">
                {customer?.email}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <Button
                color="default"
                variant="text"
                onClick={() => {
                  sessionStorage.clear();
                  location.replace("/login");
                }}
              >
                <PowerSettingsNewSharpIcon className="text-muted" />
                &nbsp; Sign Out
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          className="px-3"
          style={{ width: "100%", overflowX: "auto", display: "flex" }}
        >
          <List className="d-flex">
            <ListItem onClick={() => setstep("profile")}>
              <Avatar
                variant="circular"
                src={step === "profile" ? ProfileClickedIcon : ProfileIcon}
              />
              <Typography>{strings.Profile}</Typography>
            </ListItem>
            <ListItem onClick={() => setstep("addresses")}>
              <Avatar
                variant="circular"
                src={step === "addresses" ? LocationIcon : LocationIcon}
              />
              <Typography>{strings.Addresses}</Typography>
            </ListItem>
            <ListItem onClick={() => setstep("wishlist")}>
              <Avatar
                variant="circular"
                src={step === "wishlist" ? WishlistClickedIcon : WishlistIcon}
              />
              <Typography>{strings.Wishlist}</Typography>
            </ListItem>
            <ListItem onClick={() => setstep("advapoints")}>
              <Avatar
                variant="circular"
                src={
                  step === "advapoints" ? AdvapointsClickedIcon : AdvapointsIcon
                }
              />
              <Typography>{strings.AdvaPoints}</Typography>
            </ListItem>
            <ListItem onClick={() => setstep("orders")}>
              <Avatar
                variant="circular"
                src={step === "orders" ? OrdersClickedIcon : OrdersIcon}
              />
              <Typography>{strings.Orders}</Typography>
            </ListItem>
            <ListItem onClick={() => setstep("returns")}>
              <Avatar
                variant="circular"
                src={step === "returns" ? ReturnsClickedIcon : ReturnsIcon}
              />
              <Typography>{strings.Returns}</Typography>
            </ListItem>
            {/* <ListItem onClick={() => setstep("claims")}>
              <Avatar
                variant="circular"
                src={step === "claims" ? ClaimsClickedIcon : ClaimsIcon}
              />
              <Typography>{strings.Claims}</Typography>
            </ListItem>
            <ListItem onClick={() => setstep("payment")}>
              <Avatar
                variant="circular"
                src={step === "payment" ? PaymentsClickedIcon : PaymentsIcon}
              />
              <Typography>{strings.Payment}</Typography>
            </ListItem>
            <ListItem onClick={() => setstep("preferences")}>
              <Avatar
                variant="circular"
                src={
                  step === "preferences"
                    ? PreferencesClickedIcon
                    : PreferencesIcon
                }
              />
              <Typography>{strings.Preferences}</Typography>
            </ListItem> */}
          </List>
        </Box>
      </Hidden>
      <Container maxWidth="lg" className={`${classes.root} p-md-2 `}>
        <Grid container spacing={4}>
          <Hidden smDown>
            <Grid item md={2}>
              <Hidden smDown>
                <Box p={1}>
                  <Typography
                    component="p"
                    variant="body1"
                    className="f-16 font-weight-bold"
                  >
                    {strings.Hello}, {customer?.name}
                  </Typography>
                  <Typography component="p" variant="overline">
                    {customer?.email}
                  </Typography>
                  <Button
                    color="default"
                    variant="text"
                    onClick={() => {
                      sessionStorage.clear();
                      location.replace("/login");
                    }}
                    className="font-weight-bold"
                  >
                    <PowerSettingsNewSharpIcon className="text-muted" />
                    &nbsp; Sign Out
                  </Button>
                </Box>
              </Hidden>

              <Box>
                <List>
                  <ListItem
                    onClick={() => setstep("profile")}
                    className={step === "profile" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={
                        step === "profile" ? ProfileClickedIcon : ProfileIcon
                      }
                    />
                    <Typography>{strings.Profile}</Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => setstep("addresses")}
                    className={step === "addresses" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={
                        step === "addresses"
                          ? LocationClickedIcon
                          : LocationIcon
                      }
                    />
                    <Typography>{strings.Addresses}</Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => setstep("wishlist")}
                    className={step === "wishlist" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={
                        step === "wishlist" ? WishlistClickedIcon : WishlistIcon
                      }
                    />
                    <Typography>{strings.Wishlist}</Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => setstep("advapoints")}
                    className={step === "advapoints" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={
                        step === "advapoints"
                          ? AdvapointsClickedIcon
                          : AdvapointsIcon
                      }
                    />
                    <Typography>{strings.AdvaPoints}</Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => setstep("orders")}
                    className={step === "orders" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={step === "orders" ? OrdersClickedIcon : OrdersIcon}
                    />
                    <Typography>{strings.Orders}</Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => setstep("returns")}
                    className={step === "returns" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={
                        step === "returns" ? ReturnsClickedIcon : ReturnsIcon
                      }
                    />
                    <Typography>{strings.Returns}</Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => setstep("claims")}
                    className={step === "claims" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={step === "claims" ? ClaimsClickedIcon : ClaimsIcon}
                    />
                    <Typography>{strings.Claims}</Typography>
                  </ListItem>
                  {/* <ListItem
                    onClick={() => setstep("payment")}
                    className={step === "payment" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={
                        step === "payment" ? PaymentsClickedIcon : PaymentsIcon
                      }
                    />
                    <Typography>{strings.Payment}</Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => setstep("preferences")}
                    className={step === "preferences" ? classes.active : ""}
                  >
                    <Avatar
                      variant="circular"
                      src={
                        step === "preferences"
                          ? PreferencesClickedIcon
                          : PreferencesIcon
                      }
                    />
                    <Typography>{strings.Preferences}</Typography>
                  </ListItem> */}
                </List>
              </Box>
            </Grid>
          </Hidden>

          <Grid item md={10} xs={12}>
            {getComponent(step)}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
function getComponent(name) {
  switch (name) {
    case "profile":
      return <Profile />;

    case "wishlist":
      return <WishList />;

    case "addresses":
      return <Address />;
    case "advapoints":
      return <Reward />;

    case "orders":
      return <Order />;

    case "returns":
      return <Return />;

    case "claims":
      return <Claims />;

    case "payment":
      return <Payments />;

    case "preferences":
      return <Preferences />;

    default:
      return;
  }
}
export default MyAccount;
