import strings from "./../../../../lang/lang";
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Paper,
  Divider,
  Hidden,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import { Redirect } from "react-router-dom";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Config from "../../../../config.json";
import OrderHistory from "./Details";
import FullPageLoader from "../../../components/FullPageLoader";
const useStyles = makeStyles(() => ({
  back: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
  input: {
    backgroundColor: "white",
    color: "black",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
}));

export default function Order() {
  const [step, setstep] = useState(0);
  const [orderId, setorderId] = useState(null);
  switch (step) {
    case 0:
      return <Index setstep={setstep} setorderId={setorderId} />;
    case 1:
      return <OrderHistory id={orderId} />;
    default:
      return <Index setstep={setstep} setorderId={setorderId} />;
  }
}
function Index({ setstep, setorderId }) {
  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      cell: (row) => {
        return (
          <span
            onClick={() => {
              setorderId(row?.id);
              setstep(1);
            }}
            className="text-main"
            style={{ cursor: "pointer" }}
          >
            ID-{row?.id}
          </span>
        );
      },
    },
    {
      name: strings.Status,
      selector: "status",
      sortable: true,
      hide: "md",
    },
    {
      name: strings.Address,
      selector: "address",
      sortable: true,
      hide: "md",
      wrap: true,
    },
    {
      name: strings.TotalItems,
      selector: "cart_count",
      sortable: true,
      hide: "md",
    },
    {
      name: strings.Date,
      selector: "created_at",
      sortable: true,
      wrap: true,
    },
    {
      name: strings.TotalAmount,
      selector: "total",
      sortable: true,
    },
  ];
  const [isLoading, setisLoading] = useState(false);
  const classes = useStyles();
  const [Search, setSearch] = useState("");
  const [orders, setorders] = useState([]);
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}order/${user?.id}?search=${Search}`)
        .then((response) => {
          setisLoading(false);
          setorders(response.data);
        })
        .catch((error) => {
          setisLoading(false);
          if (error.response.status === 400) setorders(error.response.data);
        });
    };
    init();
  }, [Search, user]);
  if (!user) {
    return <Redirect to="login" />;
  }
  return (
    <>
      <FullPageLoader loading={isLoading} />
      <Box p={3}>
        <Typography component="h4" variant="h6">
          {strings.Orders}
        </Typography>
        <Divider />
        <Box component={Paper} eveluation={2} p={3}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="my-2"
          >
            <Hidden mdDown>
              <Grid item sm={12} md={4}>
                <Typography component="h6" variant="h5">
                  {strings.Orders}
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={4} className="d-flex-end-center">
              <TextField
                name="Search"
                id="Search"
                variant="outlined"
                size="small"
                color="primary"
                fullWidth
                placeholder={strings.Search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                className={`${classes.input} w-md-100`}
              />
            </Grid>
          </Grid>

          <DataTable
            direction="ltr"
            paginationComponentOptions={{
              rowsPerPageText: strings.RowPerPage,
              rangeSeparatorText: strings.Of,
            }}
            noDataComponent={strings.ThereAreNoRecordsToDisplay}
            columns={columns}
            data={orders}
            progressPending={isLoading}
            responsive={true}
            pagination
          />
        </Box>
      </Box>
    </>
  );
}
