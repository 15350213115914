import React, { useEffect, useState } from "react";
import { Divider, Typography, Grid, Box, IconButton } from "@material-ui/core";
import axios from "axios";
import Config from "../../../config.json";
import ClearIcon from "@material-ui/icons/Clear";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import strings from "./../../../lang/lang";
import { useInventoryItems } from "./../../../Context/CartContext";
import FullPageLoader from "../../components/FullPageLoader";
function WishList() {
  const [wishlist, setwishlist] = useState(null);
  const [error, seterror] = useState({});
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      setisLoading(true);
      await axios
        .get(`${Config.SERVER_URL}wishlist/${user?.id}`)
        .then((response) => {
          setwishlist(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 400) seterror(error.response.data);
          setisLoading(false);
        });
    };
    init();
  }, [user]);
  return (
    <>
      <FullPageLoader loading={isLoading} />
      <Typography component="h5" align="center" variant="h5">
        {strings.Wishlist}
      </Typography>
      <Divider />
      {isLoading && (
        <Typography align="center">
          Please Wait while we fetch Your Wish List
        </Typography>
      )}
      {!!error &&
        Object.keys(error).map((key) => {
          return (
            <Alert severity="error" key={key}>
              {error[key]}
            </Alert>
          );
        })}
      <Grid container spacing={3} className="p-md-0">
        {!!wishlist &&
          wishlist?.map((wl, index) => {
            return (
              <Grid item xs={12} key={index} md={6} className="px-md-0">
                <OrderItem
                  product={wl}
                  setwishlist={setwishlist}
                  seterror={seterror}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}
function OrderItem({ product, setwishlist, seterror }) {
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const deletewishitem = async () => {
    await axios
      .post(`${Config.SERVER_URL}wishlist/delete`, {
        wishlist_id: product?.id,
        customer_id: user?.id,
      })
      .then((res) => {
        setwishlist(res.data);
      })
      .catch((error) => {
        if (error.response.status === 400) seterror(error.response.data);
      });
  };
  const { lang } = useInventoryItems();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <img
            src={product?.products?.productimages[0]?.picture_reference}
            alt=""
            className="auto"
          />
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              disableFocusRipple
              disableRipple
              className="p-1"
              onClick={deletewishitem}
            >
              <ClearIcon color="error" />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Link
                to={`/products/details/${product?.products?.id}`}
                className="text-dark text-decoration-none"
              >
                <Typography>
                  {lang.localeCompare("en") === 0
                    ? product?.products?.product_name
                    : product?.products?.product_arabic_name}
                </Typography>
              </Link>

              <Typography>
                {strings.Model}: {product?.products?.model}
              </Typography>
            </Box>
            <Box>
              <Typography>
                {strings.SAR} {product?.products?.price}
              </Typography>
              {/* <Typography>{strings.Quantity}: {product?.products?.price}</Typography> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default WishList;
