import React, { createContext, useState, useContext } from "react";

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export default function UserProvider(props) {
  const [customercheckout, setcustomer] = useState({});
  const [open, setOpen] = React.useState(false);
  const setpersonalinfo = (e) => {
    setcustomer(e);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <UserContext.Provider value={{ setpersonalinfo, customercheckout, open, handleClose }}>
      {props?.children}
    </UserContext.Provider>
  );
}
