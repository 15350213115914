import React, {useEffect, useState} from "react";
import Header from "../layout/Header";
import TopSellers from "./components/TopSellers";
import ProductItem from "./components/ProductItem";
import OffersItem from "./components/OffersItem";
import Footer from "../layout/Footer";
import {Container, Grid, Box, List, ListItem} from "@material-ui/core";
import Slider from "react-slick";
import axios from "axios";
import config from "../config.json";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {useInventoryItems} from "./../Context/CartContext";
import strings from "../lang/lang";
import CartSnackbar from "./components/CartSnackbar";
import BundleOffersItem from "./components/BundleOffersItem";
import FullPageLoader from "./components/FullPageLoader";
function Home() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2400,
    cssEase: "linear",
  };
  const [TopSellings, setTopSellings] = useState(null);
  const [FeaturedProd, setFeaturedProd] = useState(null);
  const [AdvaAds, setAdvaAds] = useState(null);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const init = async () => {
      setloading(true);
      //Get Ads manager
      axios
        .get(`${config.SERVER_URL}all/header`)
        .then((response) => {
          if (Object.keys(response.data).length > 0) setAdvaAds(response.data);
        })
        .catch((error) => {});
      // Get Top sellings products
      axios
        .get(`${config.SERVER_URL}topsellings`)
        .then((response) => {
          if (response.data !== []) setTopSellings(response.data);
        })
        .catch((error) => {});
      // Get Featured Products
      axios
        .get(`${config.SERVER_URL}product/featured`)
        .then((response) => {
          if (Object.keys(response.data).length > 0)
            setFeaturedProd(response.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, []);

  return (
    <>
      <FullPageLoader loading={loading} />
      <Header />
      <Slider {...settings} className='arrow-none hero-slider'>
        {AdvaAds &&
          AdvaAds?.map((ad, index) => {
            return ad?.type === "image" ? (
              <div>
                <div
                  key={index}
                  className='slick-carousel-image'
                  style={{
                    backgroundImage: `url(${ad?.media})`,
                  }}
                ></div>
              </div>
            ) : (
              <div key={index}>
                <video
                  controls={false}
                  src={ad?.media}
                  muted
                  autoPlay
                  loop
                  style={{width: "100%", maxHeight: 800}}
                ></video>
              </div>
            );
          })}
      </Slider>
      <Container maxWidth='lg'>
        <HomeCategory />
        <Offers />
      </Container>
      {TopSellings && <TopSellers data={TopSellings} />}
      <Container maxWidth='lg'>
        {FeaturedProd && <FeaturedProducts data={FeaturedProd} />}
      </Container>
      <Footer />
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  category: {
    padding: "10px",
    wordBreak: "keep-all",
    border: "1px solid #70707057",
    textAlign: "center",
    color: "#707070e0",
    boxShadow: "0px 3px 6px #BF615930",
    "&:hover": {
      color: "#BF6159",
      borderColor: "#BF6159",
    },
    minWidth: 100,
    width: "max-content",
  },
  link: {
    textDecoration: "none",
    color: "black",
    margin: "auto 10px",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
function HomeCategory() {
  const {lang} = useInventoryItems();
  const classes = useStyles();
  const [categories, setcategories] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      await axios
        .get(`${config.SERVER_URL}category`)
        .then((response) => {
          if (response.data.length) setcategories(response.data);
        })
        .catch((error) => {});
    };
    init();
  }, []);

  if (categories?.length < 1) {
    return <></>;
  }
  return (
    <>
      <Box
        style={{
          width: "95%",
          overflowX: "auto",
          display: "flex",
          boxSizing: "content-box",
        }}
        className='mb-3'
        component={Container}
        maxWidth='lg'
      >
        <List className='d-flex'>
          {categories &&
            Object.keys(categories).length > 0 &&
            categories?.map((d, index) => {
              return (
                <Link
                  to={`/products?category=${d?.id}`}
                  className={classes.link}
                  key={index}
                >
                  <ListItem className={classes.category} key={index}>
                    {lang.localeCompare("en") === 0
                      ? d?.category_name
                      : d?.category_arabic_name}
                  </ListItem>
                </Link>
              );
            })}
        </List>
      </Box>
    </>
  );
}
function FeaturedProducts({data}) {
  const {items, updatecart, lang} = useInventoryItems();
  strings.setLanguage(lang);
  const [dummystate, setdummystate] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const addToCart = (d) => {
    let products = items;
    let rProduct = products.filter((p) => {
      return p.id === d.id;
    });
    if (rProduct.length) {
      rProduct = [];
      products.map((p) => {
        if (p.id === d.id) {
          p.newQuantity += 1;
        }
        rProduct.push(p);
        return "";
      });
      products = rProduct;
    } else {
      var dummy = d;
      dummy["newQuantity"] = 1;
      products.push(dummy);
    }
    updatecart(products);
    setdummystate(!dummystate);
    handleClick();
  };
  if (data === null || data === {} || data.length < 1) {
    return <></>;
  }
  return (
    <div className='products'>
      <CartSnackbar open={open} />
      <p className='text-center f-43 product-head'>
        {strings.FeaturedProducts}
      </p>
      <Grid
        container
        className='product-items px-md-5'
        spacing={2}
        justify='center'
      >
        {!!data &&
          data?.map((d, index) => {
            return (
              <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                <ProductItem product={d} cartItems={(d) => addToCart(d)} />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}

function Offers() {
  const [offer, setoffer] = useState(null);
  const [bundle, setbundle] = useState(null);
  const {lang} = useInventoryItems();
  strings.setLanguage(lang);
  useEffect(() => {
    const init = async () => {
      await axios
        .get(`${config.SERVER_URL}offer/index`)
        .then((res) => {
          setoffer(res.data.offer);
          setbundle(res.data.bundle);
        })
        .catch((error) => {});
    };
    init();
  }, []);
  return (
    <div className='offers'>
      <p className='text-center offers-head f-43'>{strings.Offers}</p>
      <Grid container className='px-1'>
        {!!offer &&
          offer?.map((off, index) => {
            return <OffersItem key={index} offer={off} />;
          })}
        {!!bundle &&
          bundle?.map((bund, index) => {
            return <BundleOffersItem key={index} bund={bund} />;
          })}
      </Grid>
    </div>
  );
}
export default Home;
