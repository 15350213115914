import React from "react";
import { makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Link } from 'react-router-dom'
const useStyles = makeStyles(() => ({
  root: {
    padding: "5px",
    marginTop: "25px",
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "contain"
  },
  card: {
    width: 260,
    height: 240
  }
}));
export default function BundleOffersItem({ bund }) {
  const classes = useStyles();
  return (
    <Grid item container justify="center" className={classes.root} xs={6} sm={4} lg={3} >
      <Link to={`/products/details/${bund?.id}`}>
        <img src={bund?.productimages[0]?.picture_reference} alt={bund?.product_name} className={classes.image} />
      </Link>
    </Grid>
  );
}
