import React from "react";
import LoaderGif from "../../assets/loader/loader-small.gif";

export default function FullPageLoader({ loading }) {
  if (!loading) return <></>;

  return (
    <div className="loader-container"
    style={{zIndex:9999,width:'100%',height:'100%',maxWidth:'100%',backgroundColor:'#f4f7f9ab'}}>
      <div className="loader" 
          style={{ display: "block" }}>
        <img
          src={LoaderGif}
          alt="this slowpoke moves"
          style={{ display: "block" }}
          width="100"
          height="100"
        />
      </div>
    </div>
  );
}
