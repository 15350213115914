import React, { useEffect, useState } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import {
  makeStyles,
  withStyles,
  Hidden,
  Container,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import BrandItem from "../../components/BrandsItem";
import axios from "axios";
import { Grid, Tabs } from "@material-ui/core";
import Config from "../../../config.json";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import strings from "./../../../lang/lang";
import { useInventoryItems } from "./../../../Context/CartContext";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import FullPageLoader from "../../components/FullPageLoader";
const SearchStyles = makeStyles((theme) => ({
  searchInput: {
    "& input:valid + fieldset": {
      borderColor: "#BF6159",
    },
    "& input:invalid + fieldset": {
      borderColor: "#BF6159",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159",
      padding: "4px !important",
    },

    "& label.Mui-focused": {
      color: "#BF6159",
    },
  },
}));
const StyledTabs = withStyles({
  indicator: {
    top: 0,
    backgroundColor: "#BF6159",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

function Brands() {
  const { lang } = useInventoryItems();
  strings.setLanguage(lang);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setloading] = useState(true);
  return (
    <>
      <FullPageLoader loading={loading} />
      <Header />
      <Container maxWidth="lg">
        <Hidden mdDown>
          <div className="d-flex brands-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{strings.Home}</Link>
              </li>
              <li className="breadcrumb-item active">{strings.Brands}</li>
            </ol>
          </div>
        </Hidden>
        <p className="f-43 text-center">{strings.Brands}</p>
        <Divider />
        <NavTabs setloading={setloading} />
      </Container>
      <Footer />
    </>
  );
}
function NavTabs({ setloading }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <AppBar
        position="relative"
        elevation={0}
        color="transparent"
        style={{ marginTop: -11 }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab className="f-20" label={strings.Brands} {...a11yProps(0)} />
          <Tab
            className="f-20"
            label={strings.FeaturedBrands}
            {...a11yProps(1)}
          />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TopBrands setloading={setloading} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FeaturedBrands setloading={setloading} />
      </TabPanel>
    </div>
  );
}
function TopBrands({ setloading }) {
  const classes = SearchStyles();
  const [brands, setbrands] = useState(null);
  const [search, setsearch] = useState("");
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      if (search === "") {
        setloading(true);
      }
      await axios
        .get(`${Config.SERVER_URL}brand?search=${search}&page=${pagenumber}`)
        .then((response) => {
          setbrands(response.data.data);
          setdata(response.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, [search, pagenumber, setloading]);
  return (
    <>
      <Grid container justify="flex-end" className="mb-4">
        <Grid item xs={3}>
          <Hidden smDown>
            <OutlinedInput
              variant="outlined"
              className={`w-100 search ${classes.searchInput}`}
              size="small"
              placeholder={strings.Search}
              value={search}
              onChange={(e) => {
                setsearch(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              style={{ padding: "10px 7px" }}
              rows="2"
            />
          </Hidden>

          <Hidden mdUp>
            <OutlinedInput
              variant="outlined"
              className={`w-100 search ${classes.searchInput}`}
              size="small"
              placeholder={strings.Search}
              value={search}
              onChange={(e) => {
                setsearch(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              style={{ padding: "10px 7px" }}
              rows="2"
            />
          </Hidden>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {brands?.map((d, index) => {
          return (
            <Grid item xs={6} md={4} lg={3} key={index}>
              <BrandItem data={d} />
            </Grid>
          );
        })}
      </Grid>
      {brands && (
        <Grid container item xs={12} justify="flex-end">
          <Pagination
            count={parseInt(data?.total / 12 + 1)}
            showFirstButton
            showLastButton
            onChange={(e, val) => setpagenumber(val)}
          />
        </Grid>
      )}
    </>
  );
}
function FeaturedBrands({ setloading }) {
  const classes = SearchStyles();
  const [brands, setbrands] = useState(null);
  const [search, setsearch] = useState("");
  const [pagenumber, setpagenumber] = useState(1);
  const [data, setdata] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      if (search === "") {
        setloading(true);
      }
      await axios
        .get(`${Config.SERVER_URL}brand?search=${search}&page=${pagenumber}`)
        .then((response) => {
          setbrands(response.data.data);
          setdata(response.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, [search, pagenumber, setloading]);
  return (
    <>
      <Grid container justify="flex-end" className="mb-4">
        <Grid item xs={3}>
          <Hidden smDown>
            <OutlinedInput
              variant="outlined"
              className={`w-100 search ${classes.searchInput}`}
              size="small"
              placeholder={strings.Search}
              value={search}
              onChange={(e) => {
                setsearch(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              style={{ padding: "10px 7px" }}
              rows="2"
            />
          </Hidden>
          <Hidden mdUp>
            <OutlinedInput
              variant="outlined"
              className={`w-100 search ${classes.searchInput}`}
              size="small"
              placeholder={strings.Search}
              value={search}
              onChange={(e) => {
                setsearch(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              style={{ padding: "10px 7px" }}
              rows="2"
            />
          </Hidden>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {brands?.map((d, index) => {
          return (
            <Grid item xs={6} md={4} lg={3} key={index}>
              <BrandItem data={d} />
            </Grid>
          );
        })}
      </Grid>
      {brands && (
        <Grid container item xs={12} justify="flex-end">
          <Pagination
            count={parseInt(data?.total / 12 + 1)}
            showFirstButton
            showLastButton
            onChange={(e, val) => setpagenumber(val)}
          />
        </Grid>
      )}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Brands;
