import LocalizedStrings from "localized-strings";

let strings = new LocalizedStrings({
  ar: {
    NewPassword: "كلمة السر الجديدة",
    ReturnReason: "سبب عودة",
    ReturnOrder: "أمر الإرجاع",
    BankCardInfo: "معلومات البطاقة المصرفية",
    NameOnCard: "الاسم على البطاقة",
    CardNumber: "رقم البطاقة",
    OrderDetails: "تفاصيل الطلب",
    DoCheckOut:
      "تحقق من هذا المنتج المذهل ، قم بزيارة https://www.advabeauty.com للمزيد",
    LowToHigh: "من أسفل إلى أعلى",
    HighToLow: "من الأعلى إلى الأقل",
    Latest: "آخر",
    Optional: "خياري",
    Review: "إعادة النظر",
    Rating: "تقييم",
    WishlistSuccess: "أضيف لقائمة الأماني",
    ConfirmOrder: "تأكيد الطلب",
    PaymentMethod: "طريقة الدفع",
    PostalCode: "رمز بريدي",
    BackToPayments: "العودة إلى المدفوعات",
    Country: "بلد",
    Cart: "عربة التسوق",
    Confirm: "التأكيد",
    CartItem: "عنصر عربة التسوق",
    Reset: "إعادة تعيين",
    VerificationCodeLabel: "رمز التحقق",
    VerifyCode: "التحقق من كود",
    ResetPassword: "إعادة تعيين كلمة المرور",
    BackToLogin: "العودة إلى تسجيل الدخول",
    Continue: "استمر",
    VerificationCode: "سيتم إرسال رمز التحقق إليك",
    DontWorryWeAre: "لا تقلق ، نحن فقط نتأكد من أنك أنت",
    Home: "الصفحة الرئيسية",
    Products: "المنتجات",
    Brands: "العلامات التجارية",
    Gallery: "معرض الصور",
    Offers: "العروض",
    BestSeller: "الأكثر مبيعا ",
    FeaturedProducts: "منتجات مميزة",
    CustomerCare: "العناية بالعملاء",
    FAQ: "التعليمات",
    Shipping: "الشحن",
    ShippingRates: "أسعار الشحن",
    Accounts: "حسابات",
    MyAccount: "حساباتي",
    WeAccept: "نحن نقبل",
    NewLetter: "رسالة إخبارية",
    MakeSureYouDontMiss: "لا تفوت الاحداث الشيقة",
    Subscribe: "الإشتراك",
    AddToCart: "أضف إلى السلة",
    Search: "بحث",
    Categories: "التصنيفات",
    Price: "السعر",
    InStock: "الكميات المتوفرة",
    OutOfStock: "نفذت الكمية",
    Availability: "التوفر",
    Model: "الموديل",
    Quantity: "كمية",
    Colors: "الألوان",
    ItemRemaining: "العناصر المتبقية",
    AddToWishlist: "أضف إلى قائمة الامنيات",
    Share: "شارك",
    CustomerReviews: "آراء العملاء",
    WriteReview: "أكتب مراجعة",
    QuestionAboutProduct: "أسئلة حول المنتج",
    EnterQuesetionHere: "أدخل السؤال هنا",
    AskQuestion: "اسأل سؤالا",
    YouMayAlsoLike: "ربما يعجبك أيضا",
    FeaturedBrands: "ماركات مميزة",
    AddAPost: "أضافة صورة",
    UploadYourImage: "ارفع صورتك",
    ShowPosts: "مشاهدت المشاركات",
    TopPost: "المشاركات الاعلى",
    RecentPost: "المشاركات الاخيرة",
    CongratsYouAreRegistered: " مبروك انت مسجل",
    SubmitReview: "إرسال المراجعة",
    Profile: "الملف الشخصي",
    Addresses: "العنوان",
    Wishlist: "قائمة الرغبات",
    AdvaPoints: "نقاط أدفا",
    Orders: "الطلبات",
    Returns: "المرتجعات",
    Claims: "المطالبات",
    Payment: "الدفع",
    Preferences: "التفضيلات",
    YourCart: "عربتك",
    Total: "مجموع",
    Hello: "مرحبا",
    SAR: ".ريال",
    New: "جديد",
    PTS: "نقاط",
    Signin: "تسجيل الدخول ",
    SignOut: "تسجيل الخروج ",
    Freeshipping: "شحن مجاني للطلبات الاكثر من {0} ريال",
    FirstName: "الاسم الاول",
    LastName: "الاسم الاخير",
    Email: "بريد الالكتروني",
    Phone: "رقم التليفون",
    Password: "كلمة المرور",
    ConfirmPassword: "تأكيد كلمة المرور",
    CreateAccount: "أنشاء حساب",
    ReturnToStore: "العودة الى المتجر",
    AlreadyHaveAnAccount: "يوجد لدي حساب سابق",
    Login: "تسجيل الدخول",
    EmailMobile: "البريد الالكتروني / رقم الجوال",
    ShopsByBrand: "التسوق حسب الماركة",
    ShopsByCategory: "التسوق حسب الاقسام",
    Size: "المقاس",
    Checkout: "اتمام الدفع",
    VAT: "الظريبة",
    BackToHome: "العودة للصفحة الرئيسية",
    PersonalInformation: "المعلومات الشخصية",
    FullName: "الاسم بالكامل",
    PhoneNumber: "رقم الهاتف",
    ShippingAddress: "عنوان الشحن",
    AddAddress: "أضف العنوان",
    ProceedToPayments: "اتمام عملية الدفع",
    Delete: "حذف",
    Edit: "تحرير",
    RewardPoints: "نقاط المكافات",
    NoReturnRequestMade: "لا يوجد طلب للاسترجاع",
    PaymentsCard: "بطاقة المدفوعات",
    YourOrderHasBeenReceived: "تم استلام طلبكم",
    YourOrderHasBeenConfirmed: "تم تأكيد الطلب",
    YourOrderisOnTheWay: "تم قيد التجهيز والشحن",
    TypeYourCommentHere: "أكتب ملاحظاتك هنا",
    Like: "اعجاب",
    Comment: "التعليقات",
    SortBy: "ترتيب حسب",
    ForgotPassword: "هل نسيت كلمة السر؟",
    Faqs: "الاسئلة الشائعة",
    TypeEmailHere: "اكتب البريد الالكتروني",
    BackToCart: "العودة إلى عربة التسوق",
    EnterYourQuestionHere: "أدخل سؤالك هنا",
    CreateAPost: "إنشاء وظيفة",
    Description: "وصف",
    UploadImage: "تحميل الصور",
    ChangePassword: "تغيير كلمة المرور",
    Save: "حفظ",
    Cancel: "يلغي",
    Default: "تقصير",
    UpdateAddress: "تحديث العنوان",
    PointsCurrencyExchage: "النقاط/ معدل احتساب النقاط",
    RedeemPoints: "استبدال النقاط",
    Name: "اسم",
    DiscountAmount: "مقدار الخصم",
    ValidTill: "صالح لغاية",
    VoucherName: "اسم القسيمة",
    PartnerName: "اسم شريك",
    PointsRequired: "النقاط المطلوبة",
    From: "من",
    To: "من",
    CopyRight: "حقوق الطبع",
    TermsConditions: "الأحكام والشروط",
    PrivacyPolicy: "سياسة خاصة",
    Status: "الحالة",
    Address: "عنوان",
    TotalItems: "إجمالي العناصر",
    Date: "تاريخ",
    TotalAmount: "المبلغ الإجمالي",
    WantToMakeAReturnRequest: "تريد تقديم طلب عودة؟ اذهب إلى الطلبات.",
    NoClaimRequestMade: "لم يتم تقديم طلب",
    WantToMakeAClaimRequest: "تريد تقديم طلب مطالبة",
    MakeClaimRequest: "تقديم طلب المطالبة",
    City: "المدينة",
    Voucher: "القسائم",
    Points: "نقاط",
    ReturnRequest: "طلب ارجاع",
    WelcomeToAdva: "مرحبا بكم في أدفا ",
    Verify: "التحقق",
    Youhavesuccessfullyloggedin: "لقد قمت بتسجيل الدخول بنجاح",
    Getupto50discountonourlatestproducts:
      "احصل على خصم يصل إلى 50٪ على أحدث منتجاتنا",
    ShopNow: "تسوق الآن",
    TypeMessageHere: "اكتب رسالة",
    PromoCode: "الرمز الترويجي",
    DiscountCode: "كود الخصم",
    PointsDiscount: "",
    Next: "التالي",
    CODCharges: "الدفع عند الاستلام",
    CreditDebitCard: "بطاقة الائتمان/الصراف الألى",
    CurrentPassword: "الرقم السري الحالي",
    Communication: "تواصل",
    ProductAndStock: "المنتجات و المخزون",
    Delivery: "التوصيل",
    ReturnAndRefund: "الاسترجاع",
    Account: "الحساب",
    CashOnDelivery: "الدفع عند الاستلام",
    OrderIssue: "مشكلة في الطلب",
    All: "الكل",
    AboutUs: "من نحن",
    AddNewAddress: "أضف عنوان جديد",

    Notifications: "التنبيهات",
    NoNotifications: "لا يوجد تنبيهات",
    Loading: "تحميل",
    ArrivedInStock: "القي نظرة, وصل حديثا ",
    CollectionArrivedInStock: "المجموعة وصلت حديثا",
    CheckoutThisAmazingOfferAndGetOff: "لا تفوت العرض المذهل واحصل على خصم",
    ViewAll: "عرض الكل",
    GoToHome: "العودة للصفحة الرئيسية",
    YourOrderIsOnTheWay: "تم قيد التجهيز والشحن",
    Success: "تم بنجاح",
    ThereAreNoRecordsToDisplay: "لايوجد أي سجلات لعرضها",
    None: "لا أحد",
    RowPerPage: "صف لكل صفحة",
    ReturnStatus: "حالة العودة",
    ProductState: "حالة المنتج",
    OrderID: "رقم اطلب",
    Of: "ل",
    SearchForPost: "بحث عن صورة",
    NoItemInWishList: "لا توجد منتجات في قائمة الرغبات",
    InvalidCredentials: "المعلومات غير صالحة",
    AddressUpdatedSuccessfully: "تم تحديث العنوان بنجاح",
    PasswordUpdatedSuccessfully: "تم تحديث الرمز السري بنجاح",
    RedeemSuccessfully: "تم الاسترداد بنجاح",
    OrderReturned: "تم ارجاع الطلب",
    AddressAddedSuccessfully: "تم اضافة العنوان بنجاح",
    MessageFailed: "فشل تسليم الرسالة",
    ProductAddedToTheCart: "تم اضافة المنتج للسلة",
    GalleryAgreement:
      "لا يوجد لدي اي مانع من نشر الصور الخاصة بي لدى موقع ADVA Beauty , وهذا اقرار مني بذلك, حيث لايوجد أي مسؤولية على الشركة مطلقا.",
    Agree: "موافق",
    Disagree: "غير موافق",
    RemoveFromWishlist: "حذف من قائمة الرغبات",
    YourCartisEmpty: "السلة فارغة",
    LooksLikeYouHaventChooseAnything: "يبدو انه لم يتم اختيار شيء",
    ApplyCodes: "تطبيق الرمز",
    ProductRemovedFromWishlist: "تم حذف المنتج من قائمة الرغبات",
    Office: "مكتب",
    AddressHome: "المنزل",
    AddressType: "نوع العنوان",
    SaudiArabia: "المملكة العربية السعودية",
    Kuwait: "الكويت",
    Emirates: "الإمارات",
    Qatar: "قطر",
    Bahrain: "البحرين",
    Oman: "عمان",
    Egypt: "مصر",
    Jordan: "الأردن",
    Yamen: "يامين",
    Iraq: "العراق",
  },
  en: {
    Home: "Home",
    Products: "Products",
    Brands: "Brands",
    Gallery: "Gallery",
    Offers: "Offers",
    BestSeller: "Best Seller",
    FeaturedProducts: "Featured Products",
    CustomerCare: "Customer Care",
    FAQ: "FAQ's",
    Shipping: "Shipping",
    ShippingRates: "Shipping Rates",
    Accounts: "Accounts",
    MyAccount: "My Account",
    WeAccept: "W Accept",
    NewLetter: "Newsletter",
    MakeSureYouDontMiss: "Make sure you don't miss interesting happenings",
    Subscribe: "Subscribe",
    AddToCart: "Add to cart",
    Search: "Search",
    Categories: "Categories",
    Latest: "Latest",
    Price: "Price",
    InStock: "In Stock",
    OutOfStock: "Out of Stock",
    Availability: "Availability",
    Model: "Model",
    Quantity: "Quantity",
    Colors: "Colors",
    ItemRemaining: "Item Remaining",
    AddToWishlist: "Add to Wishlist",
    Share: "Share",
    CustomerReviews: "Customer Reviews",
    WriteReview: "Write Review",
    QuestionAboutProduct: "Question About Product",
    EnterQuesetionHere: "Enter Question Here",
    AskQuestion: "Ask Question",
    YouMayAlsoLike: "You May Also Like",
    FeaturedBrands: "Featured Brands",
    AddAPost: "Add a Post",
    UploadYourImage: "Upload Your Image",
    ShowPosts: "Show Posts",
    TopPost: "Top Posts",
    RecentPost: "Recent Posts",
    CongratsYouAreRegistered: " Congrats You Are Registered",
    SubmitReview: "Submit Review",
    Profile: "Profile",
    Addresses: "Addresses",
    Wishlist: "Wish List",
    AdvaPoints: "Adva Points",
    Orders: "Orders",
    Returns: "Returns",
    Claims: "Claims",
    Payment: "Payment",
    Preferences: "Preferences",
    YourCart: "Your Cart",
    Total: "Total",
    Hello: "Hello",
    SAR: "SAR",
    New: "New",
    PTS: "PTS",
    Signin: "Sign In",
    SignOut: "Sign Out",
    Freeshipping: "Free shipping orders over {0} riyals",
    FirstName: "First name",
    LastName: "Last name",
    Email: "Email",
    Phone: "Phone No.",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    CreateAccount: "Create Account",
    ReturnToStore: "Return To Store",
    AlreadyHaveAnAccount: "Already Have An Account",
    Login: "Login",
    EmailMobile: "Email / Mobile number",
    ShopsByBrand: "Shop By Brand",
    ShopsByCategory: "Shop By Category",
    Size: "Size",
    Checkout: "Checkout",
    VAT: "VAT",
    BackToHome: "Back To Home",
    PersonalInformation: "Personal Information",
    FullName: "Full Name",
    PhoneNumber: "Phone Number",
    ShippingAddress: "Shipping Address",
    AddAddress: "Add Address",
    ProceedToPayments: "Proceed to Payments",
    Delete: "Delete",
    Edit: "Edit",
    RewardPoints: "Reward Points",
    NoReturnRequestMade: "No Return Request Made",
    PaymentsCard: "Payments Card",
    YourOrderHasBeenReceived: "Your order has been received",
    YourOrderHasBeenConfirmed: "Your order has been Confirmed",
    YourOrderisOnTheWay: "Your order is on the way",
    TypeYourCommentHere: "Type Your Comment Here",
    Like: "Like",
    Comment: "Comment",
    SortBy: "Sort By",
    ForgotPassword: "Forgot Password?",
    DontWorryWeAre: "Don't worry we are just making sure that its you",
    VerificationCode: "Verification Code will be send to you",
    Continue: "Continue",
    BackToLogin: "Back To Login",
    ResetPassword: "Reset Password",
    NewPassword: "New Password",
    VerifyCode: "Verify Code",
    VerificationCodeLabel: "Verification Code",
    Reset: "Reset",
    CartItem: "Cart Item",
    Cart: "Cart",
    Confirm: "Confirm",
    BackToPayments: "Back To Payments",
    Country: "Country",
    PostalCode: "Postal Code",
    PaymentMethod: "Payment Method",
    WishlistSuccess: "Added To Wishlist",
    ConfirmOrder: "Confirm Order",
    Rating: "Rating",
    Review: "Review",
    Optional: "Optional",
    DoCheckOut:
      "Do check out this amazing product, visit https://www.advabeauty.com for more",
    LowToHigh: "Low To High",
    HighToLow: "High To Low",
    NameOnCard: "Name On Card",
    CardNumber: "Card Number",
    OrderDetails: "Order Details",
    BankCardInfo: "Bank Card Information",
    ReturnOrder: "Return Order",
    ReturnReason: "Return Reason",
    Faqs: "FAQS",
    TypeEmailHere: "Type Email Here",
    BackToCart: "Back To Cart",
    EnterYourQuestionHere: "Enter Your Question Here",
    CreateAPost: "Create A Post",
    Description: "Description",
    UploadImage: "Upload Image",
    ChangePassword: "Change Password",
    Save: "Save",
    Cancel: "Cancel",
    Default: "Default",
    UpdateAddress: "Update Address",
    PointsCurrencyExchage: "Points/Currency Exchange Rate",
    RedeemPoints: "Redeem Points for voucher",
    Name: "Name",
    DiscountAmount: "Discount Amount",
    ValidTill: "Valid Till",
    VoucherName: "Voucher Name",
    PartnerName: "Partner Name",
    PointsRequired: "Points Required",
    From: "From",
    To: "To",
    CopyRight: "Copyright",
    TermsConditions: "Terms & Condition",
    PrivacyPolicy: "Privacy Policy",
    Status: "Status",
    Address: "Address",
    TotalItems: "Total Items",
    Date: "Date",
    TotalAmount: "Total Amount",
    WantToMakeAReturnRequest: "Want to make a return request? go to orders.",
    NoClaimRequestMade: "No Claim Request Made",
    WantToMakeAClaimRequest: "Want to make a claim request",
    MakeClaimRequest: "Make Claim Request",
    City: "City",
    Voucher: "Voucher",
    Points: "Points",
    ReturnRequest: "Return Request",
    WelcomeToAdva: "Welcome To adva",
    Verify: "Verify",
    Youhavesuccessfullyloggedin: "You have successfully logged in",
    Getupto50discountonourlatestproducts:
      "Get upto 50% discount on our latest products",
    ShopNow: "Shop Now",
    TypeMessageHere: "Type A Message",
    PromoCode: "Promo Code",
    DiscountCode: "Discount Code",
    PointsDiscount: "Points Discount",
    Next: "Next",
    CODCharges: "COD Charges",
    CreditDebitCard: "Credit/Debit Card",
    CurrentPassword: "Current Password",
    Communication: "Communication",
    ProductAndStock: "Product & Stock",
    Delivery: "Delivery",
    ReturnAndRefund: "Return & Refund",
    Account: "Account",
    CashOnDelivery: "Cash On Delivery",
    OrderIssue: "Order Issue",
    All: "All",
    AboutUs: "About us",
    AddNewAddress: "Add new address",
    Notifications: "Notifications",
    NoNotifications: "No Notifications",
    Loading: "Loading",
    ArrivedInStock: "Arrived in stock",
    CollectionArrivedInStock: "Collection arrived in stock",
    CheckoutThisAmazingOfferAndGetOff:
      "Checkout this amazing offer and get {0}% off",
    ViewAll: "View all",
    GoToHome: "Go to Home",
    YourOrderIsOnTheWay: "Your Order is on the way",
    Success: "Success",
    ThereAreNoRecordsToDisplay: "There are no records to display",
    None: "None",
    RowPerPage: "Rows Per Page",
    ReturnStatus: "Return Status",
    ProductState: "Product State",
    OrderID: "Order ID",
    Of: "Of",
    SearchForPost: "Search for post",
    NoItemInWishList: "No Item in wishlist",
    InvalidCredentials: "Invalid credentials",
    AddressUpdatedSuccessfully: "Address updated successfully",
    PasswordUpdatedSuccessfully: "Password updated successfully",
    RedeemSuccessfully: "redeem successfully",
    OrderReturned: "Order returned",
    AddressAddedSuccessfully: "Address added successfully",
    MessageFailed: "Message failed",
    ProductAddedToTheCart: "Product added to the cart",
    GalleryAgreement:
      "I have no objection to publishing my photos on the ADVA Beauty website, and this is my acknowledgment of that, as there is absolutely no responsibility on the company.",
    Agree: "Agree",
    Disagree: "Disagree",
    RemoveFromWishlist: "Remove from wishlist",
    YourCartisEmpty: "Your cart is empty",
    LooksLikeYouHaventChooseAnything: "Look's like you haven’t choose anything",
    ApplyCodes: "Apply codes",
    ProductRemovedFromWishlist: "Product removed from wishlist",
    Office: "Office",
    AddressHome: "Home",
    AddressType: "Address Type",
    SaudiArabia: "Saudi Arabia",
    Kuwait: "Kuwait",
    Emirates: "Emirates",
    Qatar: "Qatar",
    Bahrain: "Bahrain",
    Oman: "Oman",
    Egypt: "Egypt",
    Jordan: "Jordan",
    Yamen: "Yamen",
    Iraq: "Iraq",
  },
});
export default strings;
