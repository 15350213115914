import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import strings from './../../lang/lang';
function CartSnackbar({ open }) {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={open}
            autoHideDuration={3000}
        >
            <Alert severity="success" variant="filled">{strings.ProductAddedToTheCart }</Alert>
        </Snackbar>
    )
}

export default CartSnackbar
