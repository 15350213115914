import React, { useEffect } from "react";
import NoContent from "../../../assets/icons/NoContent.svg";
import Grid from "@material-ui/core/Grid";
import { Typography, Button, Divider } from "@material-ui/core";
import strings from './../../../lang/lang';
function Claims() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Typography component="h4" align="center" variant="h4">{strings.Claims}</Typography>
      <Divider />
      <Grid item container justify="center">
        <img src={NoContent} alt="" />
      </Grid>
      <Typography variant="h5" align="center">
        {strings.NoClaimRequestMade}
      </Typography>
      <Typography variant="caption" component="p" align="center">
        {strings.WantToMakeAClaimRequest}
      </Typography>
      <Grid item container justify="center" className="mt-4">
        <Button color="primary" variant="contained">
          {strings.MakeClaimRequest}
        </Button>
      </Grid>
    </div>
  );
}

export default Claims;
