import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import { Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import strings from "./../../lang/lang";
import { useInventoryItems } from "./../../Context/CartContext";
import LazyLoad from "react-lazyload";
const useStyles = makeStyles({
  root: {
    maxWidth: 370,
    borderRadius: "0px",
    border: "1px solid #BF615980",
    "&:hover": {
      boxShadow: " 0px 3px 20px #BF615930",
      transform: "scale(1)",
    },
  },
  media: {
    width: "100%",
    height: "auto",
  },
  CardAction: {
    padding: 0,
    border: "1px solid #BF6159",
    "&:hover": {
      background: "#BF6159",
      "& button": {
        color: "white",
      },
    },
  },
  mainColor: {
    color: "#BF6159",
  },
});
function ProductItem({ product, cartItems }) {
  const { lang } = useInventoryItems();
  strings.setLanguage(lang);
  const classes = useStyles();
  const addToCart = (d) => {
    cartItems(d);
  };
  return (
    <>
      {product && (
        <LazyLoad offset={200}>
          <Card
            className={`${classes.root} mx-auto mb-2`}
            style={{ position: "relative" }}
            elevation={0}
          >
            <Link to={`/products/details/${product?.id}`}>
              <img
                src={product?.productimages[0]?.picture_reference}
                alt="Product Item"
                className={classes.media}
                loading="lazy"
              />
            </Link>
            {product?.quantity < 1 ? (
              <span className="outofstock">{strings.OutOfStock}</span>
            ) : (
              ""
            )}
            <IsNew product={product} />
            <CardActions
              className={classes.CardAction}
              onClick={() => {
                if (product?.quantity > 1) addToCart(product);
              }}
            >
              <Button
                className={`mx-auto font-weight-bold f-block w-100 f-20 ${
                  product?.quantity > 0 ? classes.mainColor : ""
                }`}
                disabled={product?.quantity < 1 ? true : false}
                disableRipple
                disableFocusRipple
                disableTouchRipple
              >
                {strings.AddToCart}
              </Button>
            </CardActions>
          </Card>
          <p className={`text-center mb-1 f-18`}>
            {lang === "en"
              ? product?.product_name
              : product?.product_arabic_name}
          </p>
          <Box justifyContent="center" display="flex" alignItems="center">
            {product?.discounted_amount > 0 && (
              <p
                className="text-center f-14 discount mx-2"
                style={{ opacity: 0.7 }}
              >
                {strings.SAR} {product?.price}
              </p>
            )}
            <p className="text-center f-20" style={{ fontWeight: 700 }}>
              {strings.SAR} {product?.price - product?.discounted_amount}
            </p>
          </Box>
        </LazyLoad>
      )}
    </>
  );
}
function IsNew({ product }) {
  var todayDate = new Date();
  var dateOne = new Date(product?.created_at);

  const diffTime = Math.abs(todayDate - dateOne);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays < 31 && product.quantity > 0) {
    return <span className="new">{strings.New}</span>;
  } else {
    return <></>;
  }
}
export default ProductItem;
