import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  makeStyles,
  Grid,
  Box,
  Divider,
  Hidden,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Logo from "../../../assets/logo/ADVA.png";
import { Link, useHistory } from "react-router-dom";
import SuccessModal from "../../components/SuccessModal";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
import { useInventoryItems } from "./../../../Context/CartContext";
import strings from "./../../../lang/lang";
import FullPageLoader from "../../components/FullPageLoader";

const useStyles = makeStyles((theme) => ({
  root: {},
  back: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  Button: {
    backgroundColor: "#BF6159",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
}));
function Confirm({ UserInfo, setconfirmOrder }) {
  const classes = useStyles();
  const [products] = useState(
    sessionStorage.getItem("products")
      ? JSON.parse(sessionStorage.getItem("products"))
      : null
  );
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const { emptycart, emptytotal, updatepromo, updateisCOD, updatediscount } =
    useInventoryItems();
  const location = useHistory();
  const successButton = useRef();
  const [error, seterror] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const { returnValue } = useInventoryItems();
  const [loading, setloading] = useState(false);
  const PlaceOrder = async () => {
    setisLoading(true);
    if (products !== null) {
      setloading(true);
      if (user) {
        await axios
          .post(`${Config.SERVER_URL}order/create`, {
            customer_id: user?.id ? user.id : 0,
            total: returnValue(),
            email: UserInfo?.CustomerInfo?.Email,
            phone: UserInfo?.CustomerInfo?.PhoneNo,
            address_id: UserInfo?.CustomerInfo?.Address_id,
            products: products,
            // card_id: UserInfo?.PaymentInfo?.Card_id,
            payment_type:
              UserInfo?.PaymentInfo?.PaymentType !== "CashonDelivery"
                ? "CreditCardPayments"
                : "CashonDelivery",
            brand:
              UserInfo?.PaymentInfo?.PaymentType === "MADA"
                ? "MADA"
                : "VISA/MASTER",
            promo_code: UserInfo?.PaymentInfo?.promo_code,
            discount_code: UserInfo?.PaymentInfo?.discount_code,
            points_discount: UserInfo?.PaymentInfo?.points_discount,
          })
          .then((response) => {
            if (UserInfo?.PaymentInfo?.PaymentType === "CashonDelivery") {
              sessionStorage.setItem("products", JSON.stringify([]));
              sessionStorage.setItem("vat", JSON.stringify(0));
              sessionStorage.setItem("total", JSON.stringify(0));
              emptycart([]);
              updatepromo({ promo_code: "" });
              updatediscount({ discount_code: "" });
              updateisCOD("CreditCard");
              user.points = response.data.points;
              sessionStorage.setItem("user", JSON.stringify(user));
              successButton.current.click();
            } else {
              const url = `/checkout/payment/${response.data?.data?.id}?email=${UserInfo?.CustomerInfo?.Email}&phone=${UserInfo?.CustomerInfo?.PhoneNo}&address_id=${UserInfo?.CustomerInfo?.Address_id}&promo_code=${UserInfo?.PaymentInfo?.promo_code}&discount_code=${UserInfo?.PaymentInfo?.discount_code}&points_discount=${UserInfo?.PaymentInfo?.points_discount}&brand=${response.data.brand}`;

              location.push(url);
            }
            setloading(false);
          })
          .catch((error) => {
            setisLoading(false);
            error.response &&
              error.response.status === 400 &&
              seterror(error.response.data);
            setloading(false);
          });
      } else {
        await axios
          .post(`${Config.SERVER_URL}order/guest/create`, {
            customer_id: 0,
            full_name: UserInfo?.CustomerInfo?.FullName,
            email: UserInfo?.CustomerInfo?.Email,
            phone: UserInfo?.CustomerInfo?.PhoneNo,
            address: UserInfo?.CustomerInfo?.address,
            country: UserInfo?.CustomerInfo?.country,
            city: UserInfo?.CustomerInfo?.city,
            postal_code: UserInfo?.CustomerInfo?.postal_code,
            card_brand: UserInfo?.PaymentInfo?.card_name,
            name_on_card: UserInfo?.PaymentInfo?.name_on_card,
            number_on_card: UserInfo?.PaymentInfo?.number_on_card,
            expiry_date: UserInfo?.PaymentInfo?.expiry_date,
            security_code: UserInfo?.PaymentInfo?.security_code,
            products: products,
            payment_type: UserInfo?.PaymentInfo?.PaymentType,
            points_discount: UserInfo?.PaymentInfo?.points_discount,
          })
          .then((response) => {
            successButton.current.click();
            sessionStorage.setItem("products", JSON.stringify([]));
            emptycart([]);
            emptytotal(0);
            setloading(false);
          })
          .catch((error) => {
            setisLoading(false);
            error.response &&
              error.response.status === 400 &&
              seterror(error.response.data);
            setloading(false);
          });
      }
    }
  };
  const [address, setaddress] = useState(null);
  useEffect(() => {
    const init = async () => {
      if (UserInfo?.CustomerInfo?.Address_id) {
        setloading(true);
        await axios
          .get(
            `${Config.SERVER_URL}address/one/${UserInfo?.CustomerInfo?.Address_id}`
          )
          .then((res) => {
            setaddress(res.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    };
    init();
  }, [UserInfo]);
  return (
    <>
      <FullPageLoader loading={loading} />
      <Grid container className="mt-4">
        <Grid xs={12} md={5} item className="LeftSide">
          <Link to="/" className="mx-5">
            <img src={Logo} alt="Logo" />
          </Link>
          <nav className="breadcrumb mx-5">
            <li className="breadcrumb-item">
              <Link to="/">{strings.Cart}</Link>
            </li>
            <li className="breadcrumb-item ">
              <Link to="/checkout">{strings.Checkout}</Link>
            </li>
            <li className="breadcrumb-item active">{strings.Confirm}</li>
          </nav>
          <span
            className="mx-5"
            onClick={() => setconfirmOrder(false)}
            style={{ cursor: "pointer" }}
          >
            <ArrowBackIosIcon /> {strings.BackToPayments}
          </span>
          <div className={`${classes.root} w-md-75 ml-auto mt-3`}>
            {error &&
              Object.keys(error).map((key) => {
                return (
                  <Alert severity="error" key={key}>
                    {error[key]}
                  </Alert>
                );
              })}
            <h5>{strings.OrderDetails}</h5>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <h5>{strings.PersonalInformation}</h5>
                  <div>
                    <p className="font-weight-bold">{strings.FullName}</p>
                    <p>{UserInfo?.CustomerInfo?.FullName}</p>
                  </div>
                  <div>
                    <p className="font-weight-bold">{strings.Email}</p>
                    <p style={{ wordBreak: "break-word" }}>
                      {UserInfo?.CustomerInfo?.Email}
                    </p>
                  </div>
                  <div>
                    <p className="font-weight-bold">{strings.PhoneNumber}</p>
                    <p>{UserInfo?.CustomerInfo?.PhoneNo}</p>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <h5>{strings.ShippingAddress}</h5>
                  <div>
                    <p className="font-weight-bold">{strings.Addresses}</p>
                    <p>{address?.address}</p>
                  </div>
                  <div className="d-flex-start-center">
                    <div className="w-50">
                      <p className="font-weight-bold">{strings.Country}</p>
                      <p>{address?.country}</p>
                    </div>
                    <div>
                      <p className="font-weight-bold">{strings.City}</p>
                      <p>{address?.city}</p>
                    </div>
                  </div>
                  <div>
                    <p className="font-weight-bold">{strings.PostalCode}</p>
                    <p>{address?.postal_code}</p>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Box p={2}>
                  <h5>{strings.PaymentMethod}</h5>
                  <p>
                    {UserInfo?.PaymentInfo?.PaymentType ===
                      "CreditCardPayments" ||
                    UserInfo?.PaymentInfo?.PaymentType === "MADA"
                      ? "Credit Card"
                      : "Cash On Delivery"}
                  </p>
                </Box>
              </Grid>
            </Grid>
            <div className="d-flex-end-center pt-5">
              <Button
                className={`${classes.Button} w-md-100`}
                color="primary"
                variant="contained"
                onClick={() => PlaceOrder()}
                disabled={isLoading}
              >
                {strings.ConfirmOrder}
              </Button>
            </div>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            xs={12}
            md={5}
            container
            justify="center"
            alignItems="center"
          >
            <Order />
          </Grid>
        </Hidden>
      </Grid>
      <button
        className="d-none"
        ref={successButton}
        data-toggle="modal"
        data-target="#SuccessModal"
      ></button>
      <SuccessModal />
    </>
  );
}

const orderStyles = makeStyles((theme) => ({
  root: {
    marginTop: "110px",
    width: "75%",
  },
  totalRoot: {
    "& p": {
      fontWeight: "bold",
    },
  },
}));
// Cart and Order are same thing.Names are different because design is different
function Order() {
  const classes = orderStyles();
  const { items, returnValue, isCOD } = useInventoryItems();
  const [products] = useState(items);
  return (
    <>
      <section className={classes.root}>
        <h5 className="font-19 my-3">{strings.CartItem}</h5>
        {products &&
          products?.map((p, index) => {
            return <OrderItem key={index} prod={p} />;
          })}

        {returnValue("isFree") && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.Shipping}</p>
              <p>
                {strings.SAR}
                <span className="text-danger">{returnValue("shipping")}</span>
              </p>
            </div>
          </div>
        )}
        <div className={classes.totalRoot}>
          <div className="d-flex-between-center">
            <p>{strings.VAT}</p>
            <p>
              {strings.SAR}
              <span className="text-danger">{returnValue("vat")}</span>
            </p>
          </div>
        </div>

        {/* Promo if applied */}
        {returnValue("promo") > 0 && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.Promo}</p>
              <p>
                {strings.SAR}
                <span className="text-danger">{returnValue("promo")}</span>
              </p>
            </div>
          </div>
        )}
        {/* Discount if Applied */}
        {returnValue("discount") > 0 && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.DiscountAmount}</p>
              <p>
                {strings.SAR}
                <span className="text-danger">{returnValue("discount")}</span>
              </p>
            </div>
          </div>
        )}
        {returnValue("pointsDiscount") > 0 && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.PointsDiscount}</p>
              <p>
                {strings.SAR}
                <span className="text-danger">
                  {returnValue("pointsDiscount")}
                </span>
              </p>
            </div>
          </div>
        )}
        {/* Discount if Applied */}
        {isCOD && (
          <div className={classes.totalRoot}>
            <div className="d-flex-between-center">
              <p>{strings.CODCharges}</p>
              <p>
                {strings.SAR}
                <span className="text-danger">{returnValue("cod")}</span>
              </p>
            </div>
          </div>
        )}
        <div className={classes.totalRoot}>
          <div className="d-flex-between-center">
            <p>{strings.Total}</p>
            <p>
              {strings.sar} <span className="text-danger">{returnValue()}</span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
// CartItem and OrderItem are same thing.Names are different because design is different
function OrderItem({ prod }) {
  const { lang } = useInventoryItems();
  return (
    <>
      <div className="row my-2  border-bottom-main pb-1">
        <div className="col-md-3">
          <img
            src={prod?.productimages[0]?.picture_reference}
            alt={prod?.product_name}
            width="100"
            height="100"
            className="mx-auto"
          />
        </div>
        <div className="col-md-9">
          <div className="d-flex-between-center">
            <h6>
              {lang.localeCompare("en") === 0
                ? prod?.product_name
                : prod?.product_arabic_name}
            </h6>
            <p className="text-right px-4">
              {strings.Price}: {strings.SAR}
              {prod?.price - prod?.discounted_amount}
            </p>
          </div>
          <div className="d-flex-end-center">
            <p className="text-right px-4">
              {strings.Quantity}: {prod?.newQuantity}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Confirm;
