import { useInventoryItems } from "./../../../Context/CartContext";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Logo from "../../../assets/logo/ADVA.png";

import Visa from "../../../assets/icons/visa.png";
import MasterCard from "../../../assets/icons/mastercard.png";
import Mada from "../../../assets/icons/mada.png";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  makeStyles,
  Button,
  Grid,
  OutlinedInput,
  InputAdornment,
  Typography,
  Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
import strings from "../../../lang/lang";
import FullPageLoader from "../../components/FullPageLoader";
const useStyles = makeStyles(() => ({
  root: {},
  back: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      textDecoration: "none",
      color: "black",
    },
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    margin: "3px auto",
    "& input:invalid + fieldset": {
      borderColor: "#BF6159 !important",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159 !important",
      padding: "4px !important",
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#BF6159",
    },
  },
  Button: {
    backgroundColor: "#BF6159",
    "&:hover": {
      backgroundColor: "#BF6159",
    },
  },
  selected: {
    color: "#BF6159",
  },
}));
function Payments({ onClickCall, saveData }) {
  const { handleSubmit, errors } = useForm();
  const {
    updateisCOD,
    updatepromo,
    updatediscount,
    returnValue,
    updateDiscountValue,
    Adiscount,
    Apromo,
  } = useInventoryItems();
  const classes = useStyles();
  const [PaymentType, setPaymentType] = useState("CreditCardPayments");
  const [PaymentInformation, setPaymentInformation] = useState({
    card_name: "",
    PaymentType: "",
    promo_code: Apromo?.promo_code,
    discount_code: Adiscount?.discount_code,
    points_discount: 0,
  });
  const [loading, setloading] = useState(false);
  const onSubmit = () => {
    PaymentInformation.PaymentType = PaymentType;
    saveData(PaymentInformation);
  };
  function handlePaymentChange(e) {
    setPaymentType(e.target.value);
    updateisCOD(e.target.value);
  }
  function handleInformationChange(e) {
    PaymentInformation[e.target.name] = e.target.value;
    setPaymentInformation(PaymentInformation);
  }
  const [customer] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [promoerror, setpromoerror] = useState({});
  const [discounterror, setdiscounterror] = useState({});
  const applyPromo = async () => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}promo/check`, {
        promo_code: PaymentInformation.promo_code,
      })
      .then((res) => {
        updatepromo(res.data);
        setpromoerror({});
        setloading(false);
      })
      .catch((error) => {
        error &&
          error.response.status === 400 &&
          setpromoerror(error.response.data);
        setPaymentInformation({ ...PaymentInformation, promo_code: "" });
        setTimeout(() => {
          setpromoerror({});
        }, 3000);
        setloading(false);
      });
  };
  const applyDiscount = async () => {
    setloading(true);
    await axios
      .post(`${Config.SERVER_URL}discount/check`, {
        discount_code: PaymentInformation.discount_code,
      })
      .then((res) => {
        updatediscount(res.data);
        setdiscounterror({});
        setloading(false);
      })
      .catch((error) => {
        error &&
          error.response.status === 400 &&
          setdiscounterror(error.response.data);

        setPaymentInformation({ ...PaymentInformation, discount_code: "" });
        setTimeout(() => {
          setdiscounterror({});
        }, 3000);
        setloading(false);
      });
  };
  return (
    <>
      {/* <AddCard
        open={open}
        handleClose={handleClose}
        setcards={setcards}
        customer={customer}
      /> */}
      <FullPageLoader loading={loading} />
      <Link to="/">
        <img src={Logo} alt="Logo" />
      </Link>
      <nav className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">{strings.Cart}</Link>
        </li>
        <li className="breadcrumb-item active">{strings.CheckOut}</li>
      </nav>
      <Button onClick={() => onClickCall(false)} className={classes.back}>
        <ArrowBackIosIcon /> {strings.BackToCart}
      </Button>
      <div className={classes.root}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`mx-auto mx-md-4 my-20`}
        >
          <Grid container>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    className="text-dark"
                    style={{ fontWeight: 25 }}
                  >
                    {strings.PaymentMethod}
                  </FormLabel>

                  <RadioGroup
                    aria-label="PaymentMethod"
                    name="PaymentMethod"
                    value={PaymentType}
                    onChange={(e) => handlePaymentChange(e)}
                  >
                    <FormControlLabel
                      value="CreditCardPayments"
                      control={<Radio />}
                      label={
                        <>
                          <img src={Visa} alt="visa" srcset="" />/
                          <img src={MasterCard} alt="MasterCard" srcset="" />
                        </>
                      }
                    />
                    <FormControlLabel
                      value="MADA"
                      control={<Radio />}
                      label={
                        <>
                          <img src={Mada} alt="Mada" srcset="" />
                        </>
                      }
                    />
                    <FormControlLabel
                      value="CashonDelivery"
                      control={<Radio />}
                      label={strings.CashOnDelivery}
                    />
                  </RadioGroup>
                </FormControl>
                <span className="error">{errors.PaymentMethod?.message}</span>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <p className="f-25" style={{ fontWeight: 700 }}>
                {strings.ApplyCodes}
              </p>
              <FormControl fullWidth>
                <label htmlFor="">{strings.PromoCode}</label>
                <OutlinedInput
                  name="promo_code"
                  color="primary"
                  variant="outlined"
                  onChange={handleInformationChange}
                  fullWidth
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <AddCircleOutlineIcon
                        className="text-main"
                        onClick={applyPromo}
                      />
                    </InputAdornment>
                  }
                />
                {Apromo?.promo_code !== "" && (
                  <Chip
                    variant="outlined"
                    label={Apromo?.promo_code}
                    color="primary"
                    className="discount-chip"
                    onDelete={() => updatepromo(null)}
                  />
                )}
                {promoerror &&
                  Object.keys(promoerror).map((key) => {
                    return (
                      <Alert severity="error" key={key}>
                        {promoerror[key]}
                      </Alert>
                    );
                  })}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label htmlFor="">{strings.DiscountCode}</label>
                <OutlinedInput
                  name="discount_code"
                  color="primary"
                  variant="outlined"
                  onChange={handleInformationChange}
                  fullWidth
                  size="small"
                  endAdornment={
                    <InputAdornment position="end">
                      <AddCircleOutlineIcon
                        className="text-main"
                        onClick={applyDiscount}
                      />
                    </InputAdornment>
                  }
                />
                {Adiscount?.discount_code !== "" && (
                  <Chip
                    variant="outlined"
                    label={Adiscount?.discount_code}
                    color="primary"
                    className="discount-chip"
                    onDelete={() => updatediscount(null)}
                  />
                )}
                {discounterror &&
                  Object.keys(discounterror).map((key) => {
                    return (
                      <Alert severity="error" key={key}>
                        {discounterror[key]}
                      </Alert>
                    );
                  })}
              </FormControl>
            </Grid>
            {parseFloat(customer?.points) > 0 && (
              <Grid item xs={12}>
                <p style={{ marginTop: 15 }}></p>
                <Typography>
                  {strings.PTS}: {parseFloat(customer?.points).toFixed(2)}
                </Typography>
                <ToggleButtonGroup
                  size="small"
                  value={PaymentInformation?.discount_code}
                  exclusive
                  onChange={(event, value) => {
                    setPaymentInformation({
                      ...PaymentInformation,
                      points_discount: value,
                    });
                    updateDiscountValue(value);
                  }}
                >
                  {parseFloat(customer?.points) >= 100 && (
                    <ToggleButton
                      value={0}
                      className={`${
                        returnValue("selectedDiscount") === 0
                          ? classes.selected
                          : ""
                      }`}
                    >
                      0%
                    </ToggleButton>
                  )}
                  {parseFloat(customer?.points) >= 100 ? (
                    <ToggleButton
                      value={1}
                      className={`${
                        returnValue("selectedDiscount") === 1
                          ? classes.selected
                          : ""
                      }`}
                    >
                      10%
                    </ToggleButton>
                  ) : (
                    <ToggleButton value={1} disabled>
                      10%
                    </ToggleButton>
                  )}
                  {parseFloat(customer?.points) >= 200 ? (
                    <ToggleButton
                      value={2}
                      className={`${
                        returnValue("selectedDiscount") === 2
                          ? classes.selected
                          : ""
                      }`}
                    >
                      20%
                    </ToggleButton>
                  ) : (
                    <ToggleButton value={2} disabled>
                      20%
                    </ToggleButton>
                  )}
                  {parseFloat(customer?.points) >= 300 ? (
                    <ToggleButton
                      value={3}
                      className={`${
                        returnValue("selectedDiscount") === 3
                          ? classes.selected
                          : ""
                      }`}
                    >
                      30%
                    </ToggleButton>
                  ) : (
                    <ToggleButton value={3} disabled>
                      30%
                    </ToggleButton>
                  )}
                  {parseFloat(customer?.points) >= 400 ? (
                    <ToggleButton
                      value={4}
                      className={`${
                        returnValue("selectedDiscount") === 4
                          ? classes.selected
                          : ""
                      }`}
                    >
                      40%
                    </ToggleButton>
                  ) : (
                    <ToggleButton value={4} disabled>
                      40%
                    </ToggleButton>
                  )}
                  {parseFloat(customer?.points) >= 500 ? (
                    <ToggleButton
                      value={5}
                      className={`${
                        returnValue("selectedDiscount") === 5
                          ? classes.selected
                          : ""
                      }`}
                    >
                      50%
                    </ToggleButton>
                  ) : (
                    <ToggleButton value={5} disabled>
                      50%
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              </Grid>
            )}
          </Grid>
          <Grid item container xs={12} justify="flex-end" className="mt-5">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="Payment-Next"
            >
              {strings.Next}
            </Button>
          </Grid>
        </form>
      </div>
    </>
  );
}

export default Payments;
