import React, {useState, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {makeStyles, fade} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import { Widget, addResponseMessage } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import SearchIcon from "../assets/icons/search.svg";
import PersonIcon from "../assets/icons/user.svg";
import HeartIcon from "../assets/icons/heart.svg";
import {
  Avatar,
  List,
  ListItem,
  Badge,
  Grid,
  Hidden,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  Divider,
} from "@material-ui/core";
import {Select, FormControl, MenuItem, IconButton} from "@material-ui/core";
import logo from "../assets/logo/ADVA.png";
import NotificationIcon from "../assets/icons/notifications.svg";
import offerIcon from "../assets/icons/offer.svg";
import newProductIcon from "../assets/icons/newproduct.svg";
import CartBlackIcon from "../assets/icons/cart.svg";
import CheckOut from "../pages/Views/Checkout/Index";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import Config from "../config.json";
import {useInventoryItems} from "./../Context/CartContext";
import HomeIcon from "../assets/icons/home.svg";
import CategoryIcon from "../assets/icons/category.svg";
import GalleryIcon from "../assets/icons/gallery.svg";
import AccountIcon from "../assets/icons/myaccount.svg";
import CartMobile from "../assets/icons/cart-mobile.svg";
import strings from "../lang/lang";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import useInterval from "./../Hooks/Interval";
import LoginModal from "./../pages/components/LoginModal";
// import Slide from '@material-ui/core/Slide'
// import useScrollTrigger  from '@material-ui/core/useScrollTrigger';
const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "6px solid #BF6159",
    backgroundColor: "white",
    zIndex: "2",
    flexGrow: 1,
    "& .MuiAvatar-root .MuiAvatar-img": {
      width: "150px",
      height: "150px",
    },
  },
  FirstAppBar: {
    borderBottom: "6px solid #BF6159",
  },
  toolbar: {
    display: "flex",
    justifyContent: "around",
    alignItems: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  AppBar: {},
  select: {
    "&:before": {
      border: "0px",
    },
    "&:after": {
      border: "0px",
    },
  },
  large: {
    margin: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  flexContainer: {
    display: "flex",
    justifyContent: "end",
  },
  flexContainerStart: {
    display: "flex",
    justifyContent: "start",
    marginTop: "35px",
  },
  MuiListItem: {
    width: "auto",
    "& a": {
      color: "black",
      textDecoration: "none",
      fontSize: "24px",
    },
  },
  BottonNav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 5,
    height: "80px",
    "& button span": {
      color: "black",
      opacity: "1 !important",
      fontSize: "12px !important",
    },
  },
}));

const mbleStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logoleft: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: 8,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Header() {
  // const trigger = useScrollTrigger();
  return (
    <>
      {/* <Slide appear={false} direction="down" in={!trigger}> */}
      <TopAppBar />
      {/* </Slide> */}
      <CheckOut />
    </>
  );
}

function TopAppBar() {
  const classes = useStyles();
  const mblestyle = mbleStyles();
  const {items, setlanguages, lang, returnValue} = useInventoryItems();
  strings.setLanguage(lang);
  const [Lang, setLang] = useState(strings.getLanguage());
  const [enableSearch, setenableSearch] = useState(false);
  const isLogined = JSON.parse(sessionStorage.getItem("user"));
  const location = useHistory();
  const HandleLanguageChange = (e) => {
    setlanguages(e.target.value);
    setLang(e.target.value);
  };
  const {open, handleClose} = useInventoryItems();
  // const handleNewUserMessage = async (newMessage) => {
  //   await axios
  //     .get(`${Config.SERVER_URL}chatbot`, {
  //       params: {
  //         question: newMessage,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.data) addResponseMessage(response.data);
  //       else addResponseMessage("Sorry we can't find any related query");
  //     })
  //     .catch((error) => {});
  // };
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    setIsLoading(true);
    await axios
      .get(`${Config.SERVER_URL}search?search=${query}`)
      .then((res) => {
        const options = res.data.map((i) => ({
          id: i.id,
          product_name: i.product_name,
        }));
        setOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {});
  };
  const filterBy = () => true;
  return (
    <>
      <LoginModal open={open} handleClose={handleClose} />
      <Hidden mdUp>
        <AppBar
          position='sticky'
          color='transparent'
          elevation={0}
          style={{zIndex: 3}}
          className={`FirstAppBar ${classes.root}`}
        >
          <Toolbar className='mobilesearch'>
            <img
              src={logo}
              width='54'
              alt='logo'
              className={mblestyle.logoleft}
            />
            <AsyncTypeahead
              filterBy={filterBy}
              id='async-example'
              isLoading={isLoading}
              labelKey='product_name'
              minLength={2}
              onSearch={handleSearch}
              options={options}
              placeholder={strings.Search}
              renderMenuItemChildren={(option, props) => (
                <Link
                  to={`/products/details/${option.id}`}
                  className='text-dark text-decoration-none'
                >
                  {option.product_name}
                </Link>
              )}
              className='w-100 px-2'
            />
            <Select
              id='Language'
              value={Lang}
              onChange={HandleLanguageChange}
              style={{width: "46px"}}
            >
              <MenuItem value={"en"}>En</MenuItem>
              <MenuItem value={"ar"}>Ar</MenuItem>
            </Select>
          </Toolbar>
        </AppBar>
        <BottomNavigation className={classes.BottonNav}>
          <Link to='/'>
            <BottomNavigationAction
              label={strings.Home}
              value='Home'
              disableRipple
              icon={<img src={HomeIcon} alt='' height='25' className='mb-2' />}
            ></BottomNavigationAction>
          </Link>
          <Link to='/products'>
            <BottomNavigationAction
              label={strings.Products}
              value='Products'
              disableRipple
              icon={
                <img src={CategoryIcon} alt='' height='25' className='mb-2' />
              }
            ></BottomNavigationAction>
          </Link>
          <Link to='#'>
            <BottomNavigationAction
              label={strings.Cart}
              value='Cart'
              disableRipple
              data-toggle='modal'
              data-target='#CheckOutModal'
              icon={
                <Badge badgeContent={items.length} color='primary'>
                  <img
                    src={CartMobile}
                    alt='CartIcon'
                    data-toggle='modal'
                    data-target='#CheckOutModal'
                    height='31'
                    width='31'
                  />
                </Badge>
              }
            />
          </Link>
          <Link to='/gallery'>
            <BottomNavigationAction
              label={strings.Gallery}
              value='Gallery'
              disableRipple
              icon={
                <img src={GalleryIcon} alt='' height='25' className='mb-2' />
              }
            ></BottomNavigationAction>
          </Link>
          <Link to='/user'>
            <BottomNavigationAction
              label={strings.MyAccount}
              value='MyAccount'
              disableRipple
              icon={
                <img src={AccountIcon} alt='' height='25' className='mb-2' />
              }
            ></BottomNavigationAction>
          </Link>
        </BottomNavigation>
      </Hidden>
      <Hidden smDown>
        {
          <div>
            {/* <Widget
              handleNewUserMessage={handleNewUserMessage}
              senderPlaceHolder={strings.TypeMessageHere}
              title={strings.WelcomeToAdva}
              subtitle=''
            /> */}
            {enableSearch ? (
              <AppBar
                position='relative'
                color='transparent'
                elevation={0}
                className={`FirstAppBar ${classes.root} px-5`}
              >
                <Grid
                  container
                  spacing={2}
                  justify='center'
                  alignItems='center'
                  className='py-5'
                >
                  <Grid item xs={11} className='d-flex align-items-center'>
                    <AsyncTypeahead
                      filterBy={filterBy}
                      id='async-example'
                      isLoading={isLoading}
                      labelKey='product_name'
                      minLength={2}
                      onSearch={handleSearch}
                      options={options}
                      placeholder={strings.Search}
                      renderMenuItemChildren={(option) => (
                        <Link
                          to={`/products/details/${option.id}`}
                          className='text-dark text-decoration-none'
                        >
                          {option.product_name}
                        </Link>
                      )}
                      className='mx-2 w-100'
                    />
                    {/* </Grid>
                  <Grid item xs={1}> */}
                    <CloseIcon
                      onClick={() => setenableSearch(false)}
                      style={{cursor: "pointer"}}
                    />
                  </Grid>
                </Grid>
              </AppBar>
            ) : (
              <AppBar
                position='relative'
                color='transparent'
                elevation={0}
                className={`FirstAppBar ${classes.root} px-5`}
              >
                <Toolbar
                  className='toolbar row'
                  style={{paddingLeft: 70, paddingRight: 70}}
                >
                  <Grid container justify='space-between' className='pt-2'>
                    <Grid item xs={5} container alignItems='center'>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              component='p'
                              variant='body2'
                              className='text-muted'
                            >
                              {strings.formatString(
                                strings.Freeshipping,
                                returnValue("free_shipping")
                              )}
                            </Typography>
                          </Box>
                          <Box display='flex' alignItems='center'>
                            <FormControl className={classes.formControl}>
                              <Select
                                id='Language'
                                value={Lang}
                                onChange={HandleLanguageChange}
                                className={classes.select}
                              >
                                <MenuItem value={"en"}>English</MenuItem>
                                <MenuItem value={"ar"}>العربية</MenuItem>
                              </Select>
                            </FormControl>
                            {/* <FormControl className={classes.formControl}>
                        <Select
                          id="Currency"
                          value={Currency}
                          onChange={HandleCurrencyChange}
                          className={classes.select}
                        >
                          <MenuItem value={"riyals"}>Riyals</MenuItem>
                          <MenuItem value={"dollars"}>Dollars</MenuItem>
                        </Select>
                      </FormControl> */}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container alignItems='flex-end'>
                        <Grid item xs={12} container justify='flex-end'>
                          <List className={classes.flexContainer}>
                            <ListItem className={classes.MuiListItem}>
                              <Link to='/'>{strings.Home}</Link>
                            </ListItem>
                            <ListItem className={classes.MuiListItem}>
                              <Link to='/products'>{strings.Products}</Link>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      container
                      justify='center'
                      alignItems='center'
                    >
                      <Link to='/'>
                        <img
                          alt='ADVA Logo'
                          src={logo}
                          className={classes.large}
                        />
                      </Link>
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      alignItems='center'
                      container
                      direction='column'
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display='flex'
                            alignItems='end'
                            justifyContent='flex-end'
                            style={{minHeight: "48px"}}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs
                                className='d-flex justify-content-end align-items-center'
                              >
                                <IconButton
                                  aria-label='search'
                                  disableRipple
                                  disableFocusRipple
                                  className='p-0 f-43-button'
                                  onClick={(e) => {
                                    setenableSearch(true);
                                  }}
                                >
                                  <img src={SearchIcon} alt='' />
                                </IconButton>

                                <div className='d-flex-center-center'>
                                  {isLogined ? (
                                    <>
                                      <div
                                        className='dropdown show d-flex-start-center'
                                        style={{cursor: "pointer"}}
                                      >
                                        <Avatar
                                          variant='square'
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <img src={PersonIcon} alt='' />
                                        </Avatar>
                                        <span
                                          className='p-0'
                                          id='dropdownMenuLink'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <Typography
                                            component='p'
                                            style={{fontSize: 15}}
                                            className='font-weight-bold'
                                          >
                                            {strings.Hello}
                                            {isLogined?.first_name}
                                          </Typography>
                                          <Typography
                                            component='p'
                                            variant='overline'
                                            className='p-0 m-0'
                                            style={{lineHeight: "0.66"}}
                                          >
                                            {strings.PTS}:{isLogined?.points}
                                          </Typography>
                                        </span>

                                        <div
                                          className='dropdown-menu'
                                          aria-labelledby='dropdownMenuLink'
                                        >
                                          <List>
                                            <ListItem className='py-0'>
                                              <Link
                                                to='/user'
                                                className='text-decoration-none text-dark'
                                              >
                                                {strings.MyAccount}
                                              </Link>
                                            </ListItem>
                                            <Divider />
                                            <ListItem
                                              className='py-0'
                                              onClick={() => {
                                                sessionStorage.clear();
                                                location.replace("/login");
                                              }}
                                            >
                                              {strings.SignOut}
                                            </ListItem>
                                          </List>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <Avatar
                                        variant='square'
                                        style={{
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <img src={PersonIcon} alt='' />
                                      </Avatar>
                                      <Link
                                        to='/login'
                                        className='text-decoration-none text-dark'
                                      >
                                        {strings.Signin}
                                      </Link>
                                    </>
                                  )}
                                </div>

                                <ActionMenu />

                                <NotificationMenu />

                                <Badge
                                  badgeContent={items.length}
                                  color='primary'
                                >
                                  <img
                                    src={CartBlackIcon}
                                    alt='CartIcon'
                                    data-toggle='modal'
                                    data-target='#CheckOutModal'
                                  />
                                </Badge>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container alignItems='flex-end'>
                        <Grid item xs={12}>
                          <List className={classes.flexContainerStart}>
                            <ListItem className={classes.MuiListItem}>
                              <Link to='/brands'>{strings.Brands}</Link>
                            </ListItem>
                            <ListItem className={classes.MuiListItem}>
                              <Link to='/gallery'>{strings.Gallery}</Link>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            )}
          </div>
        }
      </Hidden>
    </>
  );
}
function ActionMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [wishlist, setwishlist] = useState(null);
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  useEffect(() => {
    const init = async () => {
      if (user) {
        await axios
          .get(`${Config.SERVER_URL}wishlist/preview/${user?.id}`)
          .then((res) => {
            setwishlist(res.data);
          })
          .catch((error) => {});
      }
    };
    init();
  }, [user]);
  return (
    <div>
      <IconButton
        onClick={handleClick}
        disableFocusRipple
        disableRipple
        className='py-0'
        style={{fontSize: "16pt"}}
      >
        <img src={HeartIcon} alt='' />
      </IconButton>
      <Menu
        id='WislistTopMenu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{minWidth: 250}}
      >
        {wishlist ? (
          <div>
            {wishlist?.map((w, index) => {
              return (
                <MenuItem key={index} style={{minWidth: 250}}>
                  <Link
                    to={`/products/details/${w.product_id}`}
                    className='text-dark text-decoration-none'
                  >
                    <Grid container>
                      <Grid item xs>
                        <img
                          src={w?.products?.productimages[0]?.picture_reference}
                          alt=''
                          width='50'
                        />
                      </Grid>
                      <Grid item xs>
                        <p className='mx-1'>
                          {w?.products?.product_name}
                          <br />
                          Price: {w?.products?.price}
                        </p>
                      </Grid>
                    </Grid>
                  </Link>
                </MenuItem>
              );
            })}
            <MenuItem>
              <Link to='/user' className='text-main mx-auto'>
                {strings.ViewAll}
              </Link>
            </MenuItem>
          </div>
        ) : (
          <MenuItem>{strings.NoItemInWishList}</MenuItem>
        )}
      </Menu>
    </div>
  );
}
function NotificationMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [notification, setnotification] = useState(null);
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [count, setcount] = useState(0);

  useInterval(async () => {
    if (user) {
      await axios
        .get(`${Config.SERVER_URL}notifications/${user?.id}`)
        .then((res) => {
          setnotification(res.data.notification);
          setcount(res.data.count);
        })
        .catch((error) => {});
    }
  }, 60000);
  useEffect(() => {
    const init = async () => {
      if (user) {
        await axios
          .get(`${Config.SERVER_URL}notifications/${user?.id}`)
          .then((res) => {
            setnotification(res.data.notification);
            setcount(res.data.count);
          })
          .catch((error) => {});
        if (anchorEl) {
          await axios
            .get(`${Config.SERVER_URL}notifications/read/${user?.id}`)
            .then((res) => {})
            .catch((error) => {});
        }
      }
    };
    init();
  }, [user, anchorEl]);
  const {lang} = useInventoryItems();
  return (
    <div>
      <IconButton
        onClick={handleClick}
        disableFocusRipple
        disableRipple
        className='py-0'
        style={{fontSize: "16pt"}}
      >
        <Badge badgeContent={count} color='primary'>
          <img src={NotificationIcon} alt='N' />
        </Badge>
      </IconButton>
      <Menu
        id='NotificationMenu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='mx-2'
        style={{
          minWidth: 200,
          maxHeight: 301,
          overflowX: "auto",
          display: "flex",
        }}
      >
        {notification && Object.keys(notification).length > 0 ? (
          <div>
            {notification?.map((notif, index) => {
              return (
                <Link
                  key={index}
                  to={
                    notif?.icon === "offer"
                      ? `products?offer=${notif?.not_id}`
                      : `products/details/${notif?.not_id}`
                  }
                  className='text-decoration-none text-dark'
                >
                  <Box
                    style={{minWidth: 200}}
                    justifyContent='center'
                    alignItems='start'
                    display='flex'
                  >
                    <img
                      src={notif?.icon === "offer" ? offerIcon : newProductIcon}
                      alt=''
                      width='25'
                      className='mx-2'
                    />
                    <span
                      style={{
                        width: 150,
                        wordWrap: "break-word",
                        display: "inline-block",
                        fontSize: 12,
                      }}
                    >
                      {lang.localeCompare("en") === 0
                        ? notif?.notification
                        : notif?.arabic_notification}
                    </span>
                  </Box>
                  <Divider />
                </Link>
              );
            })}
          </div>
        ) : (
          <MenuItem>{strings.NoNotifications}</MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default Header;
