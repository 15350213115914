import React, { useState, useEffect } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Hidden,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  Container,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Link, useLocation } from "react-router-dom";
import ProductItem from "../../components/ProductItem";
import { Grid } from "@material-ui/core";
import axios from "axios";
import config from "../../../config.json";
import { useInventoryItems } from "./../../../Context/CartContext";
import strings from "./../../../lang/lang";
import { Pagination } from "@material-ui/lab";
import FullPageLoader from "../../components/FullPageLoader";
import CartSnackbar from "../../components/CartSnackbar";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "auto",
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  MuiListItem: {
    width: "auto",
    "& a": {
      color: "black",
      textDecoration: "none",
    },
    "&.active": {
      "& a": {
        color: "#BF6159",
      },
    },
  },
  searchInput: {
    "& input:valid + fieldset": {
      borderColor: "black",
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
    },
    "& input:valid:focus + fieldset": {
      borderColor: "#BF6159",
      padding: "4px !important",
    },
    "& label.Mui-focused": {
      color: "#BF6159",
    },
  },
  active: {
    color: "#BF6159 !important",
    "& a": {
      color: "#BF6159 !important",
    },
  },
  mbleCategory: {
    padding: "10px 10px",
    border: "1pt solid #D2D2D2",
    margin: "3px",
    fontSize: "12px",
    cursor: "pointer",
    minWidth: 100,
    width: "max-content",
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Products() {
  const [sortBy, setsortBy] = useState("none");
  const [search, setsearch] = useState("");
  const query = useQuery();
  const [offer] = useState(query.get("offer"));
  const HandleSortByChange = (e) => {
    setsortBy(e.target.value);
  };
  const classes = useStyles();
  const [Prod, setProd] = useState(null);
  const [categories, setcategories] = useState(null);
  const [brands, setbrands] = useState(null);
  const [latest, setlatest] = useState(null);
  const [dummystate, setdummystate] = useState(false);
  const [selectedCategory, setselectedCategory] = useState(
    query?.get("category") ? query?.get("category") : null
  );
  const [selectedBrand, setselectedBrand] = useState(
    query?.get("brand") ? query?.get("brand") : null
  );
  const [data, setdata] = useState(null);
  const [pagenumber, setpagenumber] = useState(1);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      await axios
        .get(`${config.SERVER_URL}product/index?page=${pagenumber}`, {
          params: {
            sort: sortBy,
            search: search,
            category: selectedCategory,
            brand: selectedBrand,
            offer: offer,
          },
        })
        .then((response) => {
          setProd(response.data.data);
          setdata(response.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, [search, sortBy, selectedCategory, selectedBrand, offer, pagenumber]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setloading(true);
    const init = async () => {
      axios
        .get(`${config.SERVER_URL}category`)
        .then((response) => {
          if (response.data.length) setcategories(response.data);
        })
        .catch((error) => {});
      axios
        .get(`${config.SERVER_URL}brand`)
        .then((response) => {
          if (response.data.length) setbrands(response.data);
        })
        .catch((error) => {});
      axios
        .get(`${config.SERVER_URL}product/latestprod`)
        .then((response) => {
          if (response.data.length) setlatest(response.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    };
    init();
  }, []);
  if (Prod != null && Prod.length < 1) {
    setProd(null);
  }
  const { items, updatecart, lang } = useInventoryItems();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const addToCart = (d) => {
    let products = items;
    let rProduct = products.filter((p) => {
      return p.id === d.id;
    });
    if (rProduct.length) {
      rProduct = [];
      products.map((p) => {
        if (p.id === d.id) {
          p.newQuantity += 1;
        }
        rProduct.push(p);
        return "";
      });
      products = rProduct;
    } else {
      var dummy = d;
      dummy["newQuantity"] = 1;
      products.push(dummy);
    }
    updatecart(products);
    setdummystate(!dummystate);
    handleClick();
  };
  return (
    <>
      <FullPageLoader loading={loading} />

      <Header />

      <Container maxWidth="lg">
        <CartSnackbar open={open} />
        <Hidden mdDown>
          <div className="d-flex brands-header ">
            <ol className="breadcrumb my-auto">
              <li className="breadcrumb-item">
                <Link to="/">{strings.Home}</Link>
              </li>
              <li className="breadcrumb-item active">{strings.Products}</li>
            </ol>
          </div>
        </Hidden>
        <p className="f-43 text-center my-sm-3 my-lg-auto">
          {strings.Products}
        </p>
        <Divider />

        <Box p={2}>
          <Hidden mdUp>
            <Typography component="legend">{strings.ShopsByBrand}</Typography>
            <Box
              style={{ width: "100%", overflowX: "auto", display: "flex" }}
              className="mb-3"
            >
              <List className="d-flex">
                {brands
                  ? brands?.map((brand, index) => {
                      return (
                        <ListItem
                          className={`${classes.mbleCategory} ${
                            selectedBrand === brand?.id ? classes.active : ""
                          }`}
                          key={index}
                          onClick={() => {
                            setselectedBrand(brand?.id);
                            setselectedCategory(null);
                          }}
                        >
                          {lang === "en"
                            ? brand.brand_name
                            : brand.brand_arabic_name}
                        </ListItem>
                      );
                    })
                  : ""}
              </List>
            </Box>
            <Divider className="my-3" />
            <Typography component="legend">
              {strings.ShopsByCategory}
            </Typography>
            <Box
              style={{ width: "100%", overflowX: "auto", display: "flex" }}
              className="mb-3"
            >
              <List className="d-flex">
                {categories
                  ? categories?.map((cat, index) => {
                      return (
                        <ListItem
                          className={`${classes.mbleCategory} ${
                            selectedCategory === cat?.id ? classes.active : ""
                          }`}
                          key={index}
                          onClick={() => {
                            setselectedCategory(cat?.id);
                            setselectedBrand(null);
                          }}
                        >
                          {lang === "en"
                            ? cat.category_name
                            : cat.category_arabic_name}
                        </ListItem>
                      );
                    })
                  : ""}
              </List>
            </Box>
            <Divider />
          </Hidden>
          <Grid container spacing={5}>
            <Hidden mdDown>
              <Grid item lg={3}>
                <OutlinedInput
                  variant="outlined"
                  className={`w-100 search ${classes.searchInput}`}
                  size="small"
                  placeholder={strings.Search}
                  value={search}
                  onChange={(e) => {
                    setsearch(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  style={{ padding: "10px 7px" }}
                  rows="2"
                />
                <Card
                  className="mt-3"
                  elevation={2}
                  style={{ boxShadow: "0px 3px 6px #BF61594D" }}
                >
                  <CardContent>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      size="small"
                    >
                      <label>{strings.SortBy}</label>
                      <Select
                        labelId="SortByLabel"
                        id="SortBy"
                        value={sortBy}
                        onChange={HandleSortByChange}
                        placeholder={strings.SortBy}
                      >
                        <MenuItem value="none" selected>
                          {strings.None}
                        </MenuItem>
                        <MenuItem value={"asc"}>{strings.LowToHigh}</MenuItem>
                        <MenuItem value={"desc"}>{strings.HighToLow}</MenuItem>
                        <MenuItem value={"latest"}>{strings.Latest}</MenuItem>
                      </Select>
                    </FormControl>
                    {categories && (
                      <section className="my-4 border-bottom-main filter">
                        <p className="f-18 mb-2 px-16">{strings.Categories}</p>
                        <List className={classes.flexContainer}>
                          {categories.map((d, index) => {
                            return (
                              <ListItem
                                key={index}
                                className={`${classes.MuiListItem} ${
                                  selectedCategory === d?.id
                                    ? classes.active
                                    : ""
                                }`}
                                onClick={() => {
                                  setselectedCategory(d?.id);
                                  setselectedBrand(null);
                                }}
                              >
                                <Link to={`/products?category=${d?.id}`}>
                                  {lang === "en"
                                    ? d?.category_name
                                    : d?.category_arabic_name}
                                </Link>
                              </ListItem>
                            );
                          })}
                        </List>
                      </section>
                    )}
                    {/* Latest */}
                    <section className="my-4 border-bottom-main filter">
                      <p className="f-18 mb-2 px-16">{strings.Latest}</p>
                      <List className={classes.flexContainer}>
                        {latest &&
                          latest?.map((prod, index) => {
                            return (
                              <ListItem
                                className={classes.MuiListItem}
                                key={index}
                              >
                                <Link to={`/products/details/${prod?.id}`}>
                                  {prod?.product_name}
                                </Link>
                              </ListItem>
                            );
                          })}
                      </List>
                    </section>

                    {/* Brands */}
                    {brands && (
                      <section className="my-4 border-bottom-main filter">
                        <p className="f-18 mb-2 px-16">
                          {strings.FeaturedBrands}
                        </p>
                        <List className={classes.flexContainer}>
                          {brands.map((d, index) => {
                            return (
                              <ListItem
                                className={`${classes.MuiListItem} ${
                                  selectedBrand === d?.id ? classes.active : ""
                                }`}
                                key={index}
                                onClick={() => {
                                  setselectedCategory(null);
                                  setselectedBrand(d?.id);
                                }}
                              >
                                <Link to={`/products?brand=${d?.id}`}>
                                  {lang === "en"
                                    ? d?.brand_name
                                    : d?.brand_arabic_name}
                                </Link>
                              </ListItem>
                            );
                          })}
                        </List>
                      </section>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Hidden>

            <Grid item xs={12} lg={9}>
              <Grid container spacing={2}>
                {!!Prod &&
                  Object.keys(Prod).length > 0 &&
                  Prod?.map((d, index) => {
                    return (
                      <Grid item xs={6} lg={4} key={index}>
                        <ProductItem
                          product={d}
                          cartItems={(d) => addToCart(d)}
                          className="mx-1"
                        />
                      </Grid>
                    );
                  })}
                {Prod && (
                  <Grid container item xs={12} justify="flex-end">
                    <Pagination
                      count={parseInt(data?.total / 12 + 1)}
                      showFirstButton
                      showLastButton
                      onChange={(e, val) => setpagenumber(val)}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default Products;
