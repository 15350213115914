import { Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Typography, Box, Radio } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import cardIcon from "../../../assets/icons/card.svg";
import walletIcon from "../../../assets/icons/wallet.svg";
import axios from "axios";
import Config from "../../../config.json";
import { Alert } from "@material-ui/lab";
import FullPageLoader from "../../components/FullPageLoader";
function Preferences() {
  const [SelectedValue, setSelectedValue] = useState("1");
  const [user] = useState(
    sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );
  const [success, setsuccess] = useState(false);
  const handleChange = async (event) => {
    setSelectedValue(event.target.value);
    await axios
      .post(`${Config.SERVER_URL}customer/preference/update/${user?.id}`, {
        preference: SelectedValue,
      })
      .then((res) => {
        setsuccess(true);
        setTimeout(() => {
          setsuccess(false);
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 400) seterror(error.response.data);
      });
  };
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      setloading(true);
      await axios
        .get(`${Config.SERVER_URL}customer/preference/${user?.id}`)
        .then((res) => {
          setSelectedValue(res?.data?.preferences);
          setloading(false);
        })
        .catch((error) => {
          if (error.response.status === 400) seterror(error.response.data);
          setloading(false);
        });
    };
    init();
  }, [user]);
  return (
    <>
      <FullPageLoader loading={loading} />
      <Typography align="center" component="h4" variant="h4">
        Preferences
      </Typography>
      <Divider />

      <Box component={Paper} p={4}>
        {!!error &&
          Object.keys(error).map((key) => {
            return (
              <Alert key={key} severity="error">
                {error[key]}
              </Alert>
            );
          })}
        {success && (
          <Alert severity="success">Preference Updated successfully</Alert>
        )}
        <Grid container spacing={3}>
          <Grid item xs={3} lg={1}>
            <img src={cardIcon} alt="" className="auto" />
          </Grid>
          <Grid item xs>
            <Typography component="p" variant="h6">
              Card Refund
            </Typography>
            <Typography component="p" variant="body2">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Radio
              checked={SelectedValue === "1" || SelectedValue === "wallet"}
              onChange={handleChange}
              value="1"
              name="Preferences"
              inputProps={{ "aria-label": "A" }}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={3} lg={1}>
            <img src={walletIcon} alt="" className="auto" />
          </Grid>
          <Grid item xs>
            <Typography component="p" variant="h6">
              Card Refund
            </Typography>
            <Typography component="p" variant="body2">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Radio
              checked={SelectedValue === "2" || SelectedValue === "card"}
              onChange={handleChange}
              value="2"
              name="Preferences"
              inputProps={{ "aria-label": "B" }}
            />
          </Grid>
        </Grid>
        <Divider />
      </Box>
    </>
  );
}

export default Preferences;
