import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Logo from "../../../assets/logo/ADVA.png";
import strings from "./../../../lang/lang";
import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Footer from "../../../layout/Footer";
import Loader from "../../../assets/loader/loader.gif";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FullPageLoader from "../../components/FullPageLoader";
import $ from "jquery";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function CardPayment() {
  const [loading, setloading] = useState(false);
  const query = useQuery();
  const { id } = useParams();
  useEffect(() => {
    setloading(true);
    const script = document.createElement("script");
    script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${id}`;
    script.async = true;
    document.body.appendChild(script);
    const form = document.createElement("form");
    form.action = `/#/checkout/payments/confirm?email=${query.get(
      "email"
    )}&phone=${query.get("phone")}&address_id=${query.get(
      "address_id"
    )}&promo_code=${query.get("promo_code")}&discount_code=${query.get(
      "discount_code"
    )}&points_discount=${query.get("points_discount")}`;
    form.setAttribute("class", "paymentWidgets");
    if (query.get("brand") === "MADA") form.setAttribute("data-brands", "MADA");
    else form.setAttribute("data-brands", "VISA MASTER");

    document.getElementById("PaymentForm").innerHTML = "";
    document.getElementById("PaymentForm").appendChild(form);
    setloading(false);
    $(document).on("change", ".wpwl-control-brand", function () {
      console.log($(this).val());
      $("#PaymentForm form input[name=shopperResultUrl]").val(
        `/#/checkout/payments/confirm?brand=${$(this).val()}&email=${query.get(
          "email"
        )}&phone=${query.get("phone")}&address_id=${query.get(
          "address_id"
        )}&promo_code=${query.get("promo_code")}&discount_code=${query.get(
          "discount_code"
        )}&points_discount=${query.get("points_discount")}`
      );
    });
    setInterval(() => {
      $("#PaymentForm form input[name=shopperResultUrl]").val(
        `/#/checkout/payments/confirm?brand=${$(
          "select[name=paymentBrand]"
        ).val()}&email=${query.get("email")}&phone=${query.get(
          "phone"
        )}&address_id=${query.get("address_id")}&promo_code=${query.get(
          "promo_code"
        )}&discount_code=${query.get(
          "discount_code"
        )}&points_discount=${query.get("points_discount")}`
      );
    }, 5000);
  }, [id, query]);
  return (
    <>
      <FullPageLoader loading={loading} />
      <div>
        <Grid container>
          <Grid item xs={12} container justify="center">
            <img src={Logo} alt="logo" srcset="" />
          </Grid>
          <Grid item xs={12} container justify="center">
            <nav className="breadcrumb mx-5">
              <li className="breadcrumb-item">
                <Link to="/">{strings.Cart}</Link>
              </li>
              <li className="breadcrumb-item ">
                <Link to="/checkout">{strings.Checkout}</Link>
              </li>
              <li className="breadcrumb-item active">{strings.Confirm}</li>
            </nav>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Link to="/checkout" className="text-dark text-decoration-none">
              <Button variant="text" className="text-main">
                <ArrowBackIosIcon /> {strings.BackToCart}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <div id="PaymentForm" dir="ltr">
          <img src={Loader} alt="loader" srcset="" />
        </div>
      </div>
      <Footer />
    </>
  );
}
export default CardPayment;
