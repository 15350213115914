import React, { useState } from 'react'
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({ component, ...props }) {
    let user = sessionStorage.getItem('user') ? sessionStorage.getItem('user') : null;
    // eslint-disable-next-line
    const [isLogined, setisLogined] = useState(user)
    return (
        <>
            {
                isLogined ? (<Route {...props} render={()=>component}/>) : (<Redirect to="/login" />)
            }
        </>
    )
}

export default PrivateRoute
